import { HiSortAscending, HiSortDescending } from "react-icons/hi";
import {
  Table,
  Input,
  Button,
  Form,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "react-bootstrap";
import moment from "moment";
import { useEffect, useState } from "react";
import cogoToast from "cogo-toast";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
// import defaultData from "./defaultData";

const IpdLeads = () => {
  const [tableData, setTableData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dateFilter, setDateFilter] = useState({ startDate: "", endDate: "" });
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortBy, setSortBy] = useState(null);
  const [dischargeBillInfo, setDischargeBillInfo] = useState();
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = searchTerm
    ? filteredData.slice(indexOfFirstRow, indexOfLastRow)
    : tableData.slice(indexOfFirstRow, indexOfLastRow);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(tableData.length / rowsPerPage); i++) {
    pageNumbers.push(i);
  }

  const getAllBill = async () => {
    try {
      const response = await axios.get(
        `https://test-cghs.doaguru.com/api/v1/auth/getAllIPDPatientBill/${user.id}`
      );
      console.log(response.data);

      const response2 = await axios.get(
        `https://test-cghs.doaguru.com/api/v1/auth/getAllDischargeDataSummary`
      );

      console.log(response2.data.result);
      setDischargeBillInfo(response2.data.result);
      setTableData(response.data);
    } catch (error) {
      console.log(error);
      cogoToast.error("error in getting bills");
    }
  };

  const isDischargeSummaryWasMade = (id) => {
    if (!Array.isArray(dischargeBillInfo)) {
      console.error("dischargeBillInfo is not an array");
      return false;
    }

    return dischargeBillInfo.find((item) => item.patient_id === id);
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const totalPages = Math.ceil(tableData.length / rowsPerPage);

  const paginate = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleDelete = async (bill_Id) => {
    // Display a confirmation dialog
    const userConfirmed = window.confirm(
      "Are you sure you want to delete this bill ?"
    );

    // Check if the user confirmed the deletion
    if (userConfirmed) {
      try {
        // Make the API request to delete the bill
        const response = await axios.delete(
          `https://test-cghs.doaguru.com/api/v1/auth/deleteIPDBill/${bill_Id}`
        );

        // Check if the deletion was successful
        if (response.data.success) {
          // Remove the deleted bill from the state
          setTableData((prevData) =>
            prevData.filter((item) => item.bill_Id !== bill_Id)
          );

          // Display a success message
          cogoToast.success("Bill Deleted successfully");
        } else {
          // Handle the case where the deletion was not successful
          cogoToast.error("Error deleting bill");
        }
      } catch (error) {
        console.log(error);
        // Handle errors related to the deletion process
      }
    }
  };

  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    setCurrentPage(1); // Reset to the first page when searching

    const filteredResults = tableData.filter((row) =>
      row.patient_name.toLowerCase().includes(searchTerm)
    );

    setFilteredData(filteredResults);
    setDateFilter({ startDate: "", endDate: "" });
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const renderPageNumbers = pageNumbers.map((number, index) => {
    // Display the first two page numbers
    if (index < 2) {
      return (
        <Button
          key={number}
          onClick={() => paginate(number)}
          className={number === currentPage ? "active" : ""}
        >
          {number}
        </Button>
      );
    }
    // Display an ellipsis for the first middle section
    else if (index === 2 && currentPage > 3) {
      return (
        <Button key={number} disabled>
          ...
        </Button>
      );
    }
    // Display the current page and the two adjacent pages
    else if (
      (index >= currentPage - 1 && index <= currentPage + 1) ||
      (index === 2 && currentPage <= 3)
    ) {
      return (
        <Button
          key={number}
          onClick={() => paginate(number)}
          className={number === currentPage ? "active" : ""}
        >
          {number}
        </Button>
      );
    }
    // Display an ellipsis for the last middle section
    else if (
      index === pageNumbers.length - 3 &&
      currentPage < pageNumbers.length - 2
    ) {
      return (
        <Button key={number} disabled>
          ...
        </Button>
      );
    }
    // Display the last two page numbers
    else if (index >= pageNumbers.length - 2) {
      return (
        <Button
          key={number}
          onClick={() => paginate(number)}
          className={number === currentPage ? "active" : ""}
        >
          {number}
        </Button>
      );
    }
    return null;
  });

  const handleDateChange = (e) => {
    const { value, name } = e.target;
    setDateFilter({ ...dateFilter, [name]: value });
    console.log(dateFilter.startDate, dateFilter.endDate);
  };
  
  const handleSortByDate = () => {
    if (dateFilter.startDate && dateFilter.endDate) {
      setCurrentPage(1);
      let s_Date = new Date(dateFilter.startDate).getTime();
      let e_Date = new Date(dateFilter.endDate).getTime();
      const filteredResults = tableData.filter((row) => {
        let getDate = new Date(row.bill_date).getTime();
        if (getDate <= e_Date && getDate >= s_Date) return true;
      });

      setFilteredData(filteredResults);
      setSearchTerm(" ");
      console.log(filteredResults);
    }
  };

  const transformData = (rows) => {
    return rows.map(row => ({
        bill_Id: row.bill_Id,
        patientCategory: row.patientCategory,
        patient_name: row.patient_name,
        age: row.age,
        gender: row.gender,
        treatment_date: row.treatment_date,
        discharge_date: row.discharge_date,
        bill_date: row.bill_date,
        beneficiary_id: row.beneficiary_id,
        doctor_name: row.doctor_name,
        ward_bed_no: row.ward_bed_no,
    }));
};

  const downloadExcel = () => {
    const transformedData = transformData(currentRows);

    fetch('https://test-cghs.doaguru.com/api/v1/auth/download-excel', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(transformedData),
    })
        .then(response => response.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'example.xlsx'); // Filename
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        })
        .catch(error => console.error('Error downloading the file:', error));
};


  useEffect(() => {
    getAllBill();
  }, []);
  useEffect(() => {}, [tableData, dateFilter]);

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <Form.Group controlId="rowsPerPageSelect" style={{ display: "flex" }}>
            <Form.Label className="d-flex align-items-center">
              Rows Per Page :{" "}
            </Form.Label>
            <Form.Control
              as="select"
              value={rowsPerPage}
              className="m-2"
              style={{ width: "auto" }}
              onChange={handleRowsPerPageChange}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              {/* Add more options as needed */}
            </Form.Control>
          </Form.Group>
        </div>
        <div class="row">
          <div class="col">
            <input
              id="startDate"
              name="startDate"
              value={dateFilter.startDate}
              onChange={handleDateChange}
              type="date"
              class="form-control"
            />
          </div>
          <div class="col">
            <input
              id="endDate"
              name="endDate"
              value={dateFilter.endDate}
              onChange={handleDateChange}
              type="date"
              class="form-control"
            />
          </div>
        </div>
        <div class="">
      <button onClick={handleSortByDate} class="btn btn-primary me-2">Filter By Date</button>
      {/* <button onClick={() => setDateFilter({...dateFilter, startDate: "", endDate: ""})} class="btn btn-primary me-2">Reset Date filter</button> */}
      <button onClick={() => {setFilteredData(tableData); setDateFilter({ startDate: "", endDate: ""})}} class="btn btn-primary me-2">Reset Date filter</button>
      <button onClick={downloadExcel} class="btn btn-success">Download Excel</button>
    </div>
        <div>
          <label htmlFor="">Search by Name : </label>
          <input
            type="text"
            placeholder="Search by name"
            value={searchTerm}
            onChange={handleSearch}
            className="m-2 rounded"
          />
        </div>
      </div>
      <div>
        <div className="overflow-y-auto">
          <Table striped bordered hover>
            <thead className="thbody">
              <tr>
                <th>Bill ID </th>
                <th>Patient Category</th>
                <th>Patient Name</th>
                <th>Age</th>
                <th>Gender</th>
                <th>Date of Admission</th>
                <th>Date of Discharge</th>
                <th>Bill Date</th>
                <th>Beneficiary ID</th>
                <th>Dr. Name</th>
                <th>Ward/Bed no.</th>
                <th>Edit</th>
                <th>Report</th>
              </tr>
            </thead>
            <tbody>
              {currentRows?.length > 0 ? (
                currentRows
                  .sort((a, b) => {
                    if (sortBy) {
                      const order = sortOrder === "asc" ? 1 : -1;
                      return a[sortBy] > b[sortBy] ? order : -order;
                    }
                    return 0;
                  })
                  ?.map((data) => (
                    <tr>
                      <td>{data.bill_Id}</td>
                      <td>{data.patientCategory}</td>
                      <td>{data.patient_name}</td>
                      <td>{data.age}</td>
                      <td>{data.gender}</td>
                      <td>
                        {data?.treatment_date
                          ? moment(data.treatment_date).format("YYYY-MM-DD")
                          : ""}
                      </td>
                      <td>
                        {data?.discharge_date
                          ? moment(data.discharge_date).format("YYYY-MM-DD")
                          : ""}
                      </td>
                      <td>
                        {data.bill_date
                          ? moment(data.bill_date).format("YYYY-MM-DD")
                          : ""}
                      </td>
                      <td>{data.beneficiary_id}</td>
                      <td>{data.doctor_name}</td>
                      <td>{data.ward_bed_no}</td>
                      <td>
                        <div className="d-flex">
                          <button
                            onClick={() =>
                              navigate(`/edit-ipd-patient/${data.bill_Id}`)
                            }
                            className="btn btn-warning"
                          >
                            Edit
                          </button>
                          <button
                            className="btn btn-danger"
                            style={{ marginLeft: "5px" }}
                            onClick={() => handleDelete(data.bill_Id)}
                          >
                            Delete
                          </button>
                          {isDischargeSummaryWasMade(data.bill_Id) ? (
                            <Link to={`/editDischargBill/${data.bill_Id}`}>
                              <button
                                className="btn btn-light text-black"
                                style={{ marginLeft: "5px" }}
                              >
                                Edit Discharge 
                              </button>
                            </Link>
                          ) : (
                            <Link to={`/dischargeBill/${data.bill_Id}`}>
                              <button
                                className="btn text-white bg-secondary"
                                style={{ marginLeft: "5px" }}
                              >
                                Create Discharge
                              </button>
                            </Link>
                          )}
                        </div>
                      </td>
                      <td>
                        <Dropdown
                          isOpen={dropdownOpen}
                          toggle={toggleDropdown}
                          direction="up"
                        >
                          <DropdownToggle caret className="btn btn-success">
                            Bill / Form
                          </DropdownToggle>
                          <DropdownMenu>
                            <a
                              href={`/PreOpAsseSheet/${data.bill_Id}`}
                              target="blank"
                              className="dropdown-item"
                            >
                              Pre operative Assessment
                            </a>
                            <a
                              href={`/PomTs/${data.bill_Id}`}
                              target="blank"
                              className="dropdown-item"
                            >
                              Pre operative medication treatment sheet
                            </a>
                            <a
                              href={`/DischargesSummary/${data.bill_Id}`}
                              target="blank"
                              className="dropdown-item"
                            >
                              Discharge Summary
                            </a>
                            <a
                              href={`/FinalBillIPD/${data.bill_Id}`}
                              target="_blank"
                              className="dropdown-item"
                            >
                              Final bill
                            </a>
                          </DropdownMenu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan="20" className="text-center">
                    No results found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
      <div className=" mt-3 mb-3">
        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-12 col-12">
            {" "}
            <h4
              style={{
                color: "black",
                marginLeft: "5px",
                marginRight: "5px",
                fontSize: "1.1rem",
              }}
            >
              {/* Showing Page {currentPage} of {totalPages} from {data?.length} entries */}
              {searchTerm ? (
                <>
                  {" "}
                  Showing Page {currentPage} of {totalPages} from{" "}
                  {filteredData?.length} entries (filtered from{" "}
                  {tableData?.length} total entries){" "}
                </>
              ) : (
                <>
                  Showing Page {currentPage} of {totalPages} from{" "}
                  {tableData?.length} entries
                </>
              )}
            </h4>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12 col-12">
            <div className="d-flex justify-content-evenly">
              <Button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
                variant="warning"
              >
                Previous
              </Button>
              {renderPageNumbers}

              <Button
                onClick={() => paginate(currentPage + 1)}
                disabled={indexOfLastRow >= tableData.length}
                variant="success"
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IpdLeads;

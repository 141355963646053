import styled from "styled-components";
import numberToWords from "number-to-words";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
const DefaultpatientDetails = {
  patient_name: "GEETA BAI",
  age: "60/F",
  address: "VIJAY NAGAR, JABALPUR, (M.P.)",
  bill_number: "IPD/2024/157",
  employeName: "SELF",
  beneficiary_id: "IPD/24/57",
  caseNo: "CGW118033442",
  card_token_no: "2804378",
  bill_date: "13/06/2024",
  ward_bed_no: "GWM/01",
  treatment_date: "12/06/2024",
  doAdmitionTime: "10:30 AM",
  discharge_date: "13/06/2024",
  doDischargedTime: "10:30 AM",
  doctor_name: "DR. AKHILESH NEMA",
  department: "CGHS/PEN",
  condition_of_admission: "LEFT EYE CATARACT WITH IOL",
};

const defaultDiagnose = [
  { procedure_name: "CATARACT WITH 10L", code: "156", price: "10042.00" },
  { procedure_name: "IMPLANT (LENS)", code: "", price: "5800.00" },
];

const defaultBank = {
  bank_name: "UNION BANK OF INDIA",
  account_no: "898989898989",
  ifsc_code: "UBIN505225",
  br_micr_code: "46546565",
};
const FinalBillIPD = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const [patientDetails, setPatientDetails] = useState(DefaultpatientDetails);
  const [diagnoses, setDiagnoses] = useState(defaultDiagnose);
  const [hospitalDoc, setHospitalDoc] = useState([]);

  const [bankDetails, setBankDetails] = useState(defaultBank);
  const params = useParams();

  const displayDocHospital = async () => {
    console.log(user.id);
    try {
      const viewDoc = await axios.get(
        `https://test-cghs.doaguru.com/api/v1/auth/displayHospitalDoc/${user.id}`
      );

      console.log(viewDoc.data);
      setHospitalDoc(viewDoc.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetching = async () => {
    try {
      // Fetch bill details
      const res = await axios.get(
        `https://test-cghs.doaguru.com/api/v1/auth/getIPDBill/${params.billId}`
      );
      setPatientDetails(res.data);
      // Fetch patient procedures
      const res1 = await axios.get(
        `https://test-cghs.doaguru.com/api/v1/auth/getIPDPatientProcedures/${params.billId}`
      );
      setDiagnoses(res1.data.result);

      console.log(res);
      console.log(res1);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetching();
    displayDocHospital();
  }, []);

  return (
    <>
      <Component>
        <div className="px-4 py-5">
          <div>
            <img src={hospitalDoc?.header_img} alt="" width="100%" />
          </div>
          <div>
            <h5 className="text-center py-2">            
              {/* FINAL BILL (IPD) */}
              Final Patient Bill
            </h5>
          </div>
          <div className="d-flex flex-column justify-content-between">
            <div>
              <table className="w-100">
                <thead>
                  <th colSpan={3} className="border-top border-bottom border-secondary text-center ">{patientDetails.patientCategory}</th>
                </thead>
                <tbody className="">
                  <tr colSpan={3}> <div className="mb-3"></div></tr>
                  <tr>
                    <td>NAME OF PATIENT: {patientDetails.patient_name}</td>
                    <td>
                      AGE/SEX:{" "}
                      {`${patientDetails.age}/${
                        patientDetails.gender === "Male" ? "M" : "F"
                      }`}
                    </td>
                    <td>UHID NO: {patientDetails.bill_number}</td>
                  </tr>
                  <tr>
                    <td>
                      NAME OF THE EMPLOYEE: {patientDetails.bill_prepared_by}
                    </td>
                    <td>BENEFICIARY ID: {patientDetails.beneficiary_id}</td>
                    <td>ADDRESS: {patientDetails.address}</td>
                  </tr>
                  <tr>
                    <td>CARD/TOKEN NO: {patientDetails.card_token_no}</td>
                    <td>
                      DATE OF ADMISSION:{" "}
                      {moment(patientDetails.treatment_date).format(
                        "YYYY-MM-DD"
                      )}
                      <span className="px-2">AT</span>
                      {moment(patientDetails.doAdmitionTime).format("HH:mm")}
                    </td>
                    <td>DEP: {patientDetails.department}</td>
                  </tr>
                  <tr>
                    <td>CASE NO: {patientDetails.case_number}</td>
                    <td>
                      DATE OF DISCHARGE:{" "}
                      {moment(patientDetails.discharge_date).format(
                        "YYYY-MM-DD"
                      )}
                      <span className="px-2">AT</span>
                      {moment(patientDetails.doDischargedTime).format("HH:mm")}
                    </td>
                    <td>
                      BILL DATE:{" "}
                      {moment(patientDetails.bill_date).format("YYYY-MM-DD")}
                    </td>
                  </tr>
                  <tr>
                    <td>CONSULTANT INCHARGE: {patientDetails.doctor_name}</td>
                    <td>WARD/BED: {patientDetails.ward_bed_no}</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
              <table className="w-100 mt-2">
                <thead>
                  <tr className="border-top border-bottom border-secondary">
                    <th className="w-50">DISCRIPTION</th>
                    <th className="text-center">QUANTITY</th>
                    <th className="text-center">AMOUNT</th>
                  </tr>
                </thead>
                <tbody>
                  <tr colSpan={3} className="text-start" >Code  <span className="ms-5 ps-3">Proceduure</span></tr>
                  {/* <tr colSpan={3} className="text-start" > <div className="ps-5 ms-5">Proceduure</div> </tr> */}
                  {diagnoses.map((item, idx) => (
                    <tr key={idx}>
                      <td className="text-start">{item.code || "NA"}  <span className="ms-5 ps-5">{item.procedure_name}</span></td>
                      <td className="text-center"> {item.quantity} </td>
                      <td className="text-center">{item.price}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td className="pt-5"></td>
                  </tr>
                  <tr className="border-top border-bottom border-secondary">
                    <td colSpan={2}>TOTAL :</td>
                    <td className="text-center">
                      {diagnoses
                        .reduce((accu, item) => accu + Number(item.price), 0)
                        .toFixed(2)}
                    </td>
                  </tr>
                </tfoot>
              </table>
              <b>
                AMOUNT IN WORDS :{" "}
                {numberToWords
                  .toWords(
                    diagnoses.reduce(
                      (accu, item) => accu + Number(item.price),
                      0
                    )
                  )
                  .toUpperCase()}
              </b>
            </div>
            <div>
              {/* <div className="mt-5">
                <table>
                  <tbody>
                    <tr>
                      <td className="text-decoration-underline fw-semibold fs-5">
                        CREDIT BILLS RECOVERY DETAILS
                      </td>
                    </tr>
                    <tr>
                      <td>BANK NAME</td>
                      <td>{bankDetails.bank_name}</td>
                    </tr>
                    <tr>
                      <td>ACCOUNT NO. :</td>
                      <td>{bankDetails.account_no}</td>
                    </tr>
                    <tr>
                      <td>IFSC CODE: </td>
                      <td>{bankDetails.ifsc_code}</td>
                    </tr>
                    <tr>
                      <td>BR MICR CODE :</td>
                      <td>{bankDetails.br_micr_code}</td>
                    </tr>
                  </tbody>
                </table>
              </div> */}
            </div>
          </div>
        </div>
        <div className="position-absolute fixed-bottom d-none d-print-block">
          <div className="row">
            <div className="col-6 d-flex align-items-end ">
              <div>
                <h6 className="ms-5 preparedBy">
                  Prepared by{" "}
                  <span className="text-uppercase">
                    {patientDetails?.bill_prepared_by}
                  </span>
                </h6>
              </div>
            </div>

            <div className="col-6 d-flex align-items-end gap-4">
              <div className="sealimg">
                <img
                  src={hospitalDoc?.sealimg}
                  alt="header"
                  srcset=""
                  width="200px"
                  height="150px"
                />
              </div>

              <div className="signimg">
                <img
                  src={hospitalDoc?.signimg}
                  alt="header"
                  srcset=""
                  width="100px"
                  height="100px"
                />
              </div>
            </div>
          </div>

          <div className="footimage">
            <img src={hospitalDoc?.footer_img} alt="header" srcset="" />
          </div>
        </div>

        <div className="d-flex justify-content-center my-3 gap-2">
          <button
            type="button"
            className=" d-print-none btn btn-primary btn-lg"
            onClick={() => window.print()}
          >
            Print
          </button>
          <button
            type="button"
            className=" d-print-none btn btn-primary btn-lg"
            onClick={() => navigate("/admin-dashboard")}
          >
            Go to Dashboard
          </button>
        </div>
      </Component>
    </>
  );
};
export default FinalBillIPD;
const Component = styled.div`
  td {
    font-size: 14px;
  }
`;

import React, { useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { IoEyeOutline } from "react-icons/io5";
import { FaRegEyeSlash } from "react-icons/fa";
import Header from "../Layout/Header";
import axios from "axios";
import cogoToast from "cogo-toast";

const Registration = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState({
    hospital_name: "",
    type: "",
    address: "",
    contact: "",
    email: "",
    password: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Use spread syntax to update only the changed field
    setData({
      ...data,
      [name]: value,
    });
  };

  console.log(data);

  const register = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://test-cghs.doaguru.com/api/v1/auth/e-register",
        data
      );
      if(response?.data?.success){
        console.log(response.data.message);
        cogoToast.success(response?.data?.message);
        navigate("/");
      }
     
    } catch (error) {
      cogoToast.error(error?.response?.data?.error || "An Error occured");
      console.log(error?.response?.data?.error)
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <>
      <Header />
      <Container>
        <div className="container">
          <div className="row">
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12"></div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="boxContainer">
                <div className="formcontent">
                
                  <p>Enter your details below to continue</p>
                  <form onSubmit={register}>
                    <div className="mb-3">
                      <input
                        type="text"
                        placeholder="Hospital Name"
                        name="hospital_name"
                        value={data.hospital_name}
                        onChange={handleInputChange}
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <select
                       className="form-select"
                        id="hospitalType"
                        name="type"
                        value={data.type}
                        onChange={handleInputChange}
                        required
                      >
                        <option>--select Type--</option>
                        <option value="NABH">NABH</option>
                        <option value="Non NABH">Non NABH</option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <input
                        type="text"
                        placeholder="Enter Email ID"
                        name="email"
                        className="form-control"
                        value={data.email}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <input
                        type="text"
                        placeholder="Enter Contact Number"
                        name="contact"
                        maxLength={10}
                        className="form-control"
                        value={data.contact}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <textarea
                        type="text"
                        placeholder="Enter Address"
                        name="address"
                        className="form-control"
                        maxLength={30}
                        value={data.address}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div
                      className="mb-3"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <input
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter Password"
                        name="password"
                        className="form-control last-input"
                        // onChange={(e) => setPassword(e.target.value)}
                        value={data.password}
                        onChange={handleInputChange}
                        required
                      />
                      <button
                        type="button"
                        className="eyebtn"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <IoEyeOutline /> : <FaRegEyeSlash />}
                      </button>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button type="submit" className="btn btn-success">
                        Submit
                      </button>
                    </div>
                  </form>
                  <p>
                    Allready have an account?{" "}
                    <span>
                      <Link to="/user-home">Login</Link>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12"></div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Registration;
const Container = styled.div`
  background: linear-gradient(to right, #ffefba, #ffffff);
 

  .boxContainer {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .formcontent {
      background-color: #0dcaf0;
      padding: 1rem 2rem;
      border-radius: 1rem;
      height: auto;
      box-shadow: 1px 2px 34px #38c7e4;
      h1 {
        text-align: center;
        font-family: monospace;
        margin: 0rem 0;
        color: #08494c;
      }
      .form-label {
        margin-bottom: 0rem !important;
      }
      p {
        margin-bottom: 1rem;
      }
      .eyebtn {
        border: none;
        background: transparent;
        position: absolute;
        margin-left: 14rem;
      }
    }
  }
`;

import styled from "styled-components";
import Sidebar from "../../AdminDashboard/Sidebar";
import NavDash from "../../AdminDashboard/NavDash";
import { useEffect, useRef, useState } from "react";
import { RiDeleteBack2Fill, RiInputCursorMove } from "react-icons/ri";
import axios from "axios";
import cogoToast from "cogo-toast";
import { useParams } from "react-router-dom";
import { ImCross } from "react-icons/im";

// const defaultSurgicalProcedure = {
//   surgeryName: "Appendectomy",
//   surgeryDate: "2024-08-14",
//   surgeon: "Dr. A. Sharma",
//   anaesthesia: "General",
// };

// const defaultDiagnosis = {
//   name: "Acute Appendicitis",
// };

// const defaultMedicine = {
//   dischargeMedicine: "Amoxicillin",
//   dosage: "500mg",
//   freq: "Twice a day",
//   route: "Oral",
//   instruction: "After meals",
//   remark: "Complete the full course",
// };
const defaultSurgicalProcedure = {
  surgeryName: "",
  surgeryDate: "",
  surgeon: "",
  anaesthesia: "",
};

const defaultDiagnosis = {
  name: "",
};

const defaultMedicine = {
  dischargeMedicine: "",
  dosage: "",
  freq: "",
  route: "",
  instruction: "",
  remark: "",
};

// const defaultData = {
//   discharge_type: "Routine",
//   past_medical: "Diabetes",
//   post_operation: "Stable",
//   medical_history: "No known allergies",
//   TEMP: "98.6°F",
//   PULSE: "72 bpm",
//   BP: "120/80 mmHg",
//   SPO2: "98%",
//   GCS: "15/15",
//   CVS: "Normal",
//   RS: "Clear",
//   CHEST: "Normal",
//   RR: "16 breaths/min",
//   CNS: "Alert",
//   PA: "Soft, non-tender",
//   REMARK: "Patient is recovering well.",
//   discharge_TEMP: "98.7°F",
//   discharge_PULSE: "70 bpm",
//   discharge_BP: "118/78 mmHg",
//   discharge_SPO2: "99%",
//   discharge_GCS: "15/15",
//   discharge_CVS: "Normal",
//   discharge_RS: "Clear",
//   discharge_CHEST: "Normal",
//   discharge_RR: "18 breaths/min",
//   discharge_CNS: "Alert",
//   discharge_PA: "Non-tender",
//   discharge_REMARK: "No complications observed.",
//   hospital_course: "Patient admitted for appendectomy. Surgery successful with no complications.",
//   blood_transfusion: "None",
//   investigation: "Blood tests normal.",
//   procedure_note: "Laparoscopic appendectomy performed.",
//   discharge_advice: "Rest and avoid heavy lifting for 4 weeks.",
//   followUp_investigatiion: "Ultrasound in 6 weeks.",
//   followUp_urgent: "Report any signs of infection immediately.",
//   followUp_advice: "Follow-up visit in 1 week.",
//   other_byConsultant: "Monitor blood sugar levels regularly.",
//   printed_by: "Nurse A. Patel",
//   prepared_by: "Nurse A. Patel",
//   checked_by: "Dr. B. Gupta",
//   discharge_medicine: [
//     { ...defaultMedicine },
//     {
//       dischargeMedicine: "Ibuprofen",
//       dosage: "400mg",
//       freq: "Three times a day",
//       route: "Oral",
//       instruction: "After meals",
//       remark: "As needed for pain",
//     },
//   ],
//   surgical_Procedure: [
//     { ...defaultSurgicalProcedure },
//     {
//       surgeryName: "Laparoscopy",
//       surgeryDate: "2024-08-14",
//       surgeon: "Dr. R. Verma",
//       anaesthesia: "General",
//     },
//   ],
//   diagnosis: [
//     { ...defaultDiagnosis },
//     {
//       name: "Postoperative recovery",
//     },
//   ],
// };

// export default defaultData;

// const defaultSurgicalProcedure = {
//   surgeryName: "",
//   surgeryDate: "",
//   surgeon: "",
//   anaesthesia: "",
// };
// const defaultDiagnosis = {
//   name: "",
// };
// const defaultMedicine = {
//   dischargeMedicine: "",
//   dosage: "",
//   freq: "",
//   route: "",
//   instruction: "",
//   remark: "",
// };

const defaultData = {
  discharge_type: "",
  past_medical: "",
  post_operation: "",
  medical_history: "",
  TEMP: "",
  PULSE: "",
  BP: "",
  SPO2: "",
  GCS: "",
  CVS: "",
  RS: "",
  CHEST: "",
  RR: "",
  CNS: "",
  PA: "",
  REMARK: "",
  discharge_TEMP: "",
  discharge_PULSE: "",
  discharge_BP: "",
  discharge_SPO2: "",
  discharge_GCS: "",
  discharge_CVS: "",
  discharge_RS: "",
  discharge_CHEST: "",
  discharge_RR: "",
  discharge_CNS: "",
  discharge_PA: "",
  discharge_REMARK: "",
  hospital_course: "",
  blood_transfusion: "",
  investigation: "",
  procedure_note: "",
  discharge_advice: "",
  followUp_investigatiion: "",
  followUp_urgent: "",
  followUp_advice: "",
  other_byConsultant: "",
  printed_by: "",
  prepared_by: "",
  checked_by: "",
  discharge_medicine: [{ ...defaultMedicine }],
  surgical_Procedure: [{ ...defaultSurgicalProcedure }],
  diagnosis: [{ ...defaultDiagnosis }],
}

const defaultDropDown = {
  diagnosis: [
    { name: "diagnose 1" },
    { name: "diagnose 10" },
    { name: "diagnose 3" },
    { name: "diagnose 2" },
  ],
  surgeryName: [
    { name: "diagnose 1" },
    { name: "diagnose 10" },
    { name: "diagnose 8" },
    { name: "diagnose 6" },
  ],
  dischargeMedicine: [
    { name: "diagnose 1" },
    { name: "diagnose 10" },
    { name: "diagnose 8" },
    { name: "diagnose 6" },
  ],
};

const EditDischargeBill = () => {
  const [toggle, setToggle] = useState(true);
  const [data, setData] = useState(defaultData);
  const [dropDown, setDropDown] = useState(defaultDropDown);
  const [highLightDropDownItem, setHighLightDropDownItem] = useState(-1);
  // const [surgical_Procedure, setSurgicalProcedure] = useState([
  //   { ...defaultSurgicalProcedure },
  // ]);
  // const [discharge_medicine, setInputMedicine] = useState([{ ...defaultMedicine }]);
  // const [diagnosis, setInputDiagnose] = useState([{ ...defaultDiagnosis }]);

  const [trackDropDown, setTrackDropDown] = useState(false);
  const [activeElement, setActiveElement] = useState({
    dropDown: "",
    inputFocus: false,
  });
  const [suggestion, setSuggestion] = useState();
  const { billId } = useParams();

  const Toggle = () => {
    setToggle(!toggle);
  };

  const handleSurgeryChange = (e, index) => {
    const { name, value } = e.target;
    const updatedArray = [...data.surgical_Procedure];
    // const updatedArray = [...surgical_Procedure];
    updatedArray[index] = {
      ...updatedArray[index],
      [name]: value,
    };
    setActiveElement({ ...activeElement, dropDown: "" });
    // setSurgicalProcedure(updatedArray);
    setData({ ...data, surgical_Procedure: [...updatedArray] });
  };

  const handleDischargeMedicine = (e, index) => {
    const { name, value } = e.target;
    // const updatedArray = [...surgical_Procedure];
    const updatedArray = [...data.surgical_Procedure];
    updatedArray[index] = {
      ...updatedArray[index],
      [name]: value,
    };
    // setInputMedicine(updatedArray);
    setData({ ...data, discharge_medicine: [...updatedArray] });
  };

  const handleDiagnoseChange = (e, index) => {
    // let copyArr = diagnosis;
    let copyArr = data.diagnosis;
    copyArr[index] = { name: e.target.value };
    // setInputDiagnose([...copyArr]);
    setData({ ...data, diagnosis: [...copyArr] });
    setActiveElement({ ...activeElement, dropDown: "" });
  };

  const handleRemoveDiagnoseInput = (index) => {
    const updatedArray = [
      ...data.diagnosis,
    ];
    // const updatedArray = [
    //   ...diagnosis,
    // ];
    // const copyArr = diagnosis;
    const copyArr = data.diagnosis;
    console.log(copyArr, updatedArray);
    copyArr.splice(index, 1);
    updatedArray.splice(index, 1);
    if (updatedArray.length == 0) {
      // setInputDiagnose([
      //   { ...defaultDiagnosis },
      // ]);
      setData({ ...data, diagnosis: [defaultDiagnosis] });
    } else {
      // setInputDiagnose(copyArr);
      setData({ ...data, diagnosis: [...copyArr] });
    }
  }

  const handleDiagnoseDropDownList = (e, index) => {
    // let copyArr = diagnosis;
    let copyArr = data.diagnosis;
    console.log(copyArr);
    copyArr[index] = {
      name: e.target.textContent,
    };
    console.log(copyArr);
    // setInputDiagnose(copyArr);
    setData({ ...data, diagnosis: [...copyArr] });
    setActiveElement({
      ...activeElement,
      dropDown: "",
    });
  }

  const handleSurgicalDropDownList = (e, index) => {
    // let copyArr = surgical_Procedure;
    let copyArr = data.surgical_Procedure;
    console.log(copyArr);
    copyArr[index] = {
      surgeryName: e.target.textContent,
    };
    console.log(copyArr);
    // setSurgicalProcedure(copyArr);
    setData({ ...data, surgical_Procedure: [...copyArr] });
    setActiveElement({
      ...activeElement,
      dropDown: "",
    });
  }


  const handleSurgicalRemoveInput = (index) => {
    // const updatedArray = [...surgical_Procedure];
    const updatedArray = [...data.surgical_Procedure];
    updatedArray.splice(index, 1);
    // setSurgicalProcedure(updatedArray);
    setData({ ...data, surgical_Procedure: [...updatedArray] });
  }

  const handleDischargeMedicineRemoveInput = (index) => {
    const updatedArray = [...data.discharge_medicine];
    updatedArray.splice(index, 1);
    if (updatedArray.length == 0) {
      // setInputMedicine([
      //   { ...defaultMedicine },
      // ]);
      setData({ ...data, discharge_medicine: [{ ...defaultMedicine }] });
    } else {
      // setInputMedicine(updatedArray);
      setData({ ...data, discharge_medicine: [...updatedArray] });
    }
  }

  const handleMedicineDropDownList = (e, index) => {
    // let copyArr = discharge_medicine;
    let copyArr = data.discharge_medicine;
    console.log(copyArr);
    copyArr[index] = {
      dischargeMedicine: e.target.textContent,

    };
    console.log(copyArr);
    // setInputMedicine(copyArr);
    setData({ ...data, discharge_medicine: [...copyArr] });
    setActiveElement({
      ...activeElement,
      dropDown: "",
    });
  }


  const handleInputBlur = (e) => {
    if (trackDropDown) {
      let isDropDown = trackDropDown.tagName;
      if (isDropDown == "UL" || isDropDown == "LI") {
        setActiveElement({
          ...activeElement,
          inputFocus: "",
          dropDown: "",
          currentActiveInput: "",
        });
      } else {
        setTrackDropDown(false);
      }
    }
  };

  const handleInputFocus = (e) => {
    let name = e.target.name;
    let ele = e.target;
    if (ele.classList.contains("downlist")) {
      let len = ele.classList.length;
      let idx = ele.classList[len - 1];
      let createName = `${name}${idx}`;
      setActiveElement({
        ...activeElement,
        inputFocus: name,
        dropDown: createName,
        currentActiveInput: ele,
      });
    } else {
      setActiveElement({
        ...activeElement,
        inputFocus: name,
        dropDown: name,
        currentActiveInput: ele,
      });
    }
    console.log(e.target);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleKeyDown = (e, index) => {
    setHighLightDropDownItem(0);
    let targetInput = e.target.name;
    let currInput = e.target;
    let targetInputDropDown = dropDown[targetInput];
    if (targetInputDropDown.length > 0) {
      if (e.key == "ArrowDown") {
        let indexOfCurrDropDownItem = Math.min(highLightDropDownItem + 1, targetInputDropDown.length - 1);
        let parentDiv = currInput.parentElement;
        let grandParent = parentDiv.parentElement;
        let dropDownItemContainer = "";
        if (grandParent) {
          dropDownItemContainer = grandParent.querySelector("ul");
          if (dropDownItemContainer) {
            let containerRect = dropDownItemContainer.getBoundingClientRect();
            let dropDownFirstChild = dropDownItemContainer.firstElementChild;
            if (dropDownFirstChild) {
              let childRect = dropDownFirstChild.getBoundingClientRect();
              let containerHeight = containerRect.height;
              let childHeight = childRect.height;
              let lastElementOfContainer =
                (childHeight !== 0 ? containerHeight / childHeight : 0) - 2;
              if (Math.round(lastElementOfContainer) <= highLightDropDownItem) {
                dropDownItemContainer.scrollBy(0, childHeight);
              }
            }
          }
        }
        setHighLightDropDownItem(indexOfCurrDropDownItem);
      }
      if (e.key == "ArrowUp") {
        let idx = Math.max(highLightDropDownItem - 1, 0);
        setHighLightDropDownItem(idx);
        let parentDiv = currInput.parentElement;
        let grandParent = parentDiv.parentElement;
        let dropDownItemContainer = "";
        if (grandParent) {
          dropDownItemContainer = grandParent.querySelector("ul");
          if (dropDownItemContainer) {
            let containerRect = dropDownItemContainer.getBoundingClientRect();
            let dropDownFirstChild = dropDownItemContainer.firstElementChild;
            if (dropDownFirstChild) {
              let childRect = dropDownFirstChild.getBoundingClientRect();
              let containerHeight = containerRect.height;
              let childHeight = childRect.height;
              let lastElementOfContainer =
                (childHeight !== 0 ? containerHeight / childHeight : 0) - 2;

              console.log("Base Element Calculation:", Math.round(lastElementOfContainer));
              if (Math.round(lastElementOfContainer) >= highLightDropDownItem) {
                console.log("inthe base line ", lastElementOfContainer, highLightDropDownItem);
                dropDownItemContainer.scrollBy(0, -childHeight);
              }
            }
          }
        }
      }
      if (e.key == "Enter") {
        let idx = highLightDropDownItem;

        let items = dropDown[targetInput][idx];
        if (targetInput == "diagnosis") {
          // let copyArr = diagnosis;
          let copyArr = data.diagnosis;
          copyArr[index] = { name: items.name };
          // setInputDiagnose(copyArr);
          setData({ ...data, diagnosis: [...copyArr] });
        } else if (targetInput == "surgeryName") {
          let copyArr = data.surgical_Procedure;
          // let copyArr = surgical_Procedure;
          copyArr[index] = { surgeryName: items.name };
          console.log(copyArr);
          setData({ ...data, surgical_Procedure: [...copyArr] });
          // setSurgicalProcedure(copyArr);
        } else if (targetInput == "dischargeMedicine") {
          // let copyArr = discharge_medicine;
          let copyArr = data.discharge_medicine;
          copyArr[index] = { dischargeMedicine: items.name };
          console.log(copyArr);
          // setInputMedicine(copyArr);
          setData({ ...data, discharge_medicine: [...copyArr] });
        }
        setActiveElement({ ...activeElement, dropDown: "" });
      }
      if (e.key == "Tab") {
        setActiveElement({ ...activeElement, dropDown: "" });
      }
    }
  };
  const handleSubmit = async () => {
    console.log(data);
  
    try {
      let DataInJson = { ...data };
      for (let key in DataInJson) {
        if (Array.isArray(DataInJson[key])) {
          let convertingArraytoObj = {};
          let arr = DataInJson[key];
          arr.forEach((item, index) => {
            convertingArraytoObj[index] = item;
          });
          DataInJson[key] = JSON.stringify(convertingArraytoObj);
        }
      }
      console.log(DataInJson);
  
      // Update Discharge Summary
      const updateDischargeResponse = await axios.put(
        "https://test-cghs.doaguru.com/api/v1/auth/updateDischargeSummary",
        { ...DataInJson, patient_id: billId }
      );
  
      if (updateDischargeResponse.status === 200) {
        cogoToast.success("Discharge Summary Updated Successfully");
  
        // Update Examination Data
        const updateExaminationResponse = await axios.put(
          "https://test-cghs.doaguru.com/api/v1/auth/editExaminationData",
          { ...DataInJson, patient_id: billId }
        );
  
        if (updateExaminationResponse.status === 200) {
          cogoToast.success("Examination Data Updated Successfully");
        } else {
          cogoToast.error("Failed to update Examination Data");
        }
  
        window.open(`/DischargesSummary/${billId}`, "_blank");
      } else {
        cogoToast.error("Failed to update Discharge Summary");
      }
    } catch (error) {
      console.log(error);
      cogoToast.error("An error occurred while updating the data");
    }
  };
  

  const getAllDropDownList = async () => {
    try {
      const [
        diagnoseResponse,
        medicinesResponse,
        surgicalProcedureResponse,
      ] = await Promise.all([
        axios.get("https://test-cghs.doaguru.com/api/v1/auth/diagnose"),
        axios.get("https://test-cghs.doaguru.com/api/v1/auth/medicines"),
        axios.get("https://test-cghs.doaguru.com/api/v1/auth/surgical_procedure"),
      ]);

      setDropDown({
        ...dropDown,
        diagnosis: diagnoseResponse.data,
        surgeryName: surgicalProcedureResponse.data,
        dischargeMedicine: medicinesResponse.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const getPatientDischargeSummary = async () => {
    try {
      // Fetch both discharge summary and examination data
      const [dischargeResponse, examinationResponse] = await Promise.all([
        axios.get(`https://test-cghs.doaguru.com/api/v1/auth/getDischargeSummaryOfPatient/${billId}`),
        axios.get(`https://test-cghs.doaguru.com/api/v1/auth/getExaminationDataOfPatient/${billId}`)
      ]);
  
      // Discharge summary data
      let dischargeData = dischargeResponse.data.dischargesummarydetails;
      console.log(dischargeData);
      // Process discharge data if necessary
      for (let obj in dischargeData) {
        if (dischargeData[obj] && dischargeData[obj][0] === '{') {
          let arr = [];
          let jsonParse = JSON.parse(dischargeData[obj]);
          for (let innerObj in jsonParse) {
            arr.push(jsonParse[innerObj]);
          }
          dischargeData[obj] = arr;
        }
      }
  
      // Examination data
      const admissionData = examinationResponse.data.admissionData.find(item => item.type === 'admission') || {};
      const dischargeExaminationData = examinationResponse.data.dischargeData.find(item => item.type === 'discharge') || {};
  
      // Define default state for examination data
      const examinationDataProcessed = {
        TEMP: "",
        PULSE: "",
        BP: "",
        SPO2: "",
        GCS: "",
        CVS: "",
        RS: "",
        CHEST: "",
        RR: "",
        CNS: "",
        PA: "",
        REMARK: "",
        discharge_TEMP: "",
        discharge_PULSE: "",
        discharge_BP: "",
        discharge_SPO2: "",
        discharge_GCS: "",
        discharge_CVS: "",
        discharge_RS: "",
        discharge_CHEST: "",
        discharge_RR: "",
        discharge_CNS: "",
        discharge_PA: "",
        discharge_REMARK: ""
      };
  
      // Populate admission data
      for (const key in admissionData) {
        if (examinationDataProcessed.hasOwnProperty(key.toUpperCase())) {
          examinationDataProcessed[key.toUpperCase()] = admissionData[key];
        }
      }
  
      // Populate discharge data
      for (const key in dischargeExaminationData) {
        if (examinationDataProcessed.hasOwnProperty(`discharge_${key.toUpperCase()}`)) {
          examinationDataProcessed[`discharge_${key.toUpperCase()}`] = dischargeExaminationData[key];
        }
      }
  
      // Combine both data sets
      const combinedData = {
        ...dischargeData,
        ...examinationDataProcessed
      };
  
      // Update state with combined data
      console.log(combinedData);
      setData(combinedData);
    } catch (error) {
      console.error(error);
    }
  };
  
  

  useEffect(() => {
    getPatientDischargeSummary();
    getAllDropDownList();
    document.addEventListener("click", (e) => {
      let ele = e.target.tagName;
      console.log(ele);
      if (ele == "UL" || ele == "LI" || ele == "TEXTAREA") {
        setTrackDropDown(e.target);
      } else {
        setActiveElement({ ...activeElement, dropDown: "" });
      }
    });
  }, []);
  return (
    <>
      <Container>
        <div className="container-fluid bgcolor min-vh-100">
          <div className="row">
            {toggle && (
              <div className="col-2 bg-dark vh-100 position-fixed">
                <Sidebar />
              </div>
            )}
            {toggle && <div className="col-2"></div>}
            <div className="col mrg">
              <NavDash Toggle={Toggle} />
              <div className="mt-5 pb-5">
                <h4>Edit Discharge Summary</h4>
                <form>
                  <div
                    style={{ display: "flex", flexDirection: "column" }}
                    className="rounded bg-white p-3 py-5 gap-3"
                  >
                    <div>
                      <label>
                        <strong className="w-300">Discharge Type : </strong>
                        <input
                          type="text"
                          name="discharge_type"
                          onFocus={handleInputFocus}
                          onBlur={handleInputBlur}
                          maxLength={20}
                          value={data.discharge_type}
                          onChange={handleChange}
                          autoComplete="off"
                          placeholder="Enter Discharge Type"
                        />
                      </label>
                    </div>
                    <div>
                      <div className="d-inline-flex">
                        <strong className="w-300 align-self-start">
                          Diagnosis :
                        </strong>
                        <div>
                          <div style={{ width: "600px" }}>
                            {/* {diagnosis.map((item, index) => ( */}
                            {data?.diagnosis?.map((item, index) => (
                              <div className="w-100 my-2">
                                <span>
                                  <div>
                                    <div className="d-flex align-items-center ">
                                      <textarea
                                        style={{ minHeight: "50px" }}
                                        className={`w-100 ms-2 mb-0 downlist ${index}`}
                                        onFocus={handleInputFocus}
                                        onBlur={handleInputBlur}
                                        value={item.name}
                                        onChange={(e) =>
                                          handleDiagnoseChange(e, index)
                                        }
                                        type="text"
                                        name="diagnosis"
                                        autoComplete="off"
                                        placeholder="Enter Diagnosis"
                                        onKeyDown={(e) =>
                                          handleKeyDown(e, index)
                                        }
                                      />
                                      <div
                                        className="ms-2"
                                        onClick={() => handleRemoveDiagnoseInput(index)}
                                      >
                                        <ImCross
                                          style={{ fontSize: "20px" }}
                                          color="red"
                                        />
                                      </div>
                                    </div>
                                    <div className="position-relative">
                                      <ul
                                        style={{ zIndex: "1" }}
                                        className={`border overflow-auto position-absolute bg-white w-100 ms-2 ${activeElement.dropDown ===
                                          `diagnosis${index}`
                                          ? ""
                                          : "d-none"
                                          }`}
                                      >
                                        {dropDown?.diagnosis?.map(
                                          (item, idx) => (
                                            <li
                                              className={
                                                idx == highLightDropDownItem
                                                  ? "bg-success"
                                                  : ""
                                              }
                                              onClick={(e) => handleDiagnoseDropDownList(e, index)}
                                              key={item.id}
                                              value={item.name}
                                              onMouseOver={(e) =>
                                              (e.target.style.backgroundColor =
                                                "green")
                                              }
                                              onMouseOut={(e) =>
                                              (e.target.style.backgroundColor =
                                                "")
                                              }
                                            >
                                              {item.name}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                </span>
                              </div>
                            ))}
                          </div>
                          <div className="text-start mt-2">
                            <button
                              onClick={() =>
                                // setInputDiagnose((prev) => [
                                //   ...prev,
                                //   defaultDiagnosis,
                                // ]
                                setData({ ...data, diagnosis: [...data.diagnosis, defaultDiagnosis] })
                              }
                              type="button"
                              className="ms-3 btn btn-secondary "
                            >
                              Add new
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <label>
                        <span className="align-self-start w-300">
                          <div className="fw-bolder">
                            Medical history & presenting compliants
                          </div>
                          <div className="text-black-50">
                            Reason for Admission
                          </div>
                        </span>

                        <div style={{ width: "500px" }}>
                          <textarea
                            name="medical_history"
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            onChange={handleChange}
                            value={data.medical_history}
                            placeholder="Medical history & presenting compliants \n (Reason for Admission)"
                            className={`w-100 ms-2 `}
                            type="text"
                          />
                        </div>
                      </label>
                    </div>
                    <div>
                      <label>
                        <strong className="align-self-start w-300">
                          Past Medical :
                        </strong>
                        <div style={{ width: "500px" }}>
                          <textarea
                            name="past_medical"
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            onChange={handleChange}
                            value={data.past_medical}
                            placeholder="Past Medical"
                            className={`w-100 ms-2`}
                            type="text"
                          />
                        </div>
                      </label>
                    </div>
                    <div>
                      <label>
                        <strong className="align-self-start w-300">
                          Post Op. Surgery :
                        </strong>
                        <div style={{ width: "500px" }}>
                          <textarea
                            name="post_operation"
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            onChange={handleChange}
                            value={data.post_operation}
                            placeholder="Post Operation Surgery"
                            className={`w-100 ms-2`}
                            type="text"
                          />
                        </div>
                      </label>
                    </div>
                    <div>
                      <div>
                        <strong>Physical & Systematic Examination</strong>
                      </div>
                      <div className="row basic-examination">
                        <div className="col">
                          <div>
                            <label>
                              <strong>TEMP. :</strong>
                              <input
                                className="examination-input"
                                name="TEMP"
                                type="text"
                                placeholder="Unit °F"
                                onChange={handleChange}
                                value={data.TEMP}
                              />
                            </label>
                          </div>
                          <div>
                            <label>
                              <strong>PULSE :</strong>
                              <input
                                className="examination-input"
                                name="PULSE"
                                type="text"
                                placeholder="Unit bpm"
                                value={data.PULSE}
                                onChange={handleChange}
                              />
                            </label>
                          </div>
                          <div>
                            <label>
                              <strong>BP :</strong>
                              <input
                                className="examination-input"
                                name="BP"
                                type="text"
                                placeholder="Unit mmhg"
                                onChange={handleChange}
                                value={data.BP}
                              />
                            </label>
                          </div>
                        </div>
                        <div className="col">
                          <div>
                            <label>
                              <strong>SPO2 :</strong>
                              <input
                                className="examination-input"
                                name="SPO2"
                                type="text"
                                placeholder="Unit %"
                                onChange={handleChange}
                                value={data.SPO2}
                              />
                            </label>
                          </div>
                          <div>
                            <label>
                              <strong>GCS :</strong>
                              <input
                                className="examination-input"
                                name="GCS"
                                type="text"
                                onChange={handleChange}
                                value={data.GCS}
                              />
                            </label>
                          </div>
                          <div>
                            <label>
                              <strong>CVS :</strong>
                              <input
                                className="examination-input"
                                name="CVS"
                                type="text"
                                onChange={handleChange}
                                value={data.CVS}
                              />
                            </label>
                          </div>
                        </div>
                        <div className="col">
                          <div>
                            <label>
                              <strong>RS :</strong>
                              <input
                                className="examination-input"
                                name="RS"
                                type="text"
                                onChange={handleChange}
                                value={data.RS}
                              />
                            </label>
                          </div>
                          <div>
                            <label>
                              <strong>CHEST :</strong>
                              <input
                                className="examination-input"
                                name="CHEST"
                                type="text"
                                onChange={handleChange}
                                value={data.CHEST}
                              />
                            </label>
                          </div>
                          <div>
                            <label>
                              <strong>RR :</strong>
                              <input
                                className="examination-input"
                                name="RR"
                                type="text"
                                placeholder="breaths/min"
                                onChange={handleChange}
                                value={data.RR}
                              />
                            </label>
                          </div>
                        </div>
                        <div className="col">
                          <div>
                            <label>
                              <strong>CNS :</strong>
                              <input
                                className="examination-input"
                                name="CNS"
                                type="text"
                                onChange={handleChange}
                                value={data.CNS}
                              />
                            </label>
                          </div>
                          <div>
                            <label>
                              <strong>PA :</strong>
                              <input
                                className="examination-input"
                                name="PA"
                                type="text"
                                onChange={handleChange}
                                value={data.PA}
                              />
                            </label>
                          </div>
                          <div>
                            <label>
                              <strong>REMARK :</strong>
                              <input
                                className="examination-input"
                                name="REMARK"
                                type="text"
                                onChange={handleChange}
                                value={data.REMARK}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <label>
                        <strong className=" align-self-start w-300">
                          Hospital Course
                        </strong>
                        <div style={{ width: "500px" }}>
                          <textarea
                            name="hospital_course"
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            onChange={handleChange}
                            value={data.hospital_course}
                            placeholder="Hospital Course"
                            className={`w-100 ms-2`}
                            type="text"
                          />
                        </div>
                      </label>
                    </div>
                    <div>
                      <label>
                        <strong className="align-self-start w-300">
                          Blood Transfusion
                        </strong>
                        <div style={{ width: "500px" }}>
                          <textarea
                            name="blood_transfusion"
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            onChange={handleChange}
                            value={data.blood_transfusion}
                            placeholder="Blood Transfusion"
                            className={`w-100 ms-2`}
                            type="text"
                          />
                        </div>
                      </label>
                    </div>
                    <div>
                      <div className="d-inline-flex">
                        <strong className="align-self-start w-300">
                          Surgical Procedure
                        </strong>
                        <div>
                          {/* {surgical_Procedure.map((item, index) => ( */}
                          {data.surgical_Procedure.map((item, index) => (
                            <div style={{ maxWidth: "500px" }}>
                              <div className="ms-2">
                                <strong>Surgery</strong>
                                <div>
                                  <div>
                                    <textarea
                                      style={{ minHeight: "50px" }}
                                      name="surgeryName"
                                      className={`w-100 downlist ${index}`}
                                      onChange={(e) =>
                                        handleSurgeryChange(e, index)
                                      }
                                      type="text"
                                      placeholder=""
                                      onFocus={handleInputFocus}
                                      onBlur={handleInputBlur}
                                      value={item.surgeryName}
                                      onKeyDown={(e) => handleKeyDown(e, index)}
                                    />
                                  </div>
                                  <div className="position-relative">
                                    <ul
                                      style={{ zIndex: "1" }}
                                      className={`border overflow-auto position-absolute bg-white w-100 ms-2 ${activeElement.dropDown ===
                                        `surgeryName${index}`
                                        ? ""
                                        : "d-none"
                                        }`}
                                    >
                                      {dropDown?.surgeryName.map(
                                        (item, idx) => (
                                          <li
                                            className={
                                              idx == highLightDropDownItem
                                                ? "bg-success"
                                                : ""
                                            }
                                            key={item.id}
                                            value={item.name}
                                            onClick={(e) => handleSurgicalDropDownList(e, index)}
                                            onMouseOver={(e) =>
                                            (e.target.style.backgroundColor =
                                              "green")
                                            }
                                            onMouseOut={(e) =>
                                            (e.target.style.backgroundColor =
                                              "")
                                            }
                                          >
                                            {item.name}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex align-items-center">
                                <div>
                                  <strong className="ms-2">Date</strong>
                                  <input
                                    onChange={(e) =>
                                      handleSurgeryChange(e, index)
                                    }
                                    name="surgeryDate"
                                    type="date"
                                    value={item.surgeryDate}
                                  />
                                </div>
                                <div>
                                  <strong className="ms-2">Surgron</strong>
                                  <input
                                    onChange={(e) =>
                                      handleSurgeryChange(e, index)
                                    }
                                    name="surgeon"
                                    type="text"
                                    placeholder=""
                                    value={item.surgeon}
                                  />
                                </div>
                                <div>
                                  <strong className="ms-2">Anaesthesia</strong>
                                  <input
                                    onChange={(e) =>
                                      handleSurgeryChange(e, index)
                                    }
                                    name="anaesthesia"
                                    type="text"
                                    placeholder=""
                                    value={item.anaesthesia}
                                  />
                                </div>
                                <span
                                  onClick={() => handleSurgicalRemoveInput(index)}
                                >
                                  <ImCross color="red" />
                                </span>
                              </div>
                            </div>
                          ))}
                          <div className="text-start">
                            <button
                              onClick={() =>
                                // setSurgicalProcedure((prev) => [
                                //   ...prev,
                                //   defaultSurgicalProcedure,
                                // ])
                                setData({ ...data, surgical_Procedure: [...data.surgical_Procedure, defaultSurgicalProcedure] })
                              }


                              type="button"
                              className="ms-3 btn btn-secondary "
                            >
                              Add new
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <label>
                        <strong className="align-self-start w-300">
                          Investigation
                        </strong>
                        <div style={{ width: "500px" }}>
                          <textarea
                            name="investigation"
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            onChange={handleChange}
                            value={data.investigation}
                            placeholder="investigation"
                            className={`w-100 ms-2`}
                            type="text"
                          />
                        </div>
                      </label>
                    </div>
                    <div>
                      <label>
                        <strong className="align-self-start w-300">
                          Other Procedure Note
                        </strong>
                        <div style={{ width: "500px" }}>
                          <textarea
                            name="procedure_note"
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            onChange={handleChange}
                            value={data.procedure_note}
                            placeholder="Procedure Note"
                            className={`w-100 ms-2`}
                            type="text"
                          />
                        </div>
                      </label>
                    </div>
                    <div>
                      <div>
                        <strong>Condition On Discharge</strong>
                      </div>
                      <div className="row basic-examination">
                        <div className="col">
                          <div>
                            <label>
                              <strong>TEMP. :</strong>
                              <input
                                className="examination-input"
                                name="discharge_TEMP"
                                type="text"
                                   placeholder="Unit °F"
                                onChange={handleChange}
                                value={data.discharge_TEMP}
                              />
                            </label>
                          </div>
                          <div>
                            <label>
                              <strong>PULSE :</strong>
                              <input
                                className="examination-input"
                                name="discharge_PULSE"
                                type="text"
                                 placeholder="Unit bpm"
                                value={data.discharge_PULSE}
                                onChange={handleChange}
                              />
                            </label>
                          </div>
                          <div>
                            <label>
                              <strong>BP :</strong>
                              <input
                                className="examination-input"
                                name="discharge_BP"
                                type="text"
                                  placeholder="Unit mmhg"
                                onChange={handleChange}
                                value={data.discharge_BP}
                              />
                            </label>
                          </div>
                        </div>
                        <div className="col">
                          <div>
                            <label>
                              <strong>SPO2 :</strong>
                              <input
                                className="examination-input"
                                name="discharge_SPO2"
                                type="text"
                                placeholder="Unit %"
                                onChange={handleChange}
                                value={data.discharge_SPO2}
                              />
                            </label>
                          </div>
                          <div>
                            <label>
                              <strong>GCS :</strong>
                              <input
                                className="examination-input"
                                name="discharge_GCS"
                                type="text"
                                onChange={handleChange}
                                value={data.discharge_GCS}
                              />
                            </label>
                          </div>
                          <div>
                            <label>
                              <strong>CVS :</strong>
                              <input
                                className="examination-input"
                                name="discharge_CVS"
                                type="text"
                                onChange={handleChange}
                                value={data.discharge_CVS}
                              />
                            </label>
                          </div>
                        </div>
                        <div className="col">
                          <div>
                            <label>
                              <strong>RS :</strong>
                              <input
                                className="examination-input"
                                name="discharge_RS"
                                type="text"
                                onChange={handleChange}
                                value={data.discharge_RS}
                              />
                            </label>
                          </div>
                          <div>
                            <label>
                              <strong>CHEST :</strong>
                              <input
                                className="examination-input"
                                name="discharge_CHEST"
                                type="text"
                                onChange={handleChange}
                                value={data.discharge_CHEST}
                              />
                            </label>
                          </div>
                          <div>
                            <label>
                              <strong>RR :</strong>
                              <input
                                className="examination-input"
                                name="discharge_RR"
                                type="text"
                                placeholder="breaths/min"
                                onChange={handleChange}
                                value={data.discharge_RR}
                              />
                            </label>
                          </div>
                        </div>
                        <div className="col">
                          <div>
                            <label>
                              <strong>CNS :</strong>
                              <input
                                className="examination-input"
                                name="discharge_CNS"
                                type="text"
                                onChange={handleChange}
                                value={data.discharge_CNS}
                              />
                            </label>
                          </div>
                          <div>
                            <label>
                              <strong>PA :</strong>
                              <input
                                className="examination-input"
                                name="discharge_PA"
                                type="text"
                                onChange={handleChange}
                                value={data.discharge_PA}
                              />
                            </label>
                          </div>
                          <div>
                            <label>
                              <strong>REMARK :</strong>
                              <input
                                className="examination-input"
                                name="discharge_REMARK"
                                type="text"
                                onChange={handleChange}
                                value={data.discharge_REMARK}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <label>
                        <strong className="align-self-start w-300">
                          Advice On Discharge
                        </strong>
                        <div style={{ width: "500px" }}>
                          <textarea
                            name="discharge_advice"
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            onChange={handleChange}
                            value={data.discharge_advice}
                            placeholder="Advice On Discharge"
                            className={`w-100 ms-2`}
                            type="text"
                          />
                        </div>
                      </label>
                    </div>
                    <div>
                      <label>
                        <strong className="align-self-start w-300">
                          Follow Up Investigation
                        </strong>
                        <div style={{ width: "500px" }}>
                          <textarea
                            name="followUp_investigatiion"
                            placeholder="Follow Up Investigation"
                            className={`w-100 ms-2`}
                            type="text"
                            onChange={handleChange}
                            value={data.followUp_investigatiion}
                          />
                        </div>
                      </label>
                    </div>
                    <div>
                      <div className="d-inline-flex">
                        <strong className="w-300 align-self-start">
                          After Discharge Medicine
                        </strong>
                        <div>
                          {/* {discharge_medicine.map((item, index) => ( */}
                          {data.discharge_medicine.map((item, index) => (
                            <div>
                              <div style={{ maxWidth: "500px" }}>
                                <div>
                                  <strong className="ms-2">Medicine</strong>
                                  <div>
                                    <div>
                                      <textarea
                                        name="dischargeMedicine"
                                        className={`w-100 downlist ${index}`}
                                        onChange={(e) =>
                                          handleDischargeMedicine(e, index)
                                        }
                                        type="text"
                                        onFocus={handleInputFocus}
                                        onBlur={handleInputBlur}
                                        onKeyDown={(e) =>
                                          handleKeyDown(e, index)
                                        }
                                        placeholder=""
                                        value={item.dischargeMedicine}
                                      />
                                    </div>
                                    <div className="position-relative">
                                      <ul
                                        style={{ zIndex: "1" }}
                                        className={`border overflow-auto position-absolute bg-white w-100 ms-2 ${activeElement.dropDown ===
                                          `dischargeMedicine${index}`
                                          ? ""
                                          : "d-none"
                                          }`}
                                      >
                                        {dropDown?.dischargeMedicine.map(
                                          (item, idx) => (
                                            <li
                                              className={
                                                idx == highLightDropDownItem
                                                  ? "bg-success"
                                                  : ""
                                              }
                                              key={item.id}
                                              value={item.name}
                                              onClick={(e) => handleMedicineDropDownList(e, index)}
                                              onMouseOver={(e) =>
                                              (e.target.style.backgroundColor =
                                                "green")
                                              }
                                              onMouseOut={(e) =>
                                              (e.target.style.backgroundColor =
                                                "")
                                              }
                                            >
                                              {item.name}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <div className="d-flex flex-column">
                                    <strong className="ms-2">dosage</strong>
                                    <input
                                      style={{ width: "110px" }}
                                      onChange={(e) =>
                                        handleDischargeMedicine(e, index)
                                      }
                                      name="dosage"
                                      type="text"
                                      value={item.dosage}
                                    />
                                  </div>
                                  <div className="d-flex flex-column">
                                    <strong className="ms-2">freq</strong>
                                    <input
                                      style={{ width: "110px" }}
                                      onChange={(e) =>
                                        handleDischargeMedicine(e, index)
                                      }
                                      name="freq"
                                      type="text"
                                      value={item.freq}
                                    />
                                  </div>
                                  <div className="d-flex flex-column">
                                    <strong className="ms-2">route</strong>
                                    <input
                                      style={{ width: "110px" }}
                                      onChange={(e) =>
                                        handleDischargeMedicine(e, index)
                                      }
                                      name="route"
                                      type="text"
                                      value={item.route}
                                    />
                                  </div>
                                  <div className="d-flex flex-column">
                                    <strong className="ms-2">
                                      instruction
                                    </strong>
                                    <input
                                      style={{ width: "150px" }}
                                      onChange={(e) =>
                                        handleDischargeMedicine(e, index)
                                      }
                                      name="instruction"
                                      type="text"
                                      value={item.instruction}
                                    />
                                  </div>

                                  <div className="d-flex flex-column">
                                    <strong className="ms-2">remark</strong>
                                    <input
                                      style={{ width: "110px" }}
                                      onChange={(e) =>
                                        handleDischargeMedicine(e, index)
                                      }
                                      name="remark"
                                      type="text"
                                      value={item.remark}
                                    />
                                  </div>
                                  <span
                                    onClick={() => handleDischargeMedicineRemoveInput(index)}
                                  >
                                    <ImCross color="red" />
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}
                          <div className="text-start">
                            <button
                              onClick={() =>
                                // setInputMedicine((prev) => [
                                //   ...prev,
                                //   defaultMedicine,
                                // ]
                                setData({ ...data, discharge_medicine: [...data.discharge_medicine, defaultMedicine] })
                              }
                              type="button"
                              className="ms-3 btn btn-secondary "
                            >
                              Add new
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <label>
                        <strong className="align-self-start w-300">
                          Follow Up & Urgent Care
                        </strong>
                        <div style={{ width: "500px" }}>
                          <textarea
                            name="followUp_urgent"
                            placeholder="Follow Up & Urgent Care"
                            className={`w-100 ms-2`}
                            maxLength={50}
                            type="text"
                            value={data.followUp_urgent}
                            onChange={handleChange}
                          />
                        </div>
                      </label>
                    </div>
                    <div>
                      <label>
                        <strong className="align-self-start w-300">
                          Follow Up Advice & visit
                        </strong>
                        <div style={{ width: "500px" }}>
                          <textarea
                            name="followUp_advice"
                            placeholder="Follow Up & Urgent Care"
                            className={`w-100 ms-2`}
                            type="text"
                            maxLength={50}
                            onChange={handleChange}
                            value={data.followUp_advice}
                          />
                        </div>
                      </label>
                    </div>
                    <div>
                      <label>
                        <strong className="align-self-start w-300">
                          Any Other By Colsultant
                        </strong>
                        <div style={{ width: "500px" }}>
                          <textarea
                            name="other_byConsultant"
                            placeholder="Follow Up & Urgent Care"
                            className={`w-100 ms-2`}
                            type="text"
                            maxLength={50}
                            onChange={handleChange}
                            value={data.other_byConsultant}
                          />
                        </div>
                      </label>
                    </div>
                    <div>
                      <label>
                        <strong className="w-300">Printed By : </strong>
                        <input
                          type="text"
                          onFocus={handleInputFocus}
                          onBlur={handleInputBlur}
                          name="printed_by"
                          autoComplete="off"
                          maxLength={20}
                          placeholder="Enter Printed By "
                          onChange={handleChange}
                          value={data.printed_by}
                        />
                      </label>
                    </div>
                    <div>
                      <label>
                        <strong className="w-300">prepared By : </strong>
                        <input
                          type="text"
                          onFocus={handleInputFocus}
                          onBlur={handleInputBlur}
                          name="prepared_by"
                          autoComplete="off"
                          maxLength={20}
                          placeholder="Enter prepared By"
                          onChange={handleChange}
                          value={data.prepared_by}
                        />
                      </label>
                    </div>
                    <div>
                      <label>
                        <strong className="w-300">Checked By : </strong>
                        <input
                          type="text"
                          onFocus={handleInputFocus}
                          onBlur={handleInputBlur}
                          name="checked_by"
                          maxLength={20}
                          autoComplete="off"
                          placeholder="Enter Checked By"
                          onChange={handleChange}
                          value={data.checked_by}
                        />
                      </label>
                    </div>
                    <div className="text-end mt-4">
                      <button onClick={handleSubmit} type="button" className="btn btn-primary">
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};
export default EditDischargeBill;

const Container = styled.div`
  .bgcolor {
    background-color: #80bef5;
  }
  .divsettle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.8rem;
  }
  li {
    list-style: none;
  }
  ul {
    max-height: 10rem;
  }
  input {
    padding: 5px;
    border-radius: 6px;
    border: 1px solid black;
    margin: 10px;
  }
  label {
    display: inline-flex;
    align-items: center;
  }
  textarea {
    padding: 10px;
    border-radius: 10px;
    min-height: 90px;
  }
  .w-300 {
    width: 300px;
  }
  .basic-examination strong {
    width: 80px;
  }
    .examination-input {
    width: 110px;
    }
`;

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";

const defaulData = {
  patient_name : "Ranveta Parasite", 
  cr_number : "62015", 
}

const PreOpAsseSheet = () => {
  const navigate = useNavigate();
  const [patientDetails, setPatientDetails] = useState(defaulData);
  const params = useParams();


  const sections = [
    { label: 'Disease', key: 'disease' },
    { label: 'Hypersensitivity', key: 'hypersensitivity' },
    { label: 'Blood Group', key: 'bloodGroup' }
  ];
  const measurements = [
    { label: 'BP', unit: 'mmh' },
    { label: 'PR', unit: 'Hpm' },
    { label: 'SPO2', unit: '' },
    { label: 'HEIGHT', unit: 'Cms' },
    { label: 'WEIGHT', unit: 'Kg' }
  ];
  const medicalHistory = ["Hypertension", "Diabetes", "COPD", "Cancer", "Heart Disease", "Asthma", "Hepatitis", "Not Understand", "Respiratory", "TB", "Ulcer", "Kidney Disease", "Mental Disease", "Hypo/Hyperthyroidism", "Other"]

  const generateDots = (length) => '.'.repeat(length);

  const fetching = async () => {
    try {
      // Fetch bill details
      const res = await axios.get(`https://test-cghs.doaguru.com/api/v1/auth/getIPDBill/${params.billId}`);
      setPatientDetails(res.data);

      console.log(res);

    } catch (error) {
      console.error('Error fetching data:', error);
      
    }
  };

  useEffect(() => {
    fetching();
  }, []);
  return (
    <div className="mt-4">
      <div className="p-3 px-5">
        <div className="d-flex justify-content-between ">
          <p>
            Patient Name: <span>{patientDetails.patient_name}</span>
          </p>
          <p>
            CR No.: <span>{patientDetails?.cr_number || "NA"}</span>
          </p>
        </div>
        <div className="text-center mb-4">
          <p className="text-decoration-underline fw-bold fs-3">PRE OPERATIVE ASSESSMENT SHEET</p>
        </div>
        <div className="d-flex">
          <p className='fs-6'>Admission Through:</p>
          <div className='px-5 d-flex'>
            <div>
              <input className="mx-4" type="checkbox" id="casualty" />
              <label htmlFor="casualty">Casualty</label>
            </div>
            <div>
              <input className="mx-4" type="checkbox" id="elective" />
              <label htmlFor="elective">Elective</label>
            </div>
            <div>
              <input className="mx-4" type="checkbox" id="mlc" />
              <label htmlFor="mlc">MLC</label>
            </div>
          </div>
        </div>
        <div className=" d-flex">
          <p className='me-5'>Medical History:</p>
          <div className='w-75'>
            <div className='d-flex flex-wrap gap-2'>
              {medicalHistory.map((condition, index) => (
                <div key={index}>
                  <input className="mx-3" type="checkbox" id={condition} />
                  <label htmlFor={condition}>{condition}</label>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div>
          {sections.map((section, index) => (
            <div key={index} className="mb-3">
              <p>{section.label}: <span className='px-2'>{generateDots(120)}</span></p>
            </div>
          ))}
        </div>
        <div>
          <p > Medication History: <span className='px-2' > (anticoagulants/anti platelet/anticonvulsant/MAOI/{generateDots(40)} )
          </span></p>
         
          
        </div>
        <div>
          <p>Personal History: <span className='px-2'>Tobacco/Smoking/Alcohol/Any Other {generateDots(60)}</span></p>
          
        </div>
        <div>
          <p>General Examination: <span className='px-2'>Pallor/Cyanosis/Icterus/Edema/Clubbing/Temp{generateDots(40)}</span></p>
          
        </div>
        <div className="row">
        {measurements.map((measurement, index) => (
            <p key={index} className='col-4'>{measurement.label} <span>{generateDots(30)}</span> {measurement.unit}</p>
          ))}
        </div>
        <div className="d-flex">
          <p>CNS: <span className='mx-2'>{generateDots(120)}</span></p>
          
        </div>
        <div className="d-flex">
          <p>P/A: <span className='mx-2'>{generateDots(120)}</span></p>
          
        </div>
        <div className="d-flex">
          <div>
            <label htmlFor="valuables">Valuables/Belongings</label>
            <input className="mx-3" type="checkbox" id="valuables" />
          </div>
          <div>
            <label htmlFor="none">None</label>
            <input className="mx-3" type="checkbox" id="none" />
          </div>
        </div>
        <div className="d-flex">
          <p>Handed over to:</p>
          <p className='mx-3 '>Name & Signature of Attendant</p>
        </div>
        <div className="">
          <p>Patient Orientation to Environment :</p>
          <div >
            <div className='row'>   
              <div className="col-3 d-flex justify-content-between">
                <label htmlFor="valuables">Room</label>
                <input className="mx-3" type="checkbox" id="valuables" />
              </div>
              <div className="col-4 d-flex justify-content-between">
                <label htmlFor="valuables">Additional Task</label>
                <input className=" mx-3" type="checkbox" id="valuables" />
              </div>
            </div>
            <div className='row'>
              <div className="col-3 d-flex justify-content-between">
                <label htmlFor="valuables">Bathroom</label>
                <input className=" mx-3" type="checkbox" id="valuables" />
              </div>
              <div className="col-4 d-flex justify-content-between">
                <label htmlFor="valuables">Telephone Facilities</label>
                <input className="mx-3" type="checkbox" id="valuables" />
              </div>
            </div>
            <div className='row'>
              <div className="col-3 d-flex justify-content-between">
                <label htmlFor="valuables">Smoking Policy</label>
                <input className="mx-3" type="checkbox" id="valuables" />
              </div>
            </div>
          </div>
        </div>
        <div className='h-100 pt-3 text-end'>
           <p className='mt-5'>Time{generateDots(50)}</p>
        </div>
      </div>
      <div className='d-flex justify-content-center my-3 gap-2'>
                  <button type="button" className=" d-print-none btn btn-primary btn-lg" onClick={() => window.print()}>Print</button>
                  <button type="button" className=" d-print-none btn btn-primary btn-lg" onClick={()=>navigate('/admin-dashboard')}>Go to Dashboard</button>
                  </div>
    </div>
  )
}

export default PreOpAsseSheet;

import React, { useEffect, useRef, useState } from 'react';
import { styled } from "styled-components";
import { useNavigate,useParams } from "react-router-dom";
import axios from "axios";
import cogoToast from "cogo-toast";
import { useSelector } from "react-redux";
import moment from "moment";
import Sidebar from '../AdminDashboard/Sidebar';
import NavDash from '../AdminDashboard/NavDash';
import HeadFoot from './HeadFoot';


const Profile = () => {
    const user = useSelector((state) => state.user);

    console.log(user);
    const navigate = useNavigate();
    const [toggle, setToggle] = useState(true);
    const Toggle = () => {
      setToggle(!toggle);
    };
  
    const handlePasswordChange = async(e) =>{
      e.preventDefault();
        // Display a confirmation dialog
    const userConfirmed = window.confirm("Are you sure you want to Change password?");
    if(userConfirmed){
      try{
        const res =await axios.post("https://test-cghs.doaguru.com/api/v1/auth/forgot-password",{email:user.email});
        if (res?.data && res?.data?.success === true) {
         
         console.log(res?.data);
         cogoToast.success(`${res?.data?.message}`);
         navigate("/admin-dashboard");
   
        
         return;
       }
       if (res.data && res.data.success === false) {
        
         cogoToast.error(`${res?.data?.message}`);
         return;
       }
     }
     catch(err){
       if (err?.response && err.response?.data) {
           
         console.log(err);
         cogoToast.error(`${err.response?.data?.message}`);
       }  
   
     }
    }
     
    
    }

  return (
    <Container>
    <div className="container-fluid bgcolor min-vh-100">
          <div className="row">
            {toggle && (
              <div className="col-2 bg-dark vh-100 position-fixed">
                <Sidebar />
              </div>
            )}
            {toggle && <div className="col-2"></div>}
            <div className="col mrg">
              <NavDash Toggle={Toggle} />

    <div className='container mt-5 mb-2 pb-5'>
        <div className='row'>
        <div className='d-flex justify-content-end gap-2'>
            <button type="button" onClick={()=>navigate('/update-profile')} className="btn btn-primary">Update Information</button>
            <button type="button" onClick={handlePasswordChange} className="btn btn-primary">Change Password</button>
            </div>

        </div>
        <div className='row'>
            <div className='col-10'>
                  <h5 className=' heading p-2 text-center'>Hospital Information</h5>
            </div>

        </div>

       
     <div className="row mt-2 pb-4">
          <div className="col-6 d-flex justify-content-center mx-auto">
            <table className="table table-borderless">
              <tbody>
                <tr>
                  <th scope="col">Hospital Name</th>
                  <td>{": "}{user.name}</td>
                  </tr> 

                <tr>
                <th scope="col">Type</th>
    <td>{": "}{user.type}</td>
                </tr>
                <tr>
                  <th scope="col">Address</th>
                  <td>{": "}{user.address}</td>
                  </tr>
                  <tr>
                  <th scope="col">Contact</th>
                  <td>{": "}{user.contact}</td>
                  </tr>
                  <tr>
                  <th scope="col">Email</th>
                  <td className='text-lowercase'>{": "}{user.email}</td>
                </tr>
                  <tr>
                  <th scope="col">Bank Name</th>
                  <td className='text-lowercase'>{": "}{user.bank_name}</td>
                </tr>
                  <tr>
                  <th scope="col">Account no.</th>
                  <td className='text-lowercase'>{": "}{user.account_no}</td>
                </tr>
                  <tr>
                  <th scope="col">IFSC Code</th>
                  <td className='text-lowercase'>{": "}{user.ifsc_code}</td>
                </tr>
                  <tr>
                  <th scope="col">BR MICR code</th>
                  <td className='text-lowercase'>{": "}{user.br_micr_code}</td>
                </tr>
               
              </tbody>
             
            </table>
           
          </div>
        </div> 
    </div>
    <HeadFoot/>
    </div>
    </div>

    
    </div>
    </Container>
  )
}

export default Profile;

const Container = styled.div`
     .bgcolor {
    background-color: #80bef5;
  }

  .divsettle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.8rem;
  }

  li {
    list-style: none;
  }
  th{
    text-align: start;
    white-space: nowrap;
    
    
  }
   td {
   
    text-align: start !important;
    white-space: nowrap !important;
    font-weight: 500 !important;
    text-transform: uppercase;
  }
`
import React, { useEffect, useState } from "react";
import { Table, Input, Button, Form } from "react-bootstrap";
import styled from "styled-components";
import Sidebar from "../AdminDashboard/Sidebar";
import NavDash from "../AdminDashboard/NavDash";
import { HiSortAscending, HiSortDescending } from "react-icons/hi";
import cogoToast from "cogo-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import IpdLeads from "./IPD/ipdLeads";



const SeeLeads = () => {
  // { id: 1, name: "John Doe", age: 25, city: "New York" },
  //   { id: 2, name: "Jane Doe", age: 30, city: "Los Angeles" },
  //   { id: 3, name: "Bob Smith", age: 22, city: "Chicago" },
  const user = useSelector((state) => state.user);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [dateFilter, setDateFilter] = useState({ startDate: "", endDate: "" });

  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [procedure, setProcedure] = useState([]);
  const [patientProcedure, setPatientProcedure] = useState([]);
  const [isIPD, setIPD] = useState(false);

  const navigate = useNavigate();
  let totalAmount = 0;

 

 
  const getAllBill = async () => {
    try {
      const response = await axios.get(
        `https://test-cghs.doaguru.com/api/v1/auth/getAllPatientBill/${user.id}`
      );
      console.log(response.data);
      setData(response.data);
    } catch (error) {
      console.log(error);
      cogoToast.error("error in getting bills");
    }
  };

  const getProcedures = async () => {
    const response = await axios.get(
      "https://test-cghs.doaguru.com/api/v1/auth/getAllProcedures"
    );
    console.log(response);
    setProcedure(response.data.result);
  };

  
  const getPatientProcedures = async () => {
    const response = await axios.get(
      "https://test-cghs.doaguru.com/api/v1/auth/getpatientProcedure"
    );
    console.log(response);
    setPatientProcedure(response.data.result);
  };

  useEffect(() => {
    getAllBill();
    getProcedures();
    getPatientProcedures();

  }, []);
  
  const calculateTotalAmount = (rowId,hospital_type)=>{
    totalAmount = 0;

    const row = patientProcedure.filter((row) => row.patient_id === rowId);
    if(row[0]?.price){
       row.map(
        (row) => (totalAmount += (Number(row.price) * Number(row.quantity)) )
       )
    }
    else{
    patientProcedure
    .filter((item) => item.patient_id == rowId)
    .map((filteredItem, index) => {
      console.log(filteredItem)
      console.log(filteredItem.procedure_name == procedure[0].procedure_name)
      const matchedProcedure = procedure.find(
        (procedureItem) => procedureItem.procedure_name.trim() == filteredItem.procedure_name.trim()
      );
      console.log(patientProcedure)
   
    
      // Initialize totalAmount
      
      console.log(matchedProcedure)
      
      // Display amount if procedure is found
      if (matchedProcedure) {
        totalAmount += hospital_type === "NABH"
          ? ((Number(matchedProcedure.nabh_amount )) * Number(filteredItem.quantity))
          : ((Number(matchedProcedure.non_nabh_amount )) * Number(filteredItem.quantity));
          console.log((matchedProcedure))
      }
      
      
      
  })}
    return totalAmount};


  // const calculateTotalAmount = (rowId,hospital_type)=>{
  //   totalAmount = 0;
  //   patientProcedure
  //   .filter((item) => item.patient_id == rowId)
  //   .map((filteredItem, index) => {
  //     console.log(filteredItem)
  //     console.log(filteredItem.procedure_name == procedure[0].procedure_name)
  //     const matchedProcedure = procedure.find(
  //       (procedureItem) => procedureItem.procedure_name.trim() == filteredItem.procedure_name.trim()
  //     );
  //     console.log(patientProcedure)
   
    
  //     // Initialize totalAmount
      
  //     console.log(matchedProcedure)
      
  //     // Display amount if procedure is found
  //     if (matchedProcedure) {
  //       totalAmount += hospital_type === "NABH"
  //         ? ((Number(matchedProcedure.nabh_amount )) * Number(filteredItem.quantity))
  //         : ((Number(matchedProcedure.non_nabh_amount )) * Number(filteredItem.quantity));
  //         console.log((matchedProcedure))
  //     }
      
      
      
  // })
  //   return totalAmount};

  const handleEdit = (bill_id) =>{
    navigate(`/edit-single-patient/${bill_id}`)
  }
 


  const handleDelete = async (bill_id) => {
    // Display a confirmation dialog
    const userConfirmed = window.confirm("Are you sure you want to delete this bill?");
  
    // Check if the user confirmed the deletion
    if (userConfirmed) {
      try {
        // Make the API request to delete the bill
        const response = await axios.delete(
          `https://test-cghs.doaguru.com/api/v1/auth/deleteBill/${bill_id}`
        );
  
        // Check if the deletion was successful
        if (response.data.success) {
          // Remove the deleted bill from the state
          setData((prevData) => prevData.filter((item) => item.bill_id !== bill_id));
  
          // Display a success message
          cogoToast.success("Bill Deleted successfully");
        } else {
          // Handle the case where the deletion was not successful
          cogoToast.error("Error deleting bill");
        }
      } catch (error) {
        console.log(error);
        // Handle errors related to the deletion process
      }
    }
  };

  

  console.log(data);
  console.log(patientProcedure)

  console.log(procedure?.map((item) => item.procedure_name));
  // Sorting function
  const handleSort = (key) => {
    if (sortBy === key) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(key);
      setSortOrder("asc");
    }
  };

  // Searching function
  // const handleSearch = (event) => {
  //   setSearchTerm(event.target.value);
  //   setCurrentPage(1); // Reset to the first page when searching
  // };

   // Searching function
   const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    setCurrentPage(1); // Reset to the first page when searching

    const filteredResults = data.filter((row) =>
      row.patient_name.toLowerCase().includes(searchTerm)
    );

    setFilteredData(filteredResults);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  // Pagination functions
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = searchTerm ? filteredData.slice(indexOfFirstRow, indexOfLastRow) : data.slice(indexOfFirstRow, indexOfLastRow);


  const totalPages = Math.ceil(data.length / rowsPerPage);

  const paginate = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const [toggle, setToggle] = useState(true);
  const Toggle = () => {
    setToggle(!toggle);
  };

  const arrowIcon = (key) => {
    if (sortBy === key) {
      return sortOrder === "asc" ? <HiSortAscending /> : <HiSortDescending />;
    }
    return null;
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(data.length / rowsPerPage); i++) {
    pageNumbers.push(i);
  }

  // const renderPageNumbers = pageNumbers.map((number) => (
  //   <Button
  //     key={number}
  //     onClick={() => paginate(number)}
  //     className={number === currentPage ? "active" : ""}
  //   >
  //     {number}
  //   </Button>
  // ));

  // const renderPageNumbers = pageNumbers.map((number) => {
  //   // Display only the current page and the two adjacent pages
  //   if (
  //     number === currentPage ||
  //     number === currentPage - 1 ||
  //     number === currentPage + 1
  //   ) {
  //     return (
  //       <Button
  //         key={number}
  //         onClick={() => paginate(number)}
  //         className={number === currentPage ? "active" : ""}
  //       >
  //         {number}
  //       </Button>
  //     );
  //   }
  //   // Display ellipsis for other page numbers
  //   else if (
  //     number === currentPage - 2 ||
  //     number === currentPage + 2
  //   ) {
  //     return (
  //       <Button key={number} disabled>
  //         ...
  //       </Button>
  //     );
  //   }
  //   return null;
  // });


  const renderPageNumbers = pageNumbers.map((number, index) => {
    // Display the first two page numbers
    if (index < 2) {
      return (
        <Button
          key={number}
          onClick={() => paginate(number)}
          className={number === currentPage ? "active" : ""}
        >
          {number}
        </Button>
      );
    }
    // Display an ellipsis for the first middle section
    else if (index === 2 && currentPage > 3) {
      return (
        <Button key={number} disabled>
          ...
        </Button>
      );
    }
    // Display the current page and the two adjacent pages
    else if (
      (index >= currentPage - 1 && index <= currentPage + 1) ||
      (index === 2 && currentPage <= 3)
    ) {
      return (
        <Button
          key={number}
          onClick={() => paginate(number)}
          className={number === currentPage ? "active" : ""}
        >
          {number}
        </Button>
      );
    }
    // Display an ellipsis for the last middle section
    else if (index === pageNumbers.length - 3 && currentPage < pageNumbers.length - 2) {
      return (
        <Button key={number} disabled>
          ...
        </Button>
      );
    }
    // Display the last two page numbers
    else if (index >= pageNumbers.length - 2) {
      return (
        <Button
          key={number}
          onClick={() => paginate(number)}
          className={number === currentPage ? "active" : ""}
        >
          {number}
        </Button>
      );
    }
    return null;
  });
  const handleDateChange = (e) => {
    const { value, name } = e.target;
    setDateFilter({ ...dateFilter, [name]: value });
    console.log(dateFilter.startDate, dateFilter.endDate);
  };
  
  const handleSortByDate = () => {
    if (dateFilter.startDate && dateFilter.endDate) {
      setCurrentPage(1);
      let s_Date = new Date(dateFilter.startDate).getTime();
      let e_Date = new Date(dateFilter.endDate).getTime();
      const filteredResults = data.filter((row) => {
        let getDate = new Date(row.bill_date).getTime();
        if (getDate <= e_Date && getDate >= s_Date) return true;
      });

      setFilteredData(filteredResults);
      setSearchTerm(" ");
      console.log(filteredResults);
    }
  };

  const transformData = (rows) => {
    return rows.map(row => ({
        bill_Id: row.bill_Id,
        patientCategory: row.patientCategory,
        patient_name: row.patient_name,
        age: row.age,
        gender: row.gender,
        treatment_date: row.treatment_date,
        discharge_date: row.discharge_date,
        bill_date: row.bill_date,
        beneficiary_id: row.beneficiary_id,
        doctor_name: row.doctor_name,
        ward_bed_no: row.ward_bed_no,
    }));
};

  const downloadExcel = () => {
    const transformedData = transformData(currentRows);

    fetch('https://test-cghs.doaguru.com/api/v1/auth/download-excel', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(transformedData),
    })
        .then(response => response.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'example.xlsx'); // Filename
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        })
        .catch(error => console.error('Error downloading the file:', error));
};

  

  console.log(currentRows);
  console.log(procedure?.map((item) => item.procedure_name));

  // const totalValue = currentRows.filter((item)=>{
  //   const procedureTotal =
  // })
  // console.log(currentRows[5].procedure_first);
  return (
    <>
      <Container>
        <div className="container-fluid bgcolor min-vh-100">
          <div className="row">
            {toggle && (
              <div className="col-2 bg-dark vh-100 position-fixed">
                <Sidebar />
              </div>
            )}
            {toggle && <div className="col-2"></div>}
            <div className="col mrg overflow-auto">
              <div className="">
              <NavDash Toggle={Toggle} />
              </div>
              <div className="">
                <div className="row mb-3">
                <div className="m-auto col-5">
                        <button onClick={() => setIPD(true)} className="btn btn-primary py-2 px-3">IPD</button>
                        <button onClick={() => setIPD(false)} className="btn btn-success mx-2 py-2 px-3">OPD</button>
                    </div>
                    <div className="col">
                      <h2 className="fw-bold"> {isIPD ? "IPD PATIENT" : "OPD Patient"}  </h2>
                    </div>
                </div>
              {!isIPD ? ( <>
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <Form.Group
                      controlId="rowsPerPageSelect"
                      style={{ display: "flex" }}
                    >
                      <Form.Label className="d-flex align-items-center">
                        Rows Per Page :{" "}
                      </Form.Label>
                      <Form.Control
                        as="select"
                        value={rowsPerPage}
                        className="m-2"
                        style={{ width: "auto" }}
                        onChange={handleRowsPerPageChange}
                      >
                        
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        {/* Add more options as needed */}
                      </Form.Control>
                    </Form.Group>
                  </div>
                  <div class="row">
          <div class="col">
            <input
              id="startDate"
              name="startDate"
              value={dateFilter.startDate}
              onChange={handleDateChange}
              type="date"
              class="form-control"
            />
          </div>
          <div class="col">
            <input
              id="endDate"
              name="endDate"
              value={dateFilter.endDate}
              onChange={handleDateChange}
              type="date"
              class="form-control"
            />
          </div>
        </div>
        <div class="">
      <button onClick={handleSortByDate} class="btn btn-primary me-2">Filter By Date</button>
      {/* <button onClick={() => setDateFilter({startDate: "", endDate: ""})} class="btn btn-primary me-2">Reset Date filter</button> */}
      <button onClick={() => {setFilteredData(data); setDateFilter({ startDate: "", endDate: ""})}} class="btn btn-primary me-2">Reset Date filter</button>
      <button onClick={downloadExcel} class="btn btn-success">Download Excel</button>
    </div>

                  <div>
                    <label htmlFor="">Search by Name : </label>
                    <input
                      type="text"
                      placeholder="Search by name"
                      value={searchTerm}
                      onChange={handleSearch}
                      className="m-2 rounded"
                    />
                  </div>
                </div>
                <div style={{ overflowX: "auto" }}>
                 <Table striped bordered hover>
                    <thead className="thbody">
                      <tr>
                        <th>
                          Bill ID {arrowIcon("id")}
                        </th>
                        {/* <th onClick={() => handleSort("name")}>
                          Hospital Name {arrowIcon("name")}
                        </th> */}
                        <th>
                          Dep. Type {arrowIcon("age")}
                        </th>
                        {/* <th onClick={() => handleSort("city")}>
                        Reg No {arrowIcon("city")}
                        </th> */}
                        {/* <th onClick={() => handleSort("city")}>
                          OPD Number{arrowIcon("city")}
                        </th> */}
                        {/* <th onClick={() => handleSort("city")}>
                          IPD Number{arrowIcon("city")}
                        </th> */}
                        {/* <th onClick={() => handleSort("city")}>
                          Bill Number{arrowIcon("city")}
                        </th> */}
                        <th >
                          Patient Name{arrowIcon("city")}
                        </th>
                        <th >
                          Age{arrowIcon("city")}
                        </th>
                        <th >
                          Gender{arrowIcon("city")}
                        </th>
                        <th >
                          Bill Date{arrowIcon("city")}
                        </th>
                        <th >
                          Beneficiary ID{arrowIcon("city")}
                        </th>
                        {/* <th onClick={() => handleSort("city")}>
                          Treatment Date{arrowIcon("city")}
                        </th> */}
                        {/* <th onClick={() => handleSort("city")}>
                          Patient Address{arrowIcon("city")}
                        </th> */}
                        {/* <th onClick={() => handleSort("city")}>
                          Refferal ID{arrowIcon("city")}
                        </th> */}
                        <th >
                          Dr. Name{arrowIcon("city")}
                        </th>
                        {/* <th onClick={() => handleSort("city")}>
                          Bill Prepared By{arrowIcon("city")}
                        </th> */}
                        {/* <th onClick={() => handleSort("city")}>
                          Procedures{arrowIcon("city")}
                        </th> */}
                        <th >
                        Amount{arrowIcon("city")}
                        </th>
                        <th>Edit</th>
                        <th>Report</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentRows?.length > 0 ? ( currentRows
                        .sort((a, b) => {
                          if (sortBy) {
                            const order = sortOrder === "asc" ? 1 : -1;
                            return a[sortBy] > b[sortBy] ? order : -order;
                          }
                          return 0;
                        })
                        ?.map((row) => (
                          <tr>
                            <td>{row.bill_id}</td>
                            {/* <td>{row.hospital_name}</td> */}
                            <td>{row.dept_type}</td>
                            {/* <td>{row.reg_number}</td> */}
                            {/* <td>{row.opd_number}</td> */}
                            {/* <td>{row.ipd_number}</td> */}
                            {/* <td>{row.bill_number}</td> */}
                            <td>{row.patient_name}</td>
                            <td>{row.age}</td>
                            <td>{row.gender}</td>
                            <td>{row.bill_date ? moment(row.bill_date).format('DD/MM/YYYY') : ""}</td>
                            <td>{row.beneficiary_Id}</td>
                            {/* <td>{row.date_of_treatment}</td> */}
                            {/* <td>{row.address}</td> */}
                            {/* <td>{row.refferal_id}</td> */}
                            <td>{row.doctor_name}</td>
                            {/* <td>{row.bill_prepared_by}</td> */}
                            {/* <td>
                              <button
                                type="button"
                                class="btn btn-primary"
                                data-toggle="modal"
                                data-target={`#myModal-${row.bill_id}`}
                                data-row={JSON.stringify(row)}
                              >
                                Open Modal
                              </button>

                              <div
                                class="modal fade"
                                id={`myModal-${row.bill_id}`}
                                tabindex="-1"
                                role="dialog"
                                aria-labelledby={`myModalLabel-${row.bill_id}`}
                                aria-hidden="true"
                              >
                                <div class="modal-dialog" role="document">
                                  <div class="modal-content">
                                  
                                    <div class="modal-body">
                                      <table class="table" border="1">
                                        <thead>
                                          <tr>
                                            <th>Procedure Name</th>
                                            <th>Procedure Quantity</th>
                                            <th>Amount</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>{row.procedure_first}</td>
                                            <td>
                                              {row.procedure_quantity_first}
                                            </td>
                                            <td>Amount</td>
                                          </tr>
                                          {row.procedure_second && (
                                            <tr>
                                              <td>{row.procedure_second}</td>
                                              <td>
                                                {row.procedure_quantity_second}
                                              </td>
                                              <td>Amount</td>
                                            </tr>
                                          )}
                                          {row.procedure_third && (
                                            <tr>
                                              <td>{row.procedure_third}</td>
                                              <td>
                                                {row.procedure_quantity_third}
                                              </td>
                                              <td>Amount</td>
                                            </tr>
                                          )}
                                          {row.procedure_fourth && (
                                            <tr>
                                              <td>{row.procedure_fourth}</td>
                                              <td>
                                                {row.procedure_quantity_fourth}
                                              </td>
                                              <td>Amount</td>
                                            </tr>
                                          )}
                                          {row.procedure_fifth && (
                                            <tr>
                                              <td>{row.procedure_fifth}</td>
                                              <td>
                                                {row.procedure_quantity_fifth}
                                              </td>
                                              <td>Amount</td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                    <div class="modalbtn">
                                      <button
                                        type="button"
                                        class="btn btn-danger rounded"
                                        data-dismiss="modal"
                                      >
                                        Close
                                      </button>
                                      <button
                                        type="button"
                                        class="btn btn-info bluebtn"
                                      >
                                        Save changes
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td> */}

                            {/* <td>{patientProcedure
    .filter((item) => item.patient_id === row.bill_id)
    .map((filteredItem, index) => {
      const matchedProcedure = procedure.find(
        (procedureItem) => procedureItem.procedure_name === filteredItem.procedure_name
      );

      // Initialize totalAmount
      let totalAmount = 0;
      console.log(matchedProcedure)
      
      // Display amount if procedure is found
      if (matchedProcedure) {
        totalAmount += row.hospital_type === "NABH"
          ? Number(matchedProcedure.nabh_amount)
          : Number(matchedProcedure.non_nabh_amount);
      }

      return (
        <span key={index}>
          {matchedProcedure ? totalAmount : "Amount not found"}
        </span>
      );
    })}</td> */}
     <td>
      {calculateTotalAmount(row.bill_id,user.type)}
     </td>
                            <td>
                              <div className="d-flex">
                                <button className="btn btn-warning" onClick={()=> handleEdit(row.bill_id)}>
                                  Edit
                                </button>
                                <button
                                  className="btn btn-danger"
                                  style={{ marginLeft: "5px" }}
                                  onClick={()=> handleDelete(row.bill_id)}
                                >
                                  Delete
                                </button>
                              </div>
                            </td>
                            <td>
                            <a href={`/print-report/${row.bill_id}`} target="blank"> <button className="btn btn-success" >
                                Print
                              </button></a> 
                            <a href={`/print-report-only/${row.bill_id}`} target="blank"> <button className="btn btn-success" >
                                Print Bill only
                              </button></a> 
                            </td>
                          </tr>
                        ))
                        ) : (
                          <tr>
                            <td colSpan="20" className="text-center">No results found</td>
                          </tr>
                        )}
                    </tbody>
                  </Table> 
                </div>
                <div className="container mt-3 mb-3">
                  <div className="row">
                    <div className="col-lg-8 col-md-8 col-sm-12 col-12">  <h4
                          style={{
                            color: "black",
                            marginLeft: "5px",
                            marginRight: "5px",
                            fontSize: "1.1rem"
                          }}
                          
                        >
                         {/* Showing Page {currentPage} of {totalPages} from {data?.length} entries */}
                       {searchTerm ?<> Showing Page {currentPage} of {totalPages} from {filteredData?.length} entries (filtered from {data?.length} total entries) </> : <>Showing Page {currentPage} of {totalPages} from {data?.length} entries</> }  

                        </h4></div>
                    <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                      <div className="d-flex justify-content-evenly">
                        <Button
                          onClick={() => paginate(currentPage - 1)}
                          disabled={currentPage === 1}
                          variant="warning"
                        >
                          Previous
                        </Button>
                        {renderPageNumbers}
                      
                        <Button
                          onClick={() => paginate(currentPage + 1)}
                          disabled={indexOfLastRow >= data.length}
                          variant="success"
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                </>) : <IpdLeads/>}
             </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default SeeLeads;
const Container = styled.div`
  max-width: auto;


  .bgcolor {
    background-color: #80bef5;
  }

  .modalbtn {
    display: flex !important;
    justify-content: flex-end;
    padding-bottom: 1rem;
    padding-right: 1rem;
    button {
      width: auto;
      margin-left: 0.5rem;
    }
  }

  button {
    padding: 0.3rem;
  }

  .thbody {
    background-color: #487eb0;
  }
  th{
    text-align: start;
    white-space: nowrap;
    

  
    @media screen and (max-width:1500px) {
     font-size: small;
   
 }
    
  }
   td {
   
    text-align: start !important;
    
    font-weight: 500 !important;
    text-transform: uppercase !important;
    white-space: nowrap;
    
    

    @media screen and (max-width:1500px) {
     font-size: small;
   
 }
    
  }

`;

// import React, { useState } from "react";
// import styled from "styled-components";
// import { Link, useNavigate } from "react-router-dom";
// import { IoEyeOutline } from "react-icons/io5";
// import { FaRegEyeSlash } from "react-icons/fa";
// import Header from "../Layout/Header";
// import axios from "axios";
// import cogoToast from "cogo-toast";
// import Sidebar from "../AdminDashboard/Sidebar";
// import NavDash from "../AdminDashboard/NavDash";

// const EditProfile = () => {
//     const [toggle, setToggle] = useState(true);
//     const Toggle = () => {
//       setToggle(!toggle);
//     };
//   const [showPassword, setShowPassword] = useState(false);
//   const navigate = useNavigate();
//   const [data, setData] = useState({
//     hospital_name: "",
//     type: "",
//     address: "",
//     contact: "",
//     email: "",
//     password: "",
//   });

//   const handleInputChange = (event) => {
//     const { name, value } = event.target;

//     setData({
//       ...data,
//       [name]: value,
//     });
//   };

//   console.log(data);

//   const register = async (e) => {
//     e.preventDefault();

//     try {
//       const response = await axios.post(
//         "https://test-cghs.doaguru.com/api/v1/auth/e-register",
//         data
//       );
//       console.log(response.data.message);
//       cogoToast.success(response.data.message);
//       navigate("/");
//     } catch (error) {
//       cogoToast.error(error.response?.data || "An Error occured");
//     }
//   };

//   const togglePasswordVisibility = () => {
//     setShowPassword((prevShowPassword) => !prevShowPassword);
//   };

//   return (
//     <>
//       <Header />
//       <Container>
//       <div className="container-fluid bgcolor min-vh-100">
//           <div className="row">
//             {toggle && (
//               <div className="col-2 clrblack vh-100 position-fixed">
//                 <Sidebar />
//               </div>
//             )}
//             {toggle && <div className="col-2"></div>}
//             <div className="col mrg">
//               <NavDash Toggle={Toggle} />
//         <div className="">
//           <div className="row">
//             <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12"></div>
//             <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
//               <div className="boxContainer">
//                 <div className="formcontent">

//                   <p>Enter your details below to continue</p>
//                   <form onSubmit={register}>
//                     <div className="mb-3">
//                       <input
//                         type="text"
//                         placeholder="Hospital Name"
//                         name="hospital_name"
//                         value={data.hospital_name}
//                         onChange={handleInputChange}
//                         className="form-control"
//                         required
//                       />
//                     </div>
//                     <div className="mb-3">
//                       <select
//                         className="form-control"
//                         id="hospitalType"
//                         name="type"
//                         value={data.type}
//                         onChange={handleInputChange}
//                         required
//                       >
//                         <option>--select Type--</option>
//                         <option value="NABH">NABH</option>
//                         <option value="Non NABH">Non NABH</option>
//                       </select>
//                     </div>
//                     <div className="mb-3">
//                       <input
//                         type="text"
//                         placeholder="Enter Email ID"
//                         name="email"
//                         className="form-control"
//                         value={data.email}
//                         onChange={handleInputChange}
//                         required
//                       />
//                     </div>
//                     <div className="mb-3">
//                       <input
//                         type="text"
//                         placeholder="Enter Contact Number"
//                         name="contact"
//                         maxLength={10}
//                         className="form-control"
//                         value={data.contact}
//                         onChange={handleInputChange}
//                         required
//                       />
//                     </div>
//                     <div className="mb-3">
//                       <textarea
//                         type="text"
//                         placeholder="Enter Address"
//                         name="address"
//                         className="form-control"
//                         maxLength={30}
//                         value={data.address}
//                         onChange={handleInputChange}
//                         required
//                       />
//                     </div>

//                     <div className="d-flex justify-content-center">
//                       <button type="submit" className="btn btn-success">
//                         Submit
//                       </button>
//                     </div>
//                   </form>

//                 </div>
//               </div>
//             </div>
//             <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12"></div>
//           </div>
//         </div>
//         </div>
//         </div>
//         </div>

//       </Container>
//     </>
//   );
// };

// export default EditProfile;
// const Container = styled.div`
//   background: linear-gradient(to right, #ffefba, #ffffff);

//   .boxContainer {

//     display: flex;
//     justify-content: center;
//     align-items: center;

//     .formcontent {

//       padding: 1rem 2rem;
//       border-radius: 1rem;
//       height: auto;

//       h1 {
//         text-align: center;
//         font-family: monospace;
//         margin: 0rem 0;
//         color: #08494c;
//       }
//       .form-label {
//         margin-bottom: 0rem !important;
//       }
//       p {
//         margin-bottom: 1rem;
//       }
//       .eyebtn {
//         border: none;
//         background: transparent;
//         position: absolute;
//         margin-left: 14rem;
//       }
//     }
//   }

//   overflow-x: hidden;
//   .sidebar {
//     width: 220px;
//   }
//   .mrg {
//     padding: 0rem !important;
//   }

//   .bgcolor {
//     background-color: #80bef5;
//   }

//   .clrblack {
//     background-color: black !important;
//   }
//   .row{
//   @media screen and (max-width: 768px) {

//   margin-bottom: 10px;
// }

// }
// .hdd{

//     margin-left: 2rem;
//     @media screen and (max-width: 768px) {

//     margin-left: 2rem;
//     }
// }
// `;

import React, { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import cogoToast from "cogo-toast";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Sidebar from "../AdminDashboard/Sidebar";
import NavDash from "../AdminDashboard/NavDash";
import { setUser } from "../redux/slices/UserSlices";

const EditProfile = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [toggle, setToggle] = useState(true);
  const Toggle = () => {
    setToggle(!toggle);
  };
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState({
    hospital_id: user.id,
    hospital_name: user.name,
    type: user.type,
    address: user.address,
    contact: user.contact,
    email: user.email,
    bank_name: user.bank_name || '',
    account_no: user.account_no || '',
    ifsc_code: user.ifsc_code || '',
    br_micr_code: user.br_micr_code || '',
    password: user.password || ''
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setData({
      ...data,
      [name]: value,
    });
  };

  console.log(data);

  const register = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(
        `https://test-cghs.doaguru.com/api/v1/auth/update-hospital`,
        data
      );
      console.log(response);
      if (response?.data?.success) {
        cogoToast.success(response?.data?.message);
        // window.location.reload();
        const userData = {
          name: response?.data?.data?.hospital_name,
          id: response?.data?.data?.hospital_id,
          type: response?.data?.data?.type,
          address: response?.data?.data?.address,
          contact: response?.data?.data?.contact,
          email: response?.data?.data?.email,
          bank_name: response?.data?.data?.bank_name,
          account_no: response?.data?.data?.account_no,
          ifsc_code: response?.data?.data?.ifsc_code,
          br_micr_code: response?.data?.data?.br_micr_code,
        };

        dispatch(setUser(userData));
        navigate("/hospital-profile");
      }
    } catch (error) {
      cogoToast.error(error?.response?.data || "An Error occured");
    }
  };

  //   const getData = async () => {

  //     try {
  //       const response = await axios.get(
  //         `https://test-cghs.doaguru.com/api/v1/auth/get-hospital-details/${user.id}`

  //       );
  //       console.log(response);
  //     //   cogoToast.success(response);

  //     } catch (error) {
  //     //   cogoToast.error(error.response?.data || "An Error occured");
  //     }
  //   };

  //   useEffect(()=>{
  //       getData();
  //   },[])

  console.log(data);
  return (
    <Container>
      <div className="container-fluid bgcolor min-vh-100">
        <div className="row">
          {toggle && (
            <div className="col-2 bg-dark vh-100 position-fixed">
              <Sidebar />
            </div>
          )}
          {toggle && <div className="col-2"></div>}
          <div className="col mrg">
            <NavDash Toggle={Toggle} />
            <div className="">
              <div className="row">
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12"></div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <div className="boxContainer">
                    <div className="formcontent">
                      <p>Enter your details below to continue</p>
                      <form onSubmit={register}>
                        {/* <div className="mb-3">
                          <label className="form-check-label">
                            Hospital Name:
                          </label>
                          <input
                            type="text"
                            placeholder="Hospital Name"
                            name="hospital_name"
                            value={data.hospital_name}
                            onChange={handleInputChange}
                            className="form-control"
                            required
                          />
                        </div> */}
                        <div className="mb-3">
                          <label className="form-check-label">
                            Hospital Type:
                          </label>
                          <select
                            className="form-select"
                            id="hospitalType"
                            name="type"
                            value={data.type}
                            onChange={handleInputChange}
                            required
                          >
                            <option>--select Type--</option>
                            <option value="NABH">NABH</option>
                            <option value="Non NABH">Non NABH</option>
                          </select>
                        </div>
                        {/* <div className="mb-3">
                          <label className="form-check-label">Email:</label>
                          <input
                            type="text"
                            placeholder="Enter Email ID"
                            name="email"
                            className="form-control"
                            value={data.email}
                            onChange={handleInputChange}
                            required
                          />
                        </div> */}
                        <div className="mb-3">
                          <label className="form-check-label">Contact:</label>
                          <input
                            type="text"
                            placeholder="Enter Contact Number"
                            name="contact"
                            maxLength={10}
                            className="form-control"
                            value={data.contact}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-check-label">Address:</label>
                          <textarea
                            type="text"
                            placeholder="Enter Address"
                            name="address"
                            className="form-control"
                            maxLength={30}
                            value={data.address}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-check-label">Bank Name:</label>
                          <input
                            type="text"
                            placeholder="Bank Name"
                            name="bank_name"
                            className="form-control"
                            value={data.bank_name}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-check-label">
                            Account No:
                          </label>
                          <input
                            type="text"
                            placeholder="Account Number"
                            name="account_no"
                            className="form-control"
                            value={data.account_no}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-check-label">IFSC Code:</label>
                          <input
                            type="text"
                            placeholder="IFSC Code"
                            name="ifsc_code"
                            className="form-control"
                            value={data.ifsc_code}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-check-label">
                            BR MICR Code:
                          </label>
                          <input
                            type="text"
                            placeholder="BR MICR Code"
                            name="br_micr_code"
                            className="form-control"
                            value={data.br_micr_code}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                        <div className="d-flex justify-content-center">
                          <button type="submit" className="btn btn-success">
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default EditProfile;

const Container = styled.div`
  .boxContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    .formcontent {
      padding: 1rem 2rem;
      border-radius: 1rem;
      height: auto;

      h1 {
        text-align: center;
        font-family: monospace;
        margin: 0rem 0;
        color: #08494c;
      }
      .form-label {
        margin-bottom: 0rem !important;
      }
      p {
        margin-bottom: 1rem;
      }
      .eyebtn {
        border: none;
        background: transparent;
        position: absolute;
        margin-left: 14rem;
      }
    }
  }

  .bgcolor {
    background-color: #80bef5;
  }

  .divsettle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.8rem;
  }

  li {
    list-style: none;
  }
  th {
    text-align: start;
    white-space: nowrap;
  }
  td {
    text-align: start !important;
    white-space: nowrap !important;
    font-weight: 500 !important;
    text-transform: uppercase;
  }
`;

import React, { useEffect, useRef, useState } from 'react';
import { styled } from "styled-components";
import { useNavigate,useParams } from "react-router-dom";
import axios from "axios";
import cogoToast from "cogo-toast";
import { useSelector } from "react-redux";
import moment from "moment";
import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';
import {Viewer, Worker} from '@react-pdf-viewer/core';
import {defaultLayoutPlugin} from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';


const PrintWithoutLetterHead = () => {
  const {plugin, ...newPlugin} = defaultLayoutPlugin();
   
  const pdfRef = useRef();
    const { billId } = useParams();
    const user = useSelector((state) => state.user);
    const [data,setData] = useState("");
    const [procData,setProcData] = useState("")
    const [procedure, setProcedure] = useState([]);
    const navigate = useNavigate();
    // const [hospitalDoc, setHospitalDoc] = useState([]);

    const generatePDF = async () => {
      const pdfContainer = pdfRef.current;
    
      if (!pdfContainer) {
        console.error('PDF container not found');
        return;
      }
    
      try {
        const imageDataUrl = await domtoimage.toPng(pdfContainer);
        const pdf = new jsPDF({
          orientation: 'portrait',
          unit: 'mm',
          format: 'a4',
          
        });

  
    
        
        pdf.addImage(imageDataUrl, 'PNG', 0, 0, 210, 297); // Adjust the size as needed
       
        // ----- to save as pdf ----- //
      //   pdf.save('patient_bill.pdf');
      // } catch (error) {
      //   console.error('Error generating PDF:', error);
      // }
      // to save pdf // 
        
      //----- to open in new tabe use this code ------// 
       // Create a Blob from the PDF content
       //

       const pdfBlob = pdf.output('blob');

       // Create a URL for the Blob
       const pdfUrl = URL.createObjectURL(pdfBlob);
 
       // Open the URL in a same tab
       window.location.href = pdfUrl;

      //

        // Open the URL in a new tab
       window.open(pdfUrl, '_blank');
       // to open new tab
     } catch (error) {
       console.error('Error generating PDF:', error);
     }
    };
    

    

    

   







 
    
    
    let totalAmount = 0;

    const calculateTotalAmount = (procedure) =>{
      totalAmount = totalAmount + (Number(procedure?.price) * Number(procedure?.quantity));
}

    // const displayDocHospital = async () => {
    //   console.log(user.id);
    //   try {
    //     const viewDoc = await axios.get(
    //       `https://test-cghs.doaguru.com/api/v1/auth/displayHospitalDoc/${user.id}`
    //     );
  
    //     console.log(viewDoc.data);
    //     setHospitalDoc(viewDoc.data);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // };
  

   
    const getBill = async () => {
        try {
          const response = await axios.get(
            `https://test-cghs.doaguru.com/api/v1/auth/getBill/${billId}`
          );
          console.log(response?.data);
          setData(response?.data);
        } catch (error) {
          console.log(error);
          cogoToast.error("error in getting bills");
        }
      };

      const getProcedures = async () => {
        const response = await axios.get(
          "https://test-cghs.doaguru.com/api/v1/auth/getAllProcedures"
        );
        console.log(response);
        setProcedure(response?.data?.result);
      };

      const getProcedure = async () =>{
        const response = await axios.get(
            `https://test-cghs.doaguru.com/api/v1/auth/getPatientProcedures/${billId}`
          );
          console.log(response);
          setProcData(response?.data?.result);
      }

      useEffect(() => {
        // displayDocHospital();
        getBill();
        getProcedure();
        getProcedures();
        
    
      }, []);

      // Add a state to track whether the data has been loaded
  const [dataLoaded, setDataLoaded] = useState(false);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       // Fetch data based on the billId parameter
  //       const [billResponse, procedureResponse, proceduresResponse, docResponse] = await Promise.all([
  //         axios.get(`https://test-cghs.doaguru.com/api/v1/auth/getBill/${billId}`),
  //         axios.get(`https://test-cghs.doaguru.com/api/v1/auth/getPatientProcedures/${billId}`),
  //         axios.get("https://test-cghs.doaguru.com/api/v1/auth/getAllProcedures"),
  //         axios.get(`https://test-cghs.doaguru.com/api/v1/auth/displayHospitalDoc/${user.id}`)
  //       ]);

  //       setData(billResponse?.data);
  //       setProcData(procedureResponse?.data?.result);
  //       setProcedure(proceduresResponse?.data?.result);
  //       setHospitalDoc(docResponse?.data);
        
  //       // Set dataLoaded to true to indicate that data has been loaded
  //       setDataLoaded(true);
  //     } catch (error) {
  //       console.log(error);
  //       cogoToast.error("Error in fetching data");
  //     }
  //   };

  //   fetchData();
  // }, [billId, user.id]);

      // useEffect(() => {
      //   if (dataLoaded) {
      //     generatePDF();
      //   }
      // }, [dataLoaded]);

      // useEffect(() => {
      //   const fetchData = async () => {
      //     try {
      //       await displayDocHospital();
      //       await getBill();
      //       await getProcedure();
      //       await getProcedures();
            
      //       // Print the page after all data has been loaded
      //       window.print();
      //     } catch (error) {
      //       console.error(error);
      //     }
      //   };
    
      //   fetchData();
      // }, []);

   

        const calculateAmount = (procedureName,procedureQuantity)=>{
            let amount = 0;
            const Procedure =   procedure?.find(
                (procedureItem) => procedureItem?.procedure_name?.trim() === procedureName?.trim()
              );
              if (Procedure) {
                  //  amount += data?.hospital_type === "NABH"
                  amount += user.type === "NABH"
                  ? ((Number(Procedure?.nabh_amount )) * Number(procedureQuantity))
                  : ((Number(Procedure?.non_nabh_amount )) * Number(procedureQuantity));
                  
              }
              
              totalAmount += amount
              return (amount)
          };


    

        const findCode = (procedureName) =>{
          
            const Procedure =   procedure.find(
                (procedureItem) => procedureItem?.procedure_name?.trim() === procedureName?.trim()
              );
              
              return Procedure?.code

             
        }

        const handlePrint = () =>{
          window.print();
          
        }
     
console.log(procData)

  return (
    <Container >
      <div ref={pdfRef}>
      <div className="headimage">
                    
      </div>
    <div className='container-fluid m-0 p-0'>
        <div className='row'>
            <div className='col-12 '>
                  <h5 className='text-center heading'>PATIENT BILL</h5>
            </div>

        </div>

       
     <div className="row">
          <div className="col-12">
            <table className="table table-borderless">
              <tbody>
                <tr>
                  <th className='text-start'>Reg No</th>
                  <td className='text-capitalize'>{": "}{data?.reg_number}</td>

                  {data?.opd_number ? (
  <>
    <th scope="col" className='text-start'>OPD No</th>
    <td  className='text-capitalize'>{": "}{data?.opd_number}</td>
    
  </>
):  <>
<th scope="col" className='text-start'>IPD No</th>
<td  className='text-capitalize'>{": "}{data?.ipd_number}</td>

</>}
                <th scope="col" className='text-start pe-5'>Bill No</th>
    <td  className='text-capitalize'>{": "}{data?.bill_number}</td>
                </tr>
                <tr>
                  <th scope="col" className='text-start'>Name</th>
                  <td  className='text-capitalize'>{": "}{data?.patient_name}</td>
                  <th scope="col" className='text-start'>Age</th>
                  <td  className='text-capitalize'>{": "}{data?.age}</td>
                  <th scope="col" className='text-start'>Gender</th>
                  <td  className='text-capitalize'>{": "}{data?.gender}</td>
                </tr>
                <tr>
                <th scope="col" className='text-start'>Refer ID</th>
                  <td  className='text-capitalize'>{": "}{data?.refferal_id}</td>
                  
                  
                  <th scope="col" className='text-start'>D.O.A</th>
                  <td  className='text-capitalize'>{": "}{data?.date_of_treatment ? moment(data?.date_of_treatment).format('DD/MM/YYYY') : ""}</td>
                  <th scope="col" className='text-start'>Beneficiary Id</th>
                  <td  className='text-capitalize'>{": "}{data?.beneficiary_Id}</td>
                </tr>
                <tr>
                  <th scope="col" className='text-start'>Doctor Name</th>
                  <td  className='text-capitalize'>{": "}{data?.doctor_name}</td>
                  <th scope="col" className='text-start'>Bill Date</th>
                  <td  className='text-capitalize'>{": "}{data?.bill_date ? moment(data?.bill_date).format('DD/MM/YYYY') : ""}</td>
                  
                </tr>
                <tr>
                <th scope="col" className='text-start'>Address</th>
                  <td  className='text-capitalize' style={{whiteSpace:"normal"}}>{": "}{data?.address}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

       

<div className="row proc-detail" >
          <div className="col-12">
          <table className="table table-borderless">
  <thead>
    <tr>
      <th  className="text-start ps-4 pb-2 pt-1 " >
        Code
      </th>
      <th scope="col" colSpan="1" className="text-start pb-2 pt-1 code-column" >
        Procedure Name
      </th>
      <th scope="col" className="text-end pe-4 pb-2 pt-1">
        Amount
      </th>
    </tr>
  </thead>
  <tbody>
    {procData &&
      procData?.map((procedure, index) => (
        <tr key={index}>
          <td className="text-start ps-4 " >
            {findCode(procedure?.procedure_name)}
          </td>
          <td colSpan="1" style={{ wordWrap: 'break-word',whiteSpace:"normal"}} className='text-start code-column'>
            {procedure?.procedure_name} {`* ${procedure?.quantity}`}
          </td>


        {
            procedure?.price ? (
              <td className="text-end pe-4">{Number(procedure?.price)*Number(procedure?.quantity)}{calculateTotalAmount(procedure)}
              {".00"}
            </td>
            )
            :
            <td className="text-end pe-4">{calculateAmount(procedure?.procedure_name, procedure?.quantity)}
            {".00"}
          </td>
          }
        </tr>
        
      ))}
                  <tr >
                    <th scope="col" className='' style={{ width: '30% !important' }}>

                    </th>

                    <th scope="col" className='text-start pt-4 second-th' >

                      Payable Amount {" "} INR


                    </th>

                    <th scope="col" className='text-end pe-4 pt-4'> {totalAmount}{".00"}</th>



                  </tr>
                <tr>
                  <th scope="col" className='' style={{ width: '30%' }}></th>
                  <th scope="col" className='text-start second-th'> Amount Paid {" "} INR</th>
                  
                  <th scope="col" className='text-end pe-4'>{"0.00"}</th>
                  
                  
                  
                </tr>


                <tr>
                  <th scope="col" className='' style={{ width: '30%' }}></th>
                  <th scope="col" className='text-start second-th'>Net Payable {" "}INR</th>
                  
                  <th scope="col" className='text-end pe-4'>{totalAmount}{".00"}</th>
                   </tr>

                  
  </tbody>
</table>

          </div>
        </div>

        {/* <div className="row">


        <div className="col-6">
           
          </div>
            
          <div className="col-6">
            <table className="table table-borderless">
            
              <tbody>
              <tr>
                  <th scope="col" className='text-start' style={{ width: '30%' }}>Payable Amount</th>
                  <th scope="col" className='text-start'>INR</th>
                  
                  <th scope="col" className='text-start'>{totalAmount}{".00"}</th>
                  
                  
                  
                </tr>
              <tr>
                  <th scope="col" className='text-start' style={{ width: '30%' }}>Amount Paid</th>
                  <th scope="col" className='text-start'>INR</th>
                  
                  <th scope="col" className='text-start'>{"0.00"}</th>
                  
                  
                  
                </tr>

                <tr>
                  <th scope="col" className='text-start' style={{ width: '30%' }}>Net Payable</th>
                  <th scope="col" className='text-start'>INR</th>
                  
                  <th scope="col" className='text-start'>{totalAmount}{".00"}</th>
                  
                  
                  
                </tr>
                
              </tbody>
            </table>
          </div>
         
            
        </div> */}

    


        
    </div>
    <div className='row'>
         <div className='col-6 d-flex align-items-end '>
             <div>
              <h6 className='ms-5 preparedBy'>Prepared by <span className='text-uppercase'>{data?.bill_prepared_by}</span></h6>
             </div>
         </div>

        <div className='col-6 d-flex align-items-end gap-4'>
          <div className="sealimg">
           
          </div>

          <div className="signimg">
           
          </div>

        </div>
    </div>

    <div className="footimage">
                    
                  </div>
                  </div>  
                  <div className='d-flex justify-content-center my-3 gap-2'>
                  <button type="button" className="btn btn-primary btn-lg" onClick={handlePrint}>Print</button>
                  <button type="button" className="btn btn-primary btn-lg" onClick={()=>navigate('/admin-dashboard')}>Go to Dashboard</button>
                  {/* <button
    type='button'
    className='btn btn-secondary btn-lg ms-3'
    onClick={generatePDF}
  >
    Generate PDF
  </button> */}
                  </div>
                  
                          
    </Container>
  )
}

export default PrintWithoutLetterHead;

const Container = styled.div`

font-family: 'Times New Roman', Times, serif;
overflow-x: hidden;

.code-column{
  
}


  .headimage {
    
    height: 18rem;
    width: auto;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .footimage {
    @media print{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    }
    
    height: 60px;
    width: auto;
    img {
      height: 100%;
      width: 100%;
    }
  }
   
     .heading{
        
        border-bottom: 2px solid black;
        font-weight: 700;
        font-size: large;
        
     }
     .details-1{
        border-bottom: 2px solid black;
        
     }
     table {
    width: 100%;
    border-collapse: collapse;
    padding: 0;
    /* margin-bottom: 10px;  */
  }

  th {
    /* padding: 8px; */
    text-align: center;
    white-space: nowrap; /* Prevent text from wrapping */
    font-weight: 600;
    font-size: medium;
    color: black;
    /* letter-spacing: 1.5px; */
    padding-top: 0;
    padding-bottom: 0;
    
  }
   td {
    /* padding: 8px; */
    text-align: start;
    white-space: nowrap;
    font-weight: 600;
    font-size: medium;
    color: black;
    /* letter-spacing: 0.5px; */
    padding-top: 0;
    padding-bottom: 0;
  }
  .proc-detail{
    border-top: 2px solid black;
    
  }
  .btn-primary{
    
    @media print{

      display: none;
    }
  }
.preparedBy{
  /* font-weight: 900; */
  font-weight: bolder;
  font-size: medium;
}
.sealimg:not(img) {
  border: 0 !important;
}
.signimg:not(img) {
  border: 0 !important;
}
.second-th {
    padding-left: 30%;
    
    
}
` 
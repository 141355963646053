import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: { name: "", id: null, type:"" ,address:"",contact:"",email: "", bank_name: "", account_no: "", ifsc_code: "", br_micr_code: ""},
  reducers: {
    setUser: (state, action) => {
      state.name = action.payload.name;
      state.id = action.payload.id;
      state.type = action.payload.type;
      state.address = action.payload.address;
      state.email = action.payload.email;
    state.contact = action.payload.contact; 
    state.bank_name = action.payload.bank_name;
    state.account_no = action.payload.account_no;
    state.ifsc_code = action.payload.ifsc_code;
    state.br_micr_code = action.payload.br_micr_code;
    },
    clearUser: (state) => {
      state.name = "";
      state.id = null;
      state.type = "";
      state.address = "";
      state.contact = "";
      state.email = "";
      state.bank_name = "";
      state.ifsc_code = "";
      state.br_micr_code = "";
      state.state.account_no = "";
    },
  },
});

export const { setUser, clearUser } = userSlice.actions;
export default userSlice.reducer;

import React, { useState } from "react";
import styled from "styled-components";
import { Link, useNavigate,useParams } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import cogoToast from "cogo-toast";
import { useDispatch } from "react-redux";
import { setUser } from "../redux/slices/UserSlices";
import Header from "../Layout/Header";

const ResetPassword = () => {
    const [password,setPassword] = useState("");
    const {id,token} = useParams();
    const navigate = useNavigate();
   

    const handleChange =  (e) =>{
        setPassword(e.target.value)
   } 

  

  const handleSubmit = async(e) =>{
    e.preventDefault();
    if(!password){
        return cogoToast.error(`Please enter password`);
    }
    try{
        
       const res = await axios.post(`https://test-cghs.doaguru.com/api/v1/auth/reset-password/${id}/${token}`, { password: password });
       if (res?.data && res?.data?.success === true) {
        
        console.log(res?.data);
        cogoToast.success(`${res?.data?.message}`);
        navigate("/admin-dashboard");
       
        return;
      }
      if (res.data && res.data.success === false) {
       
        cogoToast.error(`${res?.data?.message}`);
        return;
      }
    }
    catch(err){
        if (err?.response && err.response?.data) {
          
            console.log(err);
            cogoToast.error(`${err.response?.data?.message}`);
          }  
    }

 }

//   const handleSubmit = async(e) =>{
//     e.preventDefault();
//     if(!email){
//       return cogoToast.error(`Please enter email`);
//   }
//     try{
//        const res =await axios.post("https://test-cghs.doaguru.com/api/v1/auth/forgot-password",{ email});
//        if (res?.data && res?.data?.success === true) {
        
//         console.log(res?.data);
//         cogoToast.success(`${res?.data?.message}`);
//         navigate("/admin-dashboard");
  
       
//         return;
//       }
//       if (res.data && res.data.success === false) {
       
//         cogoToast.error(`${res?.data?.message}`);
//         return;
//       }
//     }
//     catch(err){
//       if (err?.response && err.response?.data) {
          
//         console.log(err);
//         cogoToast.error(`${err.response?.data?.message}`);
//       }  
  
//     }
  
//   }

  return (
    <>
    <Header />
      <Container>
        <div>
          <div className="boxContainer">
            <div className="formcontent">
              <h2 className="text-start">Reset Password</h2>
              <small>Enter your details below to continue</small>
              <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  New Password
                </label>
                <input
                  type="password"
                  placeholder="Enter your password"
                  name="password"
                  className="form-control"
                  value={password}
                  onChange={handleChange}
                 
                />
              </div>
             
                <div className="d-flex justify-content-center">
                  <button className="btn btn-success" type="submit">
                    Submit
                  </button>
                </div>
              </form>
              
             
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default ResetPassword;
const Container = styled.div`
background: linear-gradient(to right, #ffefba, #ffffff);
  .boxContainer {
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;

    .formcontent {
      background-color: #0dcaf0;
      padding: 1rem;
      border-radius: 1rem;
      height: auto;
      box-shadow: 1px 2px 34px #38c7e4;
      h2 {
        text-align: center;
        font-family: monospace;
        margin: 1rem 0;
        color: #08494c !important;
      }
      span {
        a {
          text-decoration: none;
          color: #fff;
        }
      }
    }
  }
`;
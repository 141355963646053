import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { Table, Input, Button, Form } from "react-bootstrap";
import axios from 'axios';
import cogoToast from 'cogo-toast';
import { useSelector } from "react-redux";

const HospitalStatistics = ({billData,procedure,patientProcedure}) => {
  const [ipdAllData, setipdAllData] = useState({todayIpdRegistration: 0,
    totalIpdBillAmount: 0,
    todayIpdAmount: 0,
    totalIpdPatient: 0,});

 
  const user = useSelector((state) => state.user);
  let totalAmount = 0;
  let totalIpdAmount = 0;
  let totalOpdAmount = 0;

  let todayBillAmount = 0;
  let todayIpdAmount = 0;
  let todayOpdAmount = 0;
  
  let totalIpdBill = 0;
  let totalOpdBill = 0;

  let todayIpdBill = 0;
  let todayOpdBill = 0;


   // Filter today registered patients
   const todayRegistered = billData.filter((item) => {
    // Assuming item.bill_date is a valid date string or Date object
    const billDate = new Date(item?.bill_creation_time);
    const today = new Date();
    return (
      billDate.getDate() === today.getDate() &&
      billDate.getMonth() === today.getMonth() &&
      billDate.getFullYear() === today.getFullYear()
    );
  });


   if(billData){
    billData.map((item) => {
      // Assuming item.bill_date is a valid date string or Date object
        
      if(item.dept_type === "IPD"){
        (totalIpdBill+=1);
        
    }
        
      if(item.dept_type === "OPD"){
        (totalOpdBill+=1);
        
    }
  });
   } 
   if(todayRegistered){
    todayRegistered.map((item) => {
      // Assuming item.bill_date is a valid date string or Date object
        
      if(item.dept_type === "IPD"){
        (todayIpdBill+=1);
        
    }
        
      if(item.dept_type === "OPD"){
        (todayOpdBill+=1);
        
    }
  });
   } 

   if(billData){
    billData?.map((bill)=>{
    
      
    // add this for new version
      const row = patientProcedure.filter((row) => row.patient_id === bill.bill_id);
      if(row[0]?.price){
         row.map(
          (row) => {
            totalAmount += (Number(row.price) * Number(row.quantity)) 
            if(bill.dept_type === "IPD"){
          
              // totalIpdAmount += bill.hospital_type === "NABH"
              totalIpdAmount += (Number(row.price) * Number(row.quantity))
              
            }
            if(bill.dept_type === "OPD"){
              
              totalOpdAmount += (Number(row.price) * Number(row.quantity))
              
            }
          
          }
         )
      }

    else{

    
      patientProcedure
      ?.filter((item) => item.patient_id === bill.bill_id)
      ?.map((filteredItem, index) => {
        const matchedProcedure = procedure.find(
          (procedureItem) => procedureItem.procedure_name.trim() === filteredItem.procedure_name.trim()
        );
  
        // Initialize totalAmount
        
       
        
        // Display amount if procedure is found
        if (matchedProcedure) {
           
          const nabhAmount = Number(matchedProcedure?.nabh_amount);
            const nonNabhAmount = Number(matchedProcedure?.non_nabh_amount);
            const quantity = Number(filteredItem?.quantity);
          
            if (!isNaN(nabhAmount) && !isNaN(nonNabhAmount) && !isNaN(quantity)) {
                
              if(bill.dept_type === "IPD"){
            
                // totalIpdAmount += bill.hospital_type === "NABH"
                totalIpdAmount += user.type === "NABH"
                ? (nabhAmount * quantity)
                : (nonNabhAmount * quantity);
                
              }
              if(bill.dept_type === "OPD"){
                
                totalOpdAmount += user.type === "NABH"
                ? (nabhAmount * quantity)
                : (nonNabhAmount * quantity);
                
              }
              
                totalAmount += user.type === "NABH"
                ? (nabhAmount * quantity)
                : (nonNabhAmount * quantity);

            } else {
              console.error("Invalid amount or quantity:", nabhAmount, nonNabhAmount, quantity);
            }
       
            
        }
        
        
        
    })
  }
  })

  }

  
  if(todayRegistered){
    todayRegistered?.map((bill)=>{
      
       // add this for new version
      const row = patientProcedure.filter((row) => row.patient_id === bill.bill_id);
      if(row[0]?.price){
         row.map(
          (row) => {
            todayBillAmount += (Number(row.price) * Number(row.quantity)) 
            if(bill.dept_type === "IPD"){
          
              // totalIpdAmount += bill.hospital_type === "NABH"
              todayIpdAmount += (Number(row.price) * Number(row.quantity))
              
            }
            if(bill.dept_type === "OPD"){
              
              todayOpdAmount += (Number(row.price) * Number(row.quantity))
              
            }
          
          }
         )
      }
     else{

     
      
      patientProcedure
      ?.filter((item) => item.patient_id === bill.bill_id)
      ?.map((filteredItem, index) => {
        const matchedProcedure = procedure?.find(
          (procedureItem) => procedureItem.procedure_name.trim() === filteredItem.procedure_name.trim()
        );
  
        // Initialize totalAmount
        
        
        
        // Display amount if procedure is found
        if (matchedProcedure) {
          const nabhAmount = Number(matchedProcedure?.nabh_amount);
          const nonNabhAmount = Number(matchedProcedure?.non_nabh_amount);
          const quantity = Number(filteredItem?.quantity);
        
          if (!isNaN(nabhAmount) && !isNaN(nonNabhAmount) && !isNaN(quantity)) {
              
            if(bill.dept_type === "IPD"){
          
              // totalIpdAmount += bill.hospital_type === "NABH"
              todayIpdAmount += user.type === "NABH"
              ? (nabhAmount * quantity)
              : (nonNabhAmount * quantity);
              
            }
            if(bill.dept_type === "OPD"){
              
              todayOpdAmount += user.type === "NABH"
              ? (nabhAmount * quantity)
              : (nonNabhAmount * quantity);
              
            }
            
              todayBillAmount += user.type === "NABH"
              ? (nabhAmount * quantity)
              : (nonNabhAmount * quantity);

          } else {
            console.error("Invalid amount or quantity:", nabhAmount, nonNabhAmount, quantity);
          }
        }
        
        
        
    })
  }
    
  })

  }
  
  
  
  const Fetchipd = async () => {
    let ipdPatientBill = [];
    let ipdProcedures = [];
    let ipdPatientProcedure = [];
    let totalIpdPatient = 0;
    let todayIpdRegistration = 0;
    let totalIpdBillAmount = 0;
    let todayIpdAmount = 0;
    try {
      const [
        patientBillRespoonse,
        proceduresRespoonse,
        patientProcedureRespoonse,
      ] = await Promise.all([
        axios.get(`https://test-cghs.doaguru.com/api/v1/auth/getAllIPDPatientBill/${user.id}`),
        axios.get("https://test-cghs.doaguru.com/api/v1/auth/getAllIPDProcedures"),
        axios.get("https://test-cghs.doaguru.com/api/v1/auth/getIPDpatientProcedure"),
      ]);

      ipdPatientBill = patientBillRespoonse.data
      ipdProcedures = proceduresRespoonse.data.result;
      ipdPatientProcedure = patientProcedureRespoonse.data.result;
      totalIpdPatient = ipdPatientBill.length;

      // count today registered patient
      ipdPatientBill.forEach((ele) => {
            let patientDate = new Date(ele.created_at);
            if(patientDate.getDate() === new Date().getDate()) todayIpdRegistration++;
      })

      // count total bill amount
      ipdPatientProcedure.forEach((ele) => {
            let patientAmount = Number(ele.price) * Number(ele.quantity);
            let patientDate = new Date(ele.created_at);

            totalIpdBillAmount += patientAmount;
            if(patientDate.getDate() === new Date().getDate()) {
                todayIpdAmount += patientAmount;
            };

      })


      console.log(todayIpdRegistration ,totalIpdBillAmount , todayIpdAmount, totalIpdPatient);

      setipdAllData({todayIpdRegistration ,totalIpdBillAmount , todayIpdAmount, totalIpdPatient});

    } catch (error) {
      console.log(error);

      return error;
    }
  };


useEffect(() => {
  Fetchipd();
}, []);

  
  return (
    <Container>
    <div className='container-fluid'>
        <div className='row'>
            <div className='col-12 mt-4'>
                <h5 className='heading bg-success h-75 p-2 d-flex justify-content-center align-items-center'>Hospital Statistics</h5>
            </div>

            <div style={{ overflowX: "auto" }}>
                  <Table striped bordered hover>
                    <thead className="thbody">
                      <tr>
                      <th >
                          
                        </th>
                        <th >
                          Overall
                        </th>
                      
                        <th >
                          Today
                        </th>
                        
                      </tr>
                    </thead>
                    <tbody>
                     
                          <tr>
                            <td>Total Patient Registered</td>
                            <td>{billData?.length + ipdAllData?.totalIpdPatient}</td>
                            <td>{todayRegistered?.length + ipdAllData?.todayIpdRegistration}</td>
                          </tr>
                          <tr>
                            <td>OPD</td>
                            <td>{totalOpdBill}</td>
                            <td>{todayOpdBill}</td>
                          </tr>
                          <tr>
                            <td>IPD</td>
                            <td>{ipdAllData?.totalIpdPatient}</td>
                            <td>{ipdAllData?.todayIpdRegistration}</td>
                          </tr>
                          
                    </tbody>
                    <thead className="thbody">
                      <tr>
                      <th >
                          
                        </th>
                        <th >
                          Overall
                        </th>
                      
                        <th >
                          Today
                        </th>
                        
                      </tr>
                    </thead>
                    <tbody>
                     
                          <tr>
                            <td>Total Amount</td>
                            <td>{totalAmount}</td>
                            <td>{todayBillAmount + ipdAllData?.todayIpdAmount}</td>
                          </tr>
                          <tr>
                            <td>OPD</td>
                            <td>{totalOpdAmount}</td>
                            <td>{todayOpdAmount}</td>
                          </tr>
                          <tr>
                            <td>IPD</td>
                            {/* <td>{totalIpdAmount}</td>
                            <td>{todayIpdAmount}</td> */}
                            <td>{ipdAllData?.totalIpdBillAmount}</td>
                            <td>{ipdAllData?.todayIpdAmount}</td>
                          </tr>
                          
                    </tbody>
                   
                  </Table>
                </div>

        </div>

    </div>
    </Container>
  )
}

export default HospitalStatistics;

const Container = styled.div`

.heading{
    
}
th{
    text-align: center;
    white-space: nowrap;
    
    
  }
   td {
   
    text-align: center !important;
    white-space: nowrap !important;
    font-weight: 500 !important;
    
    
  }


` 


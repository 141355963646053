import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import axios from "axios";
import cogoToast from "cogo-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const initialState = {
  reg_number: "",
  ipd_number: "",
  bill_number: "",
  patient_name: "",
  age: "",
  gender: "",
  bill_date: "",
  beneficiary_id: "",
  treatment_date: "",
  address: "",
  doctor_name: "",
  bill_prepared_by: "",
  department: "",
  condition_of_admission: "",
  investigation: "",
  card_token_no: "",
  ward_bed_no: "",
  patientCategory: "",
  hospital_id: "",
  hospital_name: "",
  hospital_type: "",
  dischargeDate: "",
};

const AddPatient = () => {
  const user = useSelector((state) => state.user);
  const [patientData, setPatientData] = useState({
    ...initialState,
    hospital_id: user.id,
    hospital_name: user.name,
  });
  const [prevBill, setPrevBill] = useState();
  const [showProcedure, setShowProcedure] = useState(false);
  const [procData, setProcData] = useState({
    procedures: [{ procedure: "", quantity: "", price: "" }],
  });
  const [procDataList, setProcDataList] = useState([]);
  const [focusedInputIndex, setFocusedInputIndex] = useState(null);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [billId, setBillId] = useState("");
  const navigate = useNavigate();

  const getAllProcList = async () => {
    try {
      const response = await axios.get(
        "https://test-cghs.doaguru.com/api/v1/auth/getAllIPDProcedures"
      );
      console.log(response.data);
      setProcDataList(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleChangeProcedure = (index, key, value) => {
    const newProcedures = [...procData.procedures];
    newProcedures[index][key] = value;

    setProcData((prevData) => ({
      ...prevData,
      procedures: newProcedures,
    }));

    // Set the currently focused input index
    setFocusedInputIndex(index);

    const userInput = value.toLowerCase();
    const filtered = procedureNames.filter((name) =>
      name.toLowerCase().includes(userInput)
    );
    setFilteredSuggestions(filtered);
    setShowSuggestions(filtered.length > 0);
  };

  const handleChangeQuantity = (index, key, value) => {
    const newProcedures = [...procData.procedures];
    newProcedures[index][key] = value;
    setProcData((prevData) => ({
      ...prevData,
      procedures: newProcedures,
    }));
  };

  const handleChangePrice = (index, key, value) => {
    const newProcedures = [...procData.procedures];
    newProcedures[index][key] = value;
    setProcData((prevData) => ({
      ...prevData,
      procedures: newProcedures,
    }));
  };

  const handleAddProcedure = () => {
    setProcData((prevData) => ({
      ...prevData,
      procedures: [
        ...prevData.procedures,
        { procedure: "", quantity: "", price: "" },
      ],
    }));
  };

  const handleRemoveProcedure = (index) => {
    if (procData.procedures && procData.procedures.length > 1) {
      const newProcedures = [...procData.procedures];
      newProcedures.splice(index, 1);
      setProcData((prevData) => ({
        ...prevData,
        procedures: newProcedures,
      }));
    }
  };

  const handleChange = (e) => {
    // console.log(e.target.value );
    const { name, value } = e.target;
    console.log(name, value);
    setPatientData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleDateChange = (type, date) => {
    setPatientData((prevData) => ({
      ...prevData,
      [type]: date,
    }));
  };
  console.log(procDataList.result);
  const procedureNames = procDataList.result?.map(
    (item) => item.procedure_name
  );

  const getAllBill = async () => {
    console.log(user);
    try {
      const response = await axios.get(
        `https://test-cghs.doaguru.com/api/v1/auth/getAllIPDPatientBill/${user.id}`
      );
      console.log(response.data);
      let bill =
      response.data.length == 0 ? [{ bill_number: 0 }] : response.data.length;
      setPatientData((prevData) => ({
        ...prevData,
        bill_number: bill + 1,
      }));
    } catch (error) {
      console.log(error);
      cogoToast.error("error in getting bills");
    }
  };

  const createBillHandler = async (e) => {
    e.preventDefault();
    let data = {
      ...patientData,
      total_amount: "",
      hospital_id: user.id,
      hospital_name: user.name,
      hospital_type: user.type,
      wife_of: (patientData.gender == "Male" ? null : patientData.wife_of_or_son_of),
      son_of: (patientData.gender == "Female" ? null : patientData.wife_of_or_son_of)
    };
    console.log(data);
    try {
      const response = await axios.post(
        "https://test-cghs.doaguru.com/api/v1/auth/addIPDPatient",
        data
      );
      console.log(response);
      setShowProcedure(true);
      setBillId(response.data.user.id);
      cogoToast.success("Bill created successfully");
    } catch (error) {
      console.log(error);
    }
  };

  const getRandomNumber = () => Math.floor(Math.random() * 30) + 1;

  const procedureHandler = async (e) => {
    e.preventDefault();
    try {
      const proceduresCopy = [...procData.procedures];
      const response = await axios.post(
        `https://test-cghs.doaguru.com/api/v1/auth/addIPDProcedure/${billId}`,
        proceduresCopy
      );

      console.log(response);
      setProcData({ procedures: response.data.procedures });
      cogoToast.success("nice");
      navigate(`/FinalBillIPD/${billId}`);
    } catch (error) {
      console.log(error);
    }
  };
  const Suggestions = ({
    filteredSuggestions,
    onSelect,
    maxSuggestions,
    showSuggestions,
    setShowSuggestions,
  }) => {
    const handleSelect = (suggestion) => {
      onSelect(suggestion);
      setShowSuggestions(false); // Hide suggestions after selecting an item
    };

    return (
      <ul>
        {showSuggestions &&
          filteredSuggestions
            .slice(0, maxSuggestions)
            .map((suggestion, index) => (
              <li
                key={index}
                onClick={() => handleSelect(suggestion)}
                className="shadow p-3"
              >
                {suggestion}
              </li>
            ))}
      </ul>
    );
  };

  const handleSelectProcedure = (index, selectedProcedure) => {
    const newProcedures = [...procData.procedures];

    newProcedures[index].procedure = selectedProcedure;

    const matchedItem = procDataList.result?.find(
      (item) => item.procedure_name == newProcedures[index].procedure
    );
    console.log(newProcedures);

    const price = matchedItem
      ? user.type === "NABH"
        ? matchedItem.nabh_amount
        : matchedItem.non_nabh_amount
      : null; // or any default value you prefer
    console.log(price);
    newProcedures[index]["price"] = price;

    setProcData((prevData) => ({
      ...prevData,
      procedures: newProcedures,
    }));
    // Hide suggestions after selection
    setShowSuggestions(false);
  };

  useEffect(() => {
    getAllProcList();

    getAllBill();
    setPatientData((prevData) => ({
      ...prevData,
      ipd_number: getRandomNumber().toString(),
    }));
  }, []);

  return (
    <>
      <h3 className="text-center fw-bolder pb-4">
        Add Pateint Details for <b>IPD </b>{" "}
      </h3>
      {showProcedure === false ? (
        <form onSubmit={createBillHandler}>
          <div className="boxfirst rounded bg-white p-3">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                <div className="mb-3">
                  <label for="patientCategory" className="form-label fw-bold">
                    Patient Category
                  </label>
                  <select
                    autoComplete="off"
                    className="form-control"
                    id="email"
                    name="patientCategory"
                    value={patientData.patientCategory}
                    onChange={handleChange}
                    required
                  >
                    <option value="" disabled>
                      --select--
                    </option>
                    <option value="CGHS">CGHS</option>
                    <option value="CSMA">CSMA</option>
                    <option value="ESIS">ESIS</option>
                    <option value="ESE">ESE</option>
                    <option value="BSNM">BSNL</option>
                    <option value="RAILWAY">RAILWAY</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                <div className="mb-3">
                  <label for="reg-number" className="form-label fw-bold">
                    Registration Number
                  </label>
                  <input
                    type="number"
                    autoComplete="off"
                    className="form-control"
                    placeholder="registeration number"
                    name="reg_number"
                    value={patientData.reg_number}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                <div className="mb-3">
                  <label htmlFor="mobile_no" className="form-label fw-bold">
                    Mobile Number
                  </label>
                  <input
                    type="number"
                    autoComplete="off"
                    className="form-control"
                    placeholder="Enter 10-digit mobile number"
                    pattern="[0-9]{10}"
                    title="Mobile number should be 10 digits"
                    maxLength={10}
                    minLength={10}
                    required
                    name="mobile_no"
                    value={patientData.mobile_no}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                <div className="mb-3">
                  <label htmlFor="wife_of_or_son_of" className="form-label fw-bold">
                    Wife Of / Son Of
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    placeholder="Name of Guardian"
                    required
                    name="wife_of_or_son_of"
                    value={patientData.wife_of_or_son_of}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-12 col-12">
                <div className="mb-3">
                  <label for="opd-number" className="form-label fw-bold">
                    IPD Number
                  </label>
                  <input
                    type="number"
                    autoComplete="off"
                    className="form-control"
                    placeholder="ipd-number"
                    name="ipd_number"
                    value={patientData.ipd_number}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-12 col-12">
                <div className="mb-3">
                  <label for="bill-number" className="form-label fw-bold">
                    Bill Number
                  </label>
                  <input
                    type="number"
                    autoComplete="off"
                    className="form-control"
                    placeholder="bill-number"
                    required
                    name="bill_number"
                    value={patientData.bill_number}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-12 col-12">
                <div className="mb-3">
                  <label for="bill-number" className="form-label fw-bold">
                    Case Number
                  </label>
                  <input
                    type="number"
                    autoComplete="off"
                    className="form-control"
                    placeholder="Case Number"
                    required
                    name="case_number"
                    value={patientData.case_number}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-12 col-12">
                <div className="mb-3">
                  <label for="bill-number" className="form-label fw-bold">
                    Cr. Number
                  </label>
                  <input
                    type="number"
                    autoComplete="off"
                    className="form-control"
                    placeholder="Case Number"
                    required
                    name="cr_number"
                    value={patientData.cr_number}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                <div className="mb-3">
                  <label for="Pateint-Name" className="form-label fw-bold">
                    Pateint Name
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    placeholder="Pateint-Name"
                    required
                    name="patient_name"
                    value={patientData.patient_name}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-1 col-md-2 col-sm-12 col-12">
                <div className="mb-3">
                  <label for="age" className="form-label fw-bold">
                    Age
                  </label>
                  <input
                    type="number"
                    autoComplete="off"
                    className="form-control"
                    placeholder="enter age"
                    required
                    name="age"
                    value={patientData.age}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-12 col-12">
                <div className="mb-3">
                  <label for="gender" className="form-label fw-bold">
                    Gender
                  </label>
                  <select
                    className="form-control"
                    autoComplete="off"
                    value={patientData.gender}
                    onChange={handleChange}
                    id="gender"
                    name="gender"
                    required
                  >
                    <option>--select--</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                <div className="mb-3">
                  <label for="bill-date" className="form-label fw-bold">
                    Bill Date
                  </label>
                  <div className="">
                    <DatePicker
                      className="form-control w-full"
                      onChange={(date) => handleDateChange("bill_date", date)}
                      required
                      dateFormat="dd-MM-yyyy"
                      placeholderText="Select a date"
                      selected={patientData.bill_date}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                <div className="mb-3">
                  <label for="address" className="form-label fw-bold">
                    Address
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    placeholder="Enter your Address"
                    name="address"
                    value={patientData.address}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                <div className="mb-3">
                  <label for="Beneficiary_id" className="form-label fw-bold">
                    Beneficiary Id
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    name="beneficiary_id"
                    placeholder="Beneficiary Id"
                    required
                    value={patientData.beneficiary_id}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                <div className="mb-3">
                  <label for="Date-treatment" className="form-label fw-bold">
                    Admission Date
                  </label>
                  <div className="">
                    <DatePicker
                      selected={patientData.treatment_date}
                      name="treatment_date"
                      onChange={(date) =>
                        handleDateChange("treatment_date", date)
                      }
                      className="form-control"
                      dateFormat="dd-MM-yyyy hh:mm aa"
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      required
                      placeholderText="Select a date and time"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                <div className="mb-3">
                  <label for="doctor_name" className="form-label fw-bold">
                    Doctor Name
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    placeholder="Doctor Name"
                    name="doctor_name"
                    required
                    value={patientData.doctor_name}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                <div className="mb-3">
                  <label for="bill_prepared_by" className="form-label fw-bold">
                    Bill Prepared By
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    placeholder="Bill Prepared By"
                    required
                    name="bill_prepared_by"
                    value={patientData.bill_prepared_by}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                <div className="mb-3">
                  <label for="department" className="form-label fw-bold">
                    Department
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    placeholder="Department "
                    required
                    name="department"
                    value={patientData.department}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                <div className="mb-3">
                  <label
                    for="condition_of_admission"
                    className="form-label fw-bold"
                  >
                    Condition Of Admission
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    placeholder="Condition Of Admission "
                    required
                    name="condition_of_admission"
                    value={patientData.condition_of_admission}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                <div className="mb-3">
                  <label for="Investigation" className="form-label fw-bold">
                    Investigation
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    placeholder="Investigation "
                    required
                    name="investigation"
                    value={patientData.investigation}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                <div className="mb-3">
                  <label for="card_token_no" className="form-label fw-bold">
                    Card/Token No.
                  </label>
                  <input
                    type="number"
                    autoComplete="off"
                    className="form-control"
                    placeholder="Card/Token no."
                    required
                    name="card_token_no"
                    value={patientData.card_token_no}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                <div className="mb-3">
                  <label for="ward_bed_no" className="form-label fw-bold">
                    Ward/Bed No.
                  </label>
                  <input
                    type="number"
                    autoComplete="off"
                    className="form-control"
                    placeholder="Ward/Bed No."
                    required
                    name="ward_bed_no"
                    value={patientData.ward_bed_no}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                <div className="mb-3">
                  <label for="dischargeDate" className="form-label fw-bold">
                    Discharge Date
                  </label>
                  <DatePicker
                    className="form-control"
                    dateFormat="dd-MM-yyyy h:mm aa"
                    placeholderText="Select a date and time"
                    selected={patientData.dischargeDate}
                    onChange={(date) => handleDateChange("dischargeDate", date)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="Time"
                  />
                </div>
              </div>
            </div>
            <div>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </div>
        </form>
      ) : (
        <>
          <form onSubmit={procedureHandler}>
            <h4 className="mt-2 mb-2">Add Procedure Details</h4>
            <div className="boxsecond rounded bg-white p-3">
              <div className="boxsecond rounded bg-white p-3">
                {procData.procedures.map((procedure, index) => (
                  <div className="row" key={index}>
                    <div className="col-lg-5 col-md-5 col-sm-12 col-12">
                      <div className="mb-3">
                        <label
                          htmlFor={`Procedure-${index}`}
                          className="form-label fw-bold"
                        >
                          Select Procedure
                        </label>
                        <input
                          type="search"
                          className="form-control"
                          id={`Procedure-${index}`}
                          placeholder="Select Procedure"
                          value={procedure.procedure}
                          required
                          onChange={(e) => {
                            handleChangeProcedure(
                              index,
                              "procedure",
                              e.target.value
                            );
                            // Show suggestions when there is some input
                            setShowSuggestions(
                              e.target.value.trim().length > 0
                            );
                          }}
                        />
                        <Suggestions
                          filteredSuggestions={filteredSuggestions}
                          onSelect={(selectedProcedure) =>
                            handleSelectProcedure(
                              focusedInputIndex,
                              selectedProcedure
                            )
                          }
                          maxSuggestions={5}
                          showSuggestions={
                            showSuggestions && focusedInputIndex == index
                          }
                          setShowSuggestions={setShowSuggestions}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                      <div className="mb-3">
                        <label
                          htmlFor={`Procedure-price-${index}`}
                          className="form-label fw-bold"
                        >
                          Procedure Price
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id={`Procedure-price-${index}`}
                          placeholder="Enter Procedure Price"
                          value={procedure.price}
                          required
                          onChange={(e) =>
                            handleChangePrice(index, "price", e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                      <div className="mb-3">
                        <label
                          htmlFor={`Procedure-Quantity-${index}`}
                          className="form-label fw-bold"
                        >
                          Procedure Quantity
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id={`Procedure-Quantity-${index}`}
                          placeholder="Enter Procedure Quantity"
                          value={procedure.quantity}
                          required
                          onChange={(e) =>
                            handleChangeQuantity(
                              index,
                              "quantity",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>

                    <div className="col-lg-1 col-md-1 col-sm-12 col-12 divsettle">
                      <button
                        className="btn btn-success"
                        type="button"
                        onClick={() => handleAddProcedure()}
                        style={{
                          display:
                            index === procData.procedures.length - 1
                              ? "block"
                              : "none",
                        }}
                      >
                        +
                      </button>
                      {index > 0 && (
                        <button
                          className="btn btn-danger"
                          type="button"
                          onClick={() => handleRemoveProcedure(index)}
                        >
                          x
                        </button>
                      )}
                    </div>
                    <div className="col-lg-1 col-md-1 col-sm-12 col-12 divsettle"></div>
                  </div>
                ))}
              </div>

              <div>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </>
      )}
    </>
  );
};
export default AddPatient;

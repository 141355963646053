import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Sidebar from "../AdminDashboard/Sidebar";
import NavDash from "../AdminDashboard/NavDash";
import axios from "axios";
import { useSelector } from "react-redux";
import cogoToast from "cogo-toast";

const HeadFoot = () => {
  const user = useSelector((state) => state.user);
  
  const [headerImg, setHeaderImg] = useState("");
  const [footerImg, setFooterImg] = useState("");
  const [sealImg, setSealImg] = useState("");
  const [signImg, setSignImg] = useState("");
  const [hospitalDoc, setHospitalDoc] = useState([]);

  // Add a state to trigger useEffect
  const [refreshData, setRefreshData] = useState(false);

console.log(hospitalDoc)
  const formdata = new FormData();
  formdata.append("hospital_id", user.id);
  formdata.append("headerImg", headerImg);
  formdata.append("footerImg", footerImg);
  formdata.append("sealImg", sealImg);
  formdata.append("signImg", signImg);

  const [toggle, setToggle] = useState(true);
  const Toggle = () => {
    setToggle(!toggle);
  };

  const addImage = async (e) => {
    e.preventDefault();
    try {
      console.log(formdata);
      const response = await axios.post(
        "https://test-cghs.doaguru.com/api/v1/auth/addhospital-document",
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response)
      cogoToast.success(response?.data?.message);
      // Set the state to trigger useEffect and re-fetch data
      setRefreshData(!refreshData);
    } catch (error) {
      console.log(error?.response?.data?.error);
      cogoToast.error(error?.response?.data?.error);
    }
  };

  console.log(formdata)
  
  const displayDocHospital = async (req, res) => {
    
    try {
      const viewDoc = await axios.get(
        `https://test-cghs.doaguru.com/api/v1/auth/displayHospitalDoc/${user.id}`
      );

      
      setHospitalDoc(viewDoc.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    displayDocHospital();
  }, []);

  useEffect(() => {
    displayDocHospital();
  }, [refreshData]); // Include refreshData in the dependency array

  



 
  return (
    <>
      <Container>
        <div className="container-fluid bgcolor min-vh-100">
          <div className="row">
            {/* {toggle && (
              <div className="col-2 clrblack vh-100 position-fixed">
                <Sidebar />
              </div>
            )} */}
            {/* {toggle && <div className="col-2"></div>} */}
            <div className="col mrg">
              {/* <NavDash Toggle={Toggle} /> */}
              <div className="container">
                <h4 className="">Add Header & footer image</h4>
                <form onSubmit={addImage} enctype="multipart/form-data">
                  <div className="boxfirst rounded bg-white p-3">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div class="d-flex flex-column mb-3">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label fw-bold"
                          >
                            Insert Header Image
                          </label>
                          <input
                            type="file"
                            name="headerImg"
                            onChange={(e) => {
                              setHeaderImg(e.target.files[0]);
                            }}
                            placeholder="Insert Header Image"
                            accept="image/jpeg,image/jpg,image/png"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div class="mb-3 d-flex flex-column">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label fw-bold"
                          >
                            Insert Seal Image
                          </label>
                          <input
                            type="file"
                            name="sealImg"
                            onChange={(e) => {
                              setSealImg(e.target.files[0]);
                            }}
                            placeholder="Insert Seal Image"
                            accept="image/jpeg,image/jpg,image/png"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div class="mb-3 d-flex flex-column">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label fw-bold"
                          >
                            Insert Footer Image
                          </label>
                          <input
                            type="file"
                            name="footerImg"
                            onChange={(e) => {
                              setFooterImg(e.target.files[0]);
                            }}
                            placeholder="   Insert Footer Image"
                            accept="image/jpeg,image/jpg,image/png"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div class="mb-3 d-flex flex-column">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label fw-bold"
                          >
                            Insert sign Image
                          </label>
                          <input
                            type="file"
                            name="signImg"
                            onChange={(e) => {
                              setSignImg(e.target.files[0]);
                            }}
                            placeholder="Insert sign Image"
                            accept="image/jpeg,image/jpg,image/png"
                          />
                        </div>
                      </div>
                    </div>
                    <button type="submit" class="btn btn-primary">
                      Add Images
                    </button>
                  </div>
                </form>

                <div className="mt-3 rounded bg-white p-3">
                  <h4 className="text-center fw-bold">Header Image</h4>
                  <div className="headimage">
                    <img src={hospitalDoc.header_img} alt="header" srcset="" />
                  </div>
                </div>
                <div className="mt-3 rounded bg-white p-3">
                  <h4 className="text-center fw-bold">Footer Image</h4>
                  <div className="headimage">
                    <img src={hospitalDoc.footer_img} alt="header" srcset="" />
                  </div>
                </div>
                <div className="mt-3 rounded bg-white p-3">
                  <h4 className="text-center fw-bold">Seal Image</h4>
                  <div className="headimage">
                    <img src={hospitalDoc.sealimg} alt="header" srcset="" />
                  </div>
                </div>
                <div className="mt-3 rounded bg-white p-3">
                  <h4 className="text-center fw-bold">Sign Image</h4>
                  <div className="headimage">
                    <img src={hospitalDoc.signimg} alt="header" srcset="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default HeadFoot;
const Container = styled.div`
  .sidebar {
    width: 220px;
  }
  .mrg {
    padding: 0rem !important;
  }
  .headimage {
    height: 15rem;
    width: auto;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .bgcolor {
    background-color: #80bef5;
  }

  .clrblack {
    background-color: black !important;
  }
`;

import styled from "styled-components";
import Sidebar from "../../AdminDashboard/Sidebar";
import NavDash from "../../AdminDashboard/NavDash";
import { useEffect, useRef, useState } from "react";
import { RiDeleteBack2Fill, RiInputCursorMove } from "react-icons/ri";
import axios from "axios";
import cogoToast from "cogo-toast";
import { useParams } from "react-router-dom";

const defaultData = {
  Diagnosis: [],
  Surgical_Procedure: [],
  Treatment_Recieved: [],
  medicines: [],
  TreatmentAdvised: [],
  LocallyRele: [],
};

// This default used foor locally rele or treatment
const defaultMedicineDetails = {
  name: "",
  dose: "",
  freq: "",
  duration: "",
};

const DischargeBill = () => {
  const [toggle, setToggle] = useState(true);
  const [data, setData] = useState({ ...defaultData, nextReview: "" });
  const [dropDown, setDropDown] = useState(defaultData);
  const [currInput, setCurrInput] = useState();
  const [activeInput, setActiveInput] = useState(null);
  const [highLightedText, setHightLightedText] = useState(-1);
  const [treatement, setTreatment] = useState({
    ...defaultMedicineDetails,
    type: "",
  });
  const [suggestion, setSuggestion] = useState();
  const [locallyRele, setLocallyRele] = useState(defaultMedicineDetails);
  const inputRef = useRef();
  const { billId } = useParams();
  console.log(billId);

  // Toggle for Sidebar
  const Toggle = () => {
    setToggle(!toggle);
  };

  const handleKeyDown = (e) => {
    setHightLightedText(0);
    let items = suggestion[activeInput];
    if (items.length > 0) {
      if (e.key == "ArrowDown") {
        let idx = Math.min(highLightedText + 1, items.length - 1);
        let nextEle = currInput.nextElementSibling;
        if (nextEle) {
          let nextRect = nextEle.getBoundingClientRect();
          let firstChild = nextEle.firstElementChild;
          if (firstChild) {
            let childRect = firstChild.getBoundingClientRect();
            let nextHeight = nextRect.height;
            let childHeight = childRect.height;
            let baseEle =
              (childHeight !== 0 ? nextHeight / childHeight : 0) - 2;

            console.log("Base Element Calculation:", Math.round(baseEle));
            if (Math.round(baseEle) <= highLightedText) {
              console.log("inthe base line ", baseEle, highLightedText);
              nextEle.scrollBy(0, childHeight);
            }
          }
        }
        console.log(idx);
        setHightLightedText(idx);
      }
      if (e.key == "ArrowUp") {
        let idx = Math.max(highLightedText - 1, 0);
        setHightLightedText(idx);
        let nextEle = currInput.nextElementSibling;
        if (nextEle) {
          let nextRect = nextEle.getBoundingClientRect();
          let firstChild = nextEle.firstElementChild;
          if (firstChild) {
            let childRect = firstChild.getBoundingClientRect();
            let nextHeight = nextRect.height;
            let childHeight = childRect.height;
            let baseEle =
              (childHeight !== 0 ? nextHeight / childHeight : 0) - 2;

            console.log("Base Element Calculation:", Math.round(baseEle));
            if (Math.round(baseEle) >= highLightedText) {
              console.log("inthe base line ", baseEle, highLightedText);
              nextEle.scrollBy(0, -childHeight);
            }
          }
        }
      }
      if (e.key == "Enter") {
        if (!currInput) return;
        // console.log(currInput);
        console.log(suggestion);
        let obj = suggestion[activeInput][highLightedText];
        let nameValue = obj.name;
        if (activeInput == "LocallyRele") {
          setLocallyRele({
            ...locallyRele,
            name: nameValue,
          });
        } else if (activeInput == "medicines") {
          setTreatment({
            ...treatement,
            name: nameValue,
          });
        } else {
          currInput.value = obj.name;
        }

        // setActiveInput(false);
      }
    }
  };

  const handleChange = (e) => {
    let letters = e.target.value;

    const watchedLetters = dropDown[activeInput].filter((ele) =>
      ele.name.toLowerCase().includes(letters.toLowerCase())
    );
    console.log(suggestion);
    setSuggestion({
      Diagnosis: [
        "diagnose 1",
        "diagnose 10",
        "diagnose 8",
        "diagnoe 7",
        "diagnose 6",
        "diagnose 5",
        "diagnose 4",
        "diagnose 3",
        "diagnose 2",
      ],
    });
    if (letters.length < 1) setSuggestion(defaultData);
  };

  // Active dropDown as correct input focus
  const handleCurrentInput = (e) => {
    setSuggestion(defaultData);
    setActiveInput(false);
    // for drap frequency is differenty to other inputs
    if (e.target.id == "Dropfrequency") return;
    setCurrInput(e.target);
    setActiveInput(e.target.name);
  };

  // Add DropDown option to input
  const handleOptions = (e) => {
    if (!currInput) return;
    console.log(currInput);
    currInput.value = e.target.textContent;
    setActiveInput(false);
  };

  // Delete Right side list Item
  const deleteListItem = (index) => {
    let tempArr = data[currInput.name];
    tempArr.splice(index, 1);
    setData({ ...data, [currInput.name]: tempArr });
  };

  const handleTreatmentChange = (e) => {
    const { name, value } = e.target;
    setTreatment({ ...treatement, [name]: value });
  };

  const handleLocallyReleChange = (e) => {
    const { name, value } = e.target;
    setLocallyRele({ ...locallyRele, [name]: value });
  };

  // Add data in list
  const handleListAdd = () => {
    if (!currInput || currInput.value == "") return;
    let getInput = currInput?.value;
    let propName = currInput.name;
    setData({ ...data, [propName]: [...data[propName], getInput] });
    currInput.value = "";
  };

  // Adding data In list [Medicines]
  const submitTreatment = () => {
    let str = "";
    let obj = { ...treatement };
    for (let item in obj) {
      str += obj[item] + " ";
    }
    setData({ ...data, TreatmentAdvised: [...data.TreatmentAdvised, str] });
    setTreatment(defaultMedicineDetails);
  };

  // Adding data In list [localy rele]
  const submitLocallyRele = () => {
    let str = "";
    let obj = { ...locallyRele };
    for (let item in obj) {
      str += obj[item] + " ";
    }
    setData({ ...data, LocallyRele: [...data.LocallyRele, str] });
    setLocallyRele(defaultMedicineDetails);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(data);
    const inputs = inputRef.current.querySelectorAll("input");
    inputs.forEach((inp) => {
      inp.value = "";
    });

    try {
      let DataInJson = { ...data };
      for (let key in DataInJson) {
        if (Array.isArray(DataInJson[key])) {
          DataInJson[key] = DataInJson[key].map((item) => "'" + item + "'");
          DataInJson[key] = "[" + DataInJson[key] + "]";
        }
      }
      console.log(DataInJson);
      const response = await axios.post(
        "https://test-cghs.doaguru.com/api/v1/auth/addDischargeSummary",
        { ...DataInJson, patient_id: billId }
      );
      console.log(response);
      if (response.status == 201) {
        cogoToast.success("Patient Discharge summary already exist");
      } else {
        cogoToast.success("Discharge Summary Added Suceesfully");
        setData({ ...defaultData, nextReview: "" });
        setTreatment({ ...defaultMedicineDetails, type: "" });
        setLocallyRele(defaultMedicineDetails);
        window.open(`/DischargesSummary/${billId}`, "_blank");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllData = async () => {
    try {
      const [
        diagnoseResponse,
        medicinesResponse,
        surgicalProcedureResponse,
        treatmentsResponse,
        LocallyReleResponse,
      ] = await Promise.all([
        axios.get("https://test-cghs.doaguru.com/api/v1/auth/diagnose"),
        axios.get("https://test-cghs.doaguru.com/api/v1/auth/medicines"),
        axios.get("https://test-cghs.doaguru.com/api/v1/auth/surgical_procedure"),
        axios.get("https://test-cghs.doaguru.com/api/v1/auth/treatments"),
        axios.get("https://test-cghs.doaguru.com/api/v1/auth/locallyReLe"),
      ]);

      console.log(medicinesResponse);

      setDropDown({
        ...dropDown,
        Diagnosis: diagnoseResponse.data,
        Surgical_Procedure: surgicalProcedureResponse.data,
        Treatment_Recieved: treatmentsResponse.data,
        medicines: medicinesResponse.data,
        LocallyRele: LocallyReleResponse.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllData();
    const inputs = inputRef.current.querySelectorAll("input");
    inputs.forEach((inp) => {
      inp.addEventListener("focus", handleCurrentInput);
    });

    document.querySelector("body").addEventListener("click", (e) => {
      let ele = e.target.tagName;
      if (!(ele === "UL" || ele === "LI" || ele === "INPUT")) {
        setActiveInput("");
      }
    });

    return () => {
      inputs.forEach((inp) => {
        inp.removeEventListener("focus", handleCurrentInput);
      });
      document.querySelector("body").removeEventListener("click", () => { });
    };
  }, []);

  return (
    <>
      <Container>
        <div className="container-fluid bgcolor min-vh-100">
          <div className="row">
            {toggle && (
              <div className="col-2 bg-dark vh-100 position-fixed">
                <Sidebar />
              </div>
            )}
            {toggle && <div className="col-2"></div>}
            <div className="col mrg">
              <NavDash Toggle={Toggle} />

              <div className="container mt-5 pb-5">
                <h4>Discharge Summary</h4>
                <form>
                  <div className="boxfirst rounded bg-white p-3 py-5">
                    <div className="row">
                      <div className="col">
                        <div
                          ref={inputRef}
                          className="col d-flex flex-column gap-4 w-100 mb-3"
                        >
                          <div className="col-lg-12 col-sm-12 col-12">
                            <div className="mb-3 d-flex justify-content-between">
                              <label
                                htmlFor="Diagnosis"
                                className="form-label fw-bold"
                              >
                                Diagnosis
                              </label>
                              <div className="col-9 d-flex gap-1">
                                <div className="col-10 position-relative">
                                  <input
                                    autoComplete="off"
                                    type="text"
                                    className="form-control"
                                    placeholder="Diagnose"
                                    name="Diagnosis"
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                  />
                                  {activeInput === "Diagnosis" && (
                                    <ul
                                      style={{ zIndex: "1" }}
                                      className="border overflow-auto position-absolute bg-white w-100"
                                    >
                                      {/* {dropDown.Diagnosis.map((item) => ( */}
                                      {suggestion?.Diagnosis.map(
                                        (item, index) => (
                                          <li
                                            onClick={handleOptions}
                                            className={
                                              index == highLightedText
                                                ? "bg-success"
                                                : ""
                                            }
                                            key={item.id}
                                            value={item.name}
                                          >
                                            {item.name}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  )}
                                </div>
                                <div>
                                  <button
                                    onClick={handleListAdd}
                                    className="btn btn-primary"
                                    type="button"
                                  >
                                    Add
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 col-sm-12 col-12">
                            <div className="mb-3 d-flex justify-content-between">
                              <label
                                htmlFor="Surgical_Procedure"
                                className="form-label fw-bold"
                              >
                                Surgical Procedure
                              </label>
                              <div className="col-9 d-flex gap-1">
                                <div className="col-10 position-relative">
                                  <input
                                    autoComplete="off"
                                    type="text"
                                    className="form-control"
                                    placeholder="Surgical Procedure"
                                    name="Surgical_Procedure"
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                  />
                                  {activeInput === "Surgical_Procedure" && (
                                    <ul
                                      style={{ zIndex: "1" }}
                                      className="border  overflow-auto  position-absolute bg-white w-100"
                                    >
                                      {suggestion?.Surgical_Procedure.map(
                                        (item, index) => (
                                          <li
                                            onClick={handleOptions}
                                            className={
                                              index == highLightedText
                                                ? "bg-success"
                                                : ""
                                            }
                                            key={item.id}
                                            value={item.name}
                                          >
                                            {item.name}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  )}
                                </div>
                                <div>
                                  <button
                                    onClick={handleListAdd}
                                    className="btn btn-primary"
                                    type="button"
                                  >
                                    Add
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 col-sm-12 col-12">
                            <div className="mb-3 d-flex justify-content-between">
                              <label
                                htmlFor="Treatment_Recieved"
                                className="form-label fw-bold"
                              >
                                Treatment Recieved
                              </label>
                              <div className="col-9 d-flex gap-1">
                                <div className="col-10 position-relative">
                                  <input
                                    autoComplete="off"
                                    type="text"
                                    className="form-control"
                                    placeholder="Treatment Recieved"
                                    name="Treatment_Recieved"
                                  />
                                </div>
                                <div>
                                  <button
                                    onClick={handleListAdd}
                                    className="btn btn-primary"
                                    type="button"
                                  >
                                    Add
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 col-sm-12 col-12">
                            <div
                              id="LocallyRele"
                              onClick={() =>
                                setCurrInput({ name: "LocallyRele" })
                              }
                              className="mb-3"
                            >
                              <h5 className="fw-bold">Locally Re le</h5>
                              <div className="col-10 d-flex gap-1 ms-5">
                                <div className="col-12">
                                  <div className="d-flex py-2 justify-items-center align-items-center">
                                    <div className="col-4">
                                      <label className=" w-100 px-3 fw-semibold">
                                        Name of Drop
                                      </label>
                                    </div>
                                    <div className="w-100 position-relative">
                                      <input
                                        autoComplete="off"
                                        type="text"
                                        className="form-control"
                                        placeholder="Drop Name"
                                        name="LocallyRele"
                                        onKeyDown={handleKeyDown}
                                        onChange={(e) => {
                                          setLocallyRele({
                                            ...locallyRele,
                                            name: e.target.value,
                                          });
                                          handleChange(e);
                                        }}
                                        value={locallyRele.name}
                                      />
                                      {activeInput === "LocallyRele" && (
                                        <ul
                                          style={{ zIndex: "1" }}
                                          className="border overflow-auto  position-absolute bg-white w-100"
                                        >
                                          {suggestion?.LocallyRele.map(
                                            (item, index) => (
                                              <li
                                                className={
                                                  index == highLightedText
                                                    ? "bg-success"
                                                    : ""
                                                }
                                                onClick={() => {
                                                  setLocallyRele({
                                                    ...locallyRele,
                                                    name: item.name,
                                                  });
                                                  setActiveInput(false);
                                                }}
                                                key={item.id}
                                                value={item.name}
                                              >
                                                {item.name}
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      )}
                                    </div>
                                  </div>
                                  <div className="d-flex py-2 justify-items-center align-items-center">
                                    <div className="col-4">
                                      <label className=" w-100 px-3 fw-semibold">
                                        Frequency
                                      </label>
                                    </div>
                                    <div className="w-100 position-relative">
                                      <input
                                        autoComplete="off"
                                        className="form-control"
                                        id="Dropfrequency"
                                        placeholder="Frequency"
                                        name="frequency"
                                        onChange={(e) =>
                                          setLocallyRele({
                                            ...locallyRele,
                                            freq: e.target.value,
                                          })
                                        }
                                        value={locallyRele.freq}
                                      />
                                    </div>
                                  </div>
                                  <div className="d-flex py-2 justify-items-center align-items-center">
                                    <div className="col-4">
                                      <label className=" w-100 px-3 fw-semibold">
                                        Duration
                                      </label>
                                    </div>
                                    <div className="w-100 position-relative">
                                      <select
                                        name="duration"
                                        value={locallyRele.duration}
                                        onChange={handleLocallyReleChange}
                                        className="form-select"
                                        id="duration"
                                      >
                                        <option value=""></option>
                                        <option value="1">1 day</option>
                                        <option value="2">2 days</option>
                                        <option value="3">3 days</option>
                                        <option value="4">4 days</option>
                                        <option value="5">5 days</option>
                                        <option value="6">6 days</option>
                                        <option value="7">1 week</option>
                                        <option value="14">2 weeks</option>
                                        <option value="21">3 weeks</option>
                                        <option value="30">1 Month</option>
                                        <option value="90">3 Months</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="text-end">
                                    <button
                                      onClick={submitLocallyRele}
                                      className="btn btn-primary"
                                      type="button"
                                    >
                                      Add LocallyRele
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 col-sm-12 col-12">
                          <div
                            id="TreatmentAdvised"
                            onClick={() =>
                              setCurrInput({ name: "TreatmentAdvised" })
                            }
                            className="mb-3"
                          >
                            <h5 className="form-label fw-bold">
                              TreateMent Advised
                            </h5>
                            <div className="col-10 d-flex gap-1 ms-5">
                              <div className="col-12">
                                <div className="d-flex py-2 justify-items-center align-items-center">
                                  <div className="col-4">
                                    <label className=" w-100 px-3 fw-semibold">
                                      Type of Medicine
                                    </label>
                                  </div>
                                  <div className="w-100 position-relative">
                                    <select
                                      name="type"
                                      value={treatement.type}
                                      onChange={handleTreatmentChange}
                                      className="form-select"
                                      id="Medicine Type"
                                    >
                                      <option value=""></option>
                                      <option value="1-1-1(TDS)">TAB</option>
                                      <option value="1-1-0(BD)">CAP</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="d-flex py-2 justify-items-center align-items-center">
                                  <div className="col-4">
                                    <label className=" w-100 px-3 fw-semibold">
                                      Medicine Name
                                    </label>
                                  </div>
                                  <div className="w-100 position-relative">
                                    <input
                                      autoComplete="off"
                                      type="text"
                                      className="form-control"
                                      placeholder="Medicines name"
                                      name="medicines"
                                      onKeyDown={handleKeyDown}
                                      onFocus={handleCurrentInput}
                                      onBlur={() => setSuggestion(defaultData)}
                                      onChange={(e) => {
                                        setTreatment({
                                          ...treatement,
                                          name: e.target.value,
                                        });
                                        handleChange(e);
                                      }}
                                      value={treatement.name}
                                    />
                                    {activeInput === "medicines" && (
                                      <ul
                                        style={{ zIndex: "1" }}
                                        className="border overflow-auto  position-absolute bg-white w-100"
                                      >
                                        {suggestion?.medicines.map(
                                          (item, index) => (
                                            <li
                                              onClick={() => {
                                                setTreatment({
                                                  ...treatement,
                                                  name: item.name,
                                                });
                                                setActiveInput(false);
                                              }}
                                              className={
                                                index == highLightedText
                                                  ? "bg-success"
                                                  : ""
                                              }
                                              key={item.id}
                                              value={item.name}
                                            >
                                              {item.name}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    )}
                                  </div>
                                </div>
                                <div className="d-flex py-2 justify-items-center align-items-center">
                                  <div className="col-4">
                                    <label className=" w-100 px-3 fw-semibold">
                                      Dosage
                                    </label>
                                  </div>
                                  <div className="w-100 position-relative">
                                    <input
                                      autoComplete="off"
                                      type="text"
                                      className="form-control"
                                      placeholder="dose"
                                      name="dose"
                                      value={treatement.dose}
                                      onChange={handleTreatmentChange}
                                    />
                                  </div>
                                </div>
                                <div className="d-flex py-2 justify-items-center align-items-center">
                                  <div className="col-4">
                                    <label className=" w-100 px-3 fw-semibold">
                                      Frequency
                                    </label>
                                  </div>
                                  <div className="w-100 position-relative">
                                    <select
                                      name="freq"
                                      value={treatement.freq}
                                      onChange={handleTreatmentChange}
                                      className="form-select"
                                      id="frequency"
                                    >
                                      <option value=""></option>
                                      <option value="1-1-1(TDS)">
                                        1-1-1(TDS)
                                      </option>
                                      <option value="1-1-0(BD)">
                                        1-1-0(BD)
                                      </option>
                                      <option value="0-1-1(BD)">
                                        0-1-1(BD)
                                      </option>
                                      <option value="1-0-1(BD)">
                                        1-0-1(BD)
                                      </option>
                                      <option value="0-0-1(HS)">
                                        0-0-1(HS)
                                      </option>
                                      <option value="0-1-0(OD)">
                                        0-1-0(OD)
                                      </option>
                                      <option value="1-0-0(BM)">
                                        1-0-0(BM)
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div className="d-flex py-2 justify-items-center align-items-center">
                                  <div className="col-4">
                                    <label className=" w-100 px-3 fw-semibold">
                                      Duration
                                    </label>
                                  </div>
                                  <div className="w-100 position-relative">
                                    <select
                                      name="duration"
                                      value={treatement.duration}
                                      onChange={handleTreatmentChange}
                                      className="form-select"
                                      id="duration"
                                    >
                                      <option value=""></option>
                                      <option value="1">1 day</option>
                                      <option value="2">2 days</option>
                                      <option value="3">3 days</option>
                                      <option value="4">4 days</option>
                                      <option value="5">5 days</option>
                                      <option value="6">6 days</option>
                                      <option value="7">1 week</option>
                                      <option value="14">2 weeks</option>
                                      <option value="21">3 weeks</option>
                                      <option value="30">1 Month</option>
                                      <option value="90">3 Months</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="text-end">
                                  <button
                                    onClick={submitTreatment}
                                    className="btn btn-primary"
                                    type="button"
                                  >
                                    Add Medicine
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex py-2 justify-items-center align-items-center">
                          <div className="col-4">
                            <label className=" w-100 px-3 fw-semibold">
                              Next Review
                            </label>
                          </div>
                          <div className="w-100 position-relative">
                            <select
                              name="next-review"
                              value={data.nextReview}
                              onChange={(e) =>
                                setData({ ...data, nextReview: e.target.value })
                              }
                              className="form-select"
                              id="next-review"
                            >
                              <option value=""></option>
                              <option value="1">1 day</option>
                              <option value="1">1 day</option>
                              <option value="2">2 days</option>
                              <option value="3">3 days</option>
                              <option value="4">4 days</option>
                              <option value="5">5 days</option>
                              <option value="6">6 days</option>
                              <option value="7">7 days</option>
                            </select>
                          </div>
                        </div>
                        {/* New fields */}
                        {[
                          "discharge_type",
                          "medical_history",
                          "past_medical",
                          "post_operation",
                          "hospital_course",
                          "blood_transution",
                          "followUp_urgent",
                          "followUp_advice",
                          "other_byConsultant",
                          "prepared_by",
                          "printed_by",
                          "procedure_note",
                          "when_urgentCare",
                        ].map((field) => (
                          <div
                            className="col-lg-12 col-sm-12 col-12"
                            key={field}
                          >
                            <div className="mb-3 d-flex justify-content-between">
                              <label
                                htmlFor={field}
                                className="form-label fw-bold"
                              >
                                {field.replace(/_/g, " ")}
                              </label>
                              <div className="col-9 d-flex gap-1">
                                <div className="col-10 position-relative">
                                  <input
                                    autoComplete="off"
                                    type="text"
                                    value={data.field}
                                    className="form-control"
                                    placeholder={field}
                                    onChange={(e) =>
                                      setData({
                                        ...data,
                                        [field]: e.target.value,
                                      })
                                    }
                                    name={field}
                                  />
                                </div>
                                {/* <div>
                                  <button
                                    onClick={handleListAdd}
                                    className="btn btn-primary"
                                    type="button"
                                  >
                                    Add
                                  </button>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        ))}

                        <div className="text-end mt-4">
                          <button
                            onClick={handleSubmit}
                            type="button"
                            className="btn btn-primary"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                      {/* right side */}
                      <div style={{ maxWidth: "50%" }} className="col w-100">
                        <p className="text-center fs-3">
                          List of {currInput?.name || "None"}
                        </p>
                        <div className="d-flex flex-column gap-2 px-4 w-100">
                          {currInput?.name
                            ? data[currInput.name].map((item, idx) => (
                              <>
                                <div
                                  key={idx}
                                  className="d-flex justify-content-between align-items-center
"
                                >
                                  <div className="w-100 d-flex">
                                    <b className="me-2">{idx + 1}.</b>
                                    {item}
                                  </div>
                                  <RiDeleteBack2Fill
                                    onClick={() => deleteListItem(idx)}
                                    className="fs-4"
                                  />
                                </div>
                              </>
                            ))
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};
export default DischargeBill;
const Container = styled.div`
  .bgcolor {
    background-color: #80bef5;
  }
  .divsettle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.8rem;
  }
  li {
    list-style: none;
  }
  ul {
    max-height: 10rem;
  }
`;

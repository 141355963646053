import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import axios from "axios";
import cogoToast from "cogo-toast";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import NavDash from "../../AdminDashboard/NavDash";
import Sidebar from "../../AdminDashboard/Sidebar";
import styled from "styled-components";

const initialState = {
  reg_number: "",
  ipd_number: "",
  bill_number: "",
  patient_name: "",
  age: "",
  gender: "",
  bill_date: "",
  beneficiary_id: "",
  treatment_date: "",
  address: "",
  doctor_name: "",
  bill_prepared_by: "",
  department: "",
  condition_of_admission: "",
  investigation: "",
  card_token_no: "",
  ward_bed_no: "",
  patientCategory: "",
  hospital_id: "",
  hospital_name: "",
  hospital_type: "",
  dischargeDate: "",
};

const EditPatient = () => {
  const [patientData, setPatientData] = useState(initialState);
  const [prevData, setPrevData] = useState();
  const [showProcedure, setShowProcedure] = useState(false);
  const [procData, setProcData] = useState({
    procedures: [{ procedure: "", quantity: "", price: "" }],
  });
  const [procDataList, setProcDataList] = useState([]);
  const [focusedInputIndex, setFocusedInputIndex] = useState(null);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [preProcData, setPreProcData] = useState("");
  const { billId } = useParams();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [toggle, setToggle] = useState(true);
  const Toggle = () => {
    setToggle(!toggle);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setPatientData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const getBill = async () => {
    try {
      const response = await axios.get(
        `https://test-cghs.doaguru.com/api/v1/auth/getIPDBill/${billId}`
      );
      console.log(response.data);
      setPrevData(response.data);
    } catch (error) {
      console.log(error);
      cogoToast.error("error in getting bills");
    }
  };

  const createBillHandler = async (e) => {
    console.log(patientData);
    let editedData = {
      ...patientData,
      wife_of: patientData.gender == "Male" ? null : patientData.wife_of_or_son_of,
      son_of: patientData.gender == "Female" ? null : patientData.wife_of_or_son_of
    }
    e.preventDefault();
    try {
      const response = await axios.put(
        `https://test-cghs.doaguru.com/api/v1/auth/editIPDPatient/${billId}`,
        editedData
      );

      console.log(response);
      setShowProcedure(true);
      cogoToast.success("Bill Edited Successfully");
    } catch (error) {
      console.log(error);
      cogoToast.error("Something wents wrong");
    }
  };

  const handleDateChange = (type, date) => {
    setPatientData((prevData) => ({
      ...prevData,
      [type]: date,
    }));
  };

  // Procedure related
  const getProcedure = async () => {
    const response = await axios.get(
      `https://test-cghs.doaguru.com/api/v1/auth/getIPDPatientProcedures/${billId}`
    );
    console.log(response);
    setPreProcData(response?.data?.result);
  };

  const getAllProcList = async () => {
    try {
      const response = await axios.get(
        "https://test-cghs.doaguru.com/api/v1/auth/getAllIPDProcedures"
      );
      console.log(response.data);
      setProcDataList(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectProcedure = (index, selectedProcedure) => {
    const newProcedures = [...procData.procedures];

    newProcedures[index].procedure = selectedProcedure;

    const matchedItem = procDataList.result?.find(
      (item) => item.procedure_name == newProcedures[index].procedure
    );
    console.log(newProcedures);

    const price = matchedItem
      ? user.type === "NABH"
        ? matchedItem.nabh_amount
        : matchedItem.non_nabh_amount
      : null; // or any default value you prefer
    console.log(price);
    newProcedures[index]["price"] = price;

    setProcData((prevData) => ({
      ...prevData,
      procedures: newProcedures,
    }));
    // Hide suggestions after selection
    setShowSuggestions(false);
  };

  const Suggestions = ({
    filteredSuggestions,
    onSelect,
    maxSuggestions,
    showSuggestions,
    setShowSuggestions,
  }) => {
    const handleSelect = (suggestion) => {
      onSelect(suggestion);
      setShowSuggestions(false); // Hide suggestions after selecting an item
    };

    return (
      <ul>
        {showSuggestions &&
          filteredSuggestions
            .slice(0, maxSuggestions)
            .map((suggestion, index) => (
              <li
                key={index}
                onClick={() => handleSelect(suggestion)}
                className="shadow p-3"
              >
                {suggestion}
              </li>
            ))}
      </ul>
    );
  };
  const procedureHandler = async (e) => {
    e.preventDefault();
    console.log(procData);
    try {
      const proceduresCopy = [...procData.procedures];
      const response = await axios.put(
        `https://test-cghs.doaguru.com/api/v1/auth/editIpdProcedure/${billId}`,
        proceduresCopy
      );
      console.log(response);
      if (response?.data?.success) {
        cogoToast.success("Procedure Edited Successfully");
        navigate(`/FinalBillIPD/${billId}`);
      }
    } catch (error) {
      console.log(error);
      cogoToast.error("Something wents wrong");
    }
  };
  const procedureNames = procDataList.result?.map(
    (item) => item.procedure_name
  );
  const handleRemoveProcedure = (index) => {
    if (procData.procedures && procData.procedures.length > 1) {
      const newProcedures = [...procData.procedures];
      newProcedures.splice(index, 1);
      setProcData((prevData) => ({
        ...prevData,
        procedures: newProcedures,
      }));
    }
  };
  const handleAddProcedure = () => {
    setProcData((prevData) => ({
      ...prevData,
      procedures: [
        ...prevData.procedures,
        { procedure: "", quantity: "", price: "" },
      ],
    }));
  };
  const handleChangePrice = (index, key, value) => {
    const newProcedures = [...procData.procedures];
    newProcedures[index][key] = value;
    setProcData((prevData) => ({
      ...prevData,
      procedures: newProcedures,
    }));
  };
  const handleChangeQuantity = (index, key, value) => {
    const newProcedures = [...procData.procedures];
    newProcedures[index][key] = value;
    setProcData((prevData) => ({
      ...prevData,
      procedures: newProcedures,
    }));
  };
  const handleChangeProcedure = (index, key, value) => {
    const newProcedures = [...procData.procedures];
    newProcedures[index][key] = value;

    setProcData((prevData) => ({
      ...prevData,
      procedures: newProcedures,
    }));

    // Set the currently focused input index
    setFocusedInputIndex(index);

    const userInput = value.toLowerCase();
    const filtered = procedureNames.filter((name) =>
      name.toLowerCase().includes(userInput)
    );
    setFilteredSuggestions(filtered);
    setShowSuggestions(filtered.length > 0);
  };
  useEffect(() => {
    if (preProcData) {
      const extractedData = preProcData.map((item, index) => {
        const matchedItem = procDataList.result?.find(
          (procItem) =>
            procItem.procedure_name.trim() === item.procedure_name.trim()
        );

        const price = matchedItem
          ? user.type === "NABH"
            ? matchedItem.nabh_amount
            : matchedItem.non_nabh_amount
          : null;

        return {
          procedure: item.procedure_name,
          quantity: item.quantity,
          price: item.price ? item.price : price,
        };
      });

      setProcData({ procedures: extractedData });
    }
  }, [preProcData]);

  useEffect(() => {
    getAllProcList();
    console.log(prevData);
    if (prevData) {
      setPatientData({
        reg_number: prevData?.reg_number || "",
        ipd_number: prevData?.ipd_number || "",
        bill_number: prevData?.bill_number || "",
        patient_name: prevData?.patient_name || "",
        mobile_no: prevData?.mobile_no || "",
        wife_of_or_son_of: prevData.gender == "Male"? prevData.son_of : prevData.wife_of,
        age: prevData?.age || "",
        gender: prevData?.gender || "",

        // Inline date parsing
        bill_date: prevData?.bill_date ? new Date(prevData.bill_date) : null,
        treatment_date: prevData?.treatment_date
          ? new Date(prevData.treatment_date)
          : null,
        dischargeDate: prevData?.discharge_date
          ? new Date(prevData.discharge_date)
          : null,

        address: prevData?.address || "",
        doctor_name: prevData?.doctor_name || "",
        beneficiary_id: prevData?.beneficiary_id || "",
        bill_prepared_by: prevData?.bill_prepared_by || "",
        department: prevData?.department || "",
        condition_of_admission: prevData?.condition_of_admission || "",
        investigation: prevData?.investigation || "",
        card_token_no: prevData?.card_token_no || "",
        ward_bed_no: prevData?.ward_bed_no || "",
        patientCategory: prevData?.patientCategory || "",
        case_number: prevData?.case_number || "",
        cr_number: prevData?.cr_number || "",
        hospital_id: user?.id || "",
        hospital_name: user?.name || "",
        hospital_type: user?.type || "",
      });
    }
  }, [prevData]);

  useEffect(() => {
    // console.log('pahle ')
    getProcedure();
    getAllProcList();
    getBill();
    setPatientData((prevData) => ({
      ...prevData,
    }));
  }, []);
  return (
    <>
      <Container>
        <div className="container-fluid bgcolor min-vh-100">
          <div className="row">
            {toggle && (
              <div className="col-2 bg-dark vh-100 position-fixed">
                <Sidebar />
              </div>
            )}
            {toggle && <div className="col-2"></div>}
            <div className="col mrg">
              <NavDash Toggle={Toggle} />
              <div className="container mt-5 pb-5">
                <h3 className="text-center fw-bolder pb-4">
                  Edit Pateint Details for <b>IPD </b>{" "}
                </h3>
                {showProcedure === false ? (
                  <form onSubmit={createBillHandler}>
                     <div className="boxfirst rounded bg-white p-3">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                <div className="mb-3">
                  <label for="patientCategory" className="form-label fw-bold">
                    Patient Category
                  </label>
                  <select
                    autoComplete="off"
                    className="form-control"
                    id="email"
                    name="patientCategory"
                    value={patientData.patientCategory}
                    onChange={handleChange}
                    required
                  >
                    <option value="" disabled>
                      --select--
                    </option>
                    <option value="CGHS">CGHS</option>
                    <option value="CSMA">CSMA</option>
                    <option value="ESIS">ESIS</option>
                    <option value="ESE">ESE</option>
                    <option value="BSNM">BSNL</option>
                    <option value="RAILWAY">RAILWAY</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                <div className="mb-3">
                  <label for="reg-number" className="form-label fw-bold">
                    Registration Number
                  </label>
                  <input
                    type="number"
                    autoComplete="off"
                    className="form-control"
                    placeholder="registeration number"
                    name="reg_number"
                    value={patientData.reg_number}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                <div className="mb-3">
                  <label htmlFor="mobile_no" className="form-label fw-bold">
                    Mobile Number
                  </label>
                  <input
                    type="number"
                    autoComplete="off"
                    className="form-control"
                    placeholder="Enter 10-digit mobile number"
                    pattern="[0-9]{10}"
                    title="Mobile number should be 10 digits"
                    maxLength={10}
                    minLength={10}
                    required
                    name="mobile_no"
                    value={patientData.mobile_no}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                <div className="mb-3">
                  <label htmlFor="wife_of_or_son_of" className="form-label fw-bold">
                    Wife Of / Son Of
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    placeholder="Name of Guardian"
                    required
                    name="wife_of_or_son_of"
                    value={patientData.wife_of_or_son_of}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-12 col-12">
                <div className="mb-3">
                  <label for="opd-number" className="form-label fw-bold">
                    IPD Number
                  </label>
                  <input
                    type="number"
                    autoComplete="off"
                    className="form-control"
                    placeholder="ipd-number"
                    name="ipd_number"
                    value={patientData.ipd_number}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-12 col-12">
                <div className="mb-3">
                  <label for="bill-number" className="form-label fw-bold">
                    Bill Number
                  </label>
                  <input
                    type="number"
                    autoComplete="off"
                    className="form-control"
                    placeholder="bill-number"
                    required
                    name="bill_number"
                    value={patientData.bill_number}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-12 col-12">
                <div className="mb-3">
                  <label for="bill-number" className="form-label fw-bold">
                    Case Number
                  </label>
                  <input
                    type="number"
                    autoComplete="off"
                    className="form-control"
                    placeholder="Case Number"
                    required
                    name="case_number"
                    value={patientData.case_number}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-12 col-12">
                <div className="mb-3">
                  <label for="bill-number" className="form-label fw-bold">
                    Cr. Number
                  </label>
                  <input
                    type="number"
                    autoComplete="off"
                    className="form-control"
                    placeholder="Case Number"
                    required
                    name="cr_number"
                    value={patientData.cr_number}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                <div className="mb-3">
                  <label for="Pateint-Name" className="form-label fw-bold">
                    Pateint Name
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    placeholder="Pateint-Name"
                    required
                    name="patient_name"
                    value={patientData.patient_name}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-1 col-md-2 col-sm-12 col-12">
                <div className="mb-3">
                  <label for="age" className="form-label fw-bold">
                    Age
                  </label>
                  <input
                    type="number"
                    autoComplete="off"
                    className="form-control"
                    placeholder="enter age"
                    required
                    name="age"
                    value={patientData.age}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-12 col-12">
                <div className="mb-3">
                  <label for="gender" className="form-label fw-bold">
                    Gender
                  </label>
                  <select
                    className="form-control"
                    autoComplete="off"
                    value={patientData.gender}
                    onChange={handleChange}
                    id="gender"
                    name="gender"
                    required
                  >
                    <option>--select--</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                <div className="mb-3">
                  <label for="bill-date" className="form-label fw-bold">
                    Bill Date
                  </label>
                  <div className="">
                    <DatePicker
                      className="form-control w-full"
                      onChange={(date) => handleDateChange("bill_date", date)}
                      required
                      dateFormat="dd-MM-yyyy"
                      placeholderText="Select a date"
                      selected={patientData.bill_date}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                <div className="mb-3">
                  <label for="address" className="form-label fw-bold">
                    Address
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    placeholder="Enter your Address"
                    name="address"
                    value={patientData.address}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                <div className="mb-3">
                  <label for="Beneficiary_id" className="form-label fw-bold">
                    Beneficiary Id
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    name="beneficiary_id"
                    placeholder="Beneficiary Id"
                    required
                    value={patientData.beneficiary_id}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                <div className="mb-3">
                  <label for="Date-treatment" className="form-label fw-bold">
                    Admission Date
                  </label>
                  <div className="">
                    <DatePicker
                      selected={patientData.treatment_date}
                      name="treatment_date"
                      onChange={(date) =>
                        handleDateChange("treatment_date", date)
                      }
                      className="form-control"
                      dateFormat="dd-MM-yyyy hh:mm aa"
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      required
                      placeholderText="Select a date and time"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                <div className="mb-3">
                  <label for="doctor_name" className="form-label fw-bold">
                    Doctor Name
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    placeholder="Doctor Name"
                    name="doctor_name"
                    required
                    value={patientData.doctor_name}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                <div className="mb-3">
                  <label for="bill_prepared_by" className="form-label fw-bold">
                    Bill Prepared By
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    placeholder="Bill Prepared By"
                    required
                    name="bill_prepared_by"
                    value={patientData.bill_prepared_by}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                <div className="mb-3">
                  <label for="department" className="form-label fw-bold">
                    Department
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    placeholder="Department "
                    required
                    name="department"
                    value={patientData.department}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                <div className="mb-3">
                  <label
                    for="condition_of_admission"
                    className="form-label fw-bold"
                  >
                    Condition Of Admission
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    placeholder="Condition Of Admission "
                    required
                    name="condition_of_admission"
                    value={patientData.condition_of_admission}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                <div className="mb-3">
                  <label for="Investigation" className="form-label fw-bold">
                    Investigation
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    placeholder="Investigation "
                    required
                    name="investigation"
                    value={patientData.investigation}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                <div className="mb-3">
                  <label for="card_token_no" className="form-label fw-bold">
                    Card/Token No.
                  </label>
                  <input
                    type="number"
                    autoComplete="off"
                    className="form-control"
                    placeholder="Card/Token no."
                    required
                    name="card_token_no"
                    value={patientData.card_token_no}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                <div className="mb-3">
                  <label for="ward_bed_no" className="form-label fw-bold">
                    Ward/Bed No.
                  </label>
                  <input
                    type="number"
                    autoComplete="off"
                    className="form-control"
                    placeholder="Ward/Bed No."
                    required
                    name="ward_bed_no"
                    value={patientData.ward_bed_no}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                <div className="mb-3">
                  <label for="dischargeDate" className="form-label fw-bold">
                    Discharge Date
                  </label>
                  <DatePicker
                    className="form-control"
                    dateFormat="dd-MM-yyyy h:mm aa"
                    placeholderText="Select a date and time"
                    selected={patientData.dischargeDate}
                    onChange={(date) => handleDateChange("dischargeDate", date)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="Time"
                  />
                </div>
              </div>
            </div>
            <div>
              <button type="submit" className="btn btn-primary">
                Update
              </button>
            </div>
          </div>
                  </form>
                ) : (
                  <>
                    <form onSubmit={procedureHandler}>
                      <h4 className="mt-2 mb-2">Add Procedure Details</h4>
                      <div className="boxsecond rounded bg-white p-3">
                        <div className="boxsecond rounded bg-white p-3">
                          {procData?.procedures?.map((procedure, index) => (
                            <div className="row" key={index}>
                              <div className="col-lg-5 col-md-5 col-sm-12 col-12">
                                <div className="mb-3">
                                  <label
                                    htmlFor={`Procedure-${index}`}
                                    className="form-label fw-bold"
                                  >
                                    Select Procedure
                                  </label>
                                  <input
                                    type="search"
                                    className="form-control"
                                    id={`Procedure-${index}`}
                                    placeholder="Select Procedure"
                                    value={procedure.procedure}
                                    required
                                    onChange={(e) => {
                                      handleChangeProcedure(
                                        index,
                                        "procedure",
                                        e.target.value
                                      );
                                      // Show suggestions when there is some input
                                      setShowSuggestions(
                                        e.target.value.trim().length > 0
                                      );
                                    }}
                                  />
                                  <Suggestions
                                    filteredSuggestions={filteredSuggestions}
                                    onSelect={(selectedProcedure) =>
                                      handleSelectProcedure(
                                        focusedInputIndex,
                                        selectedProcedure
                                      )
                                    }
                                    maxSuggestions={5}
                                    showSuggestions={
                                      showSuggestions &&
                                      focusedInputIndex == index
                                    }
                                    setShowSuggestions={setShowSuggestions}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                <div className="mb-3">
                                  <label
                                    htmlFor={`Procedure-price-${index}`}
                                    className="form-label fw-bold"
                                  >
                                    Procedure Price
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id={`Procedure-price-${index}`}
                                    placeholder="Enter Procedure Price"
                                    value={procedure.price}
                                    required
                                    onChange={(e) =>
                                      handleChangePrice(
                                        index,
                                        "price",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                <div className="mb-3">
                                  <label
                                    htmlFor={`Procedure-Quantity-${index}`}
                                    className="form-label fw-bold"
                                  >
                                    Procedure Quantity
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id={`Procedure-Quantity-${index}`}
                                    placeholder="Enter Procedure Quantity"
                                    value={procedure.quantity}
                                    required
                                    onChange={(e) =>
                                      handleChangeQuantity(
                                        index,
                                        "quantity",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-lg-1 col-md-1 col-sm-12 col-12 divsettle">
                                <button
                                  className="btn btn-success"
                                  type="button"
                                  onClick={() => handleAddProcedure()}
                                  style={{
                                    display:
                                      index === procData.procedures.length - 1
                                        ? "block"
                                        : "none",
                                  }}
                                >
                                  +
                                </button>
                                {index > 0 && (
                                  <button
                                    className="btn btn-danger"
                                    type="button"
                                    onClick={() => handleRemoveProcedure(index)}
                                  >
                                    x
                                  </button>
                                )}
                              </div>
                              <div className="col-lg-1 col-md-1 col-sm-12 col-12 divsettle"></div>
                            </div>
                          ))}
                        </div>

                        <div>
                          <button type="submit" class="btn btn-primary">
                            Update
                          </button>
                        </div>
                      </div>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};
export default EditPatient;
const Container = styled.div`
  .bgcolor {
    background-color: #80bef5;
  }

  .divsettle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.8rem;
  }

  li {
    list-style: none;
  }
  input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
`;

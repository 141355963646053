import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";

const defaulData = {
  patient_name : "Ranveta Parasite", 
  cr_number : "62015", 
}

function PomTs() {
    const navigate = useNavigate();
  const params = useParams();


  const [patientDetails, setPatientDetails] = useState(defaulData);


  const firstTableRow = ['Tab. Acetazolamide 250 mg', 'Nepafenac Eye Drops', 'Pilocar 2%/4% Eye Drop', 'Moxifloxacin Eye Drop', 'Tropicamide0.8% w/v+', 'Phenylephrine 5% w/v Eye Drop', 'Tropicamide 0.8% w/v Eye Drop', 'Homatropine 2% Eye Drop'];

  const tests = ["Blood Sugar", "HIV (Screening Test)","HBsAg (Screening Test)", "Conjuctival mear"];

  const generateDots = (length) => '.'.repeat(length);
  const fetching = async () => {
    try {
      // Fetch bill details
      const res = await axios.get(`https://test-cghs.doaguru.com/api/v1/auth/getIPDBill/${params.billId}`);
      setPatientDetails(res.data);

      console.log(res);

    } catch (error) {
      console.error('Error fetching data:', error);
      
    }
  };

  useEffect(() => {
    fetching();
  }, []);
  return (
    <>
      <div className='py-5'>
        <div className="p-3 px-5">
        <div className="d-flex justify-content-between ">
          <p>
            Patient Name: <span>{patientDetails.patient_name}</span>
          </p>
          <p>
            CR No.: <span>{patientDetails?.cr_number || "NA"}</span>
          </p>
        </div>
          <div className="text-center ">
          <p className="text-decoration-underline fw-bold fs-3">PRE OPERATIVE MEDICATION TREATMENT SHEET</p>
        </div>
        <table className='table table-bordered'>
          <thead>
            <tr>
              <th style={{fontSize: "14px"}}>Medicine Name</th>
              {
                Array.from({length: 3}, (_, idx) =>   <th style={{fontSize: "14px"}} className='text-center'>Time/Signature</th>)
              }
            </tr>
          </thead>
          <tbody>
            {
              firstTableRow.map((item) => 
                <>
                  <tr>
                      <td style={{fontSize: "14px"}}>{item}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                  </tr>
                </>
              )
            }
          </tbody>
        </table>
        <div className="text-center mb-4">
          <p className="fw-bold fs-5">Medicine to be given during emergency</p>
        </div>
        <table  className='table table-bordered'>
          <tbody>
            {Array.from({length: 2}, (_, idx) =>  
            <>
              <tr>
                <td>{idx+1} .</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </>
          )}
          </tbody>
        </table>
        <div>
            <div>
                <p className="mb-1">Name of Nurse: <span className='px-2'>{generateDots(40)}</span></p>
            </div>
            <div>
                <p className="mb-1">Signature <span className='px-2'>{generateDots(40)}</span></p>
            </div>
            <div>
                <p className="mb-1">Date/Time<span className='px-2'>{generateDots(40)}</span></p>
            </div>
        </div>
        <div className="text-center mb-4">
          <p className="text-decoration-underline fw-bold fs-3">BLOOD CHEMISTRY</p>
        </div>
          <div className='d-flex gap-5'>
            <table  className='table table-bordered'>
              <tbody>
                {tests.map((item) =>  
                <>
                  <tr>
                    <td className='w-50'>{item}</td>
                    <td></td>
                  </tr>
                </>
              )}
              </tbody>
            </table>
            <table  className='table table-bordered'>
              <thead>
                <tr className='text-center'>
                  <th colSpan="2">Other</th>
                </tr>
              </thead>
              <tbody>
                {
                  Array.from({length: 3}, (_, idx) =>  <tr>
                  <td></td>
                  <td></td>
                </tr>)
                }
              </tbody>
            </table>
          </div>
        </div>
        <div className='d-flex justify-content-center my-3 gap-2'>
                  <button type="button" className=" d-print-none btn btn-primary btn-lg" onClick={() => window.print()}>Print</button>
                  <button type="button" className=" d-print-none btn btn-primary btn-lg" onClick={()=>navigate('/admin-dashboard')}>Go to Dashboard</button>
                  </div>
      </div>  
    </>
  );
}

export default PomTs;

// reducers/userReducer.js
import { createReducer } from "@reduxjs/toolkit";
import { setUser, clearUser } from "../slices/UserSlices";

// Initial state for the user
const initialState = { name: "", id: null , type:"" ,address:"",contact:"",email: "", bank_name: "", ifsc_code: "", account_no: "", br_micr_code: "" };

// Create a reducer using createReducer from Redux Toolkit
export const userReducer = createReducer(initialState, {
  [setUser]: (state, action) => {
    state.name = action.payload.name;
    state.id = action.payload.id;
    state.type = action.payload.type;
    state.address = action.payload.address;
    state.email = action.payload.email;
  state.contact = action.payload.contact;
  state.bank_name = action.payload.bank_name;
  state.account_no = action.payload.account_no;
  state.ifsc_code = action.payload.ifsc_code;
  state.br_micr_code = action.payload.br_micr_code;
  },
  [clearUser]: (state) => {
    state.name = "";
    state.id = null;
    state.type = "";
    state.address = "";
    state.contact = "";
    state.email = "";
    state.bank_name = "";
    state.ifsc_code = "";
    state.br_micr_code = "";
    state.state.account_no = "";
  },
});

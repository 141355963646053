import styled from "styled-components";
import { Await, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

const defaultData = {};

const patientDetailsTable = [
  { label: "UHID", value: "bill_number" },
  { label: "SERIAL NO.", value: "ipd_number" },
  { label: "PATIENT NAME", value: "patient_name" },
  { label: "W/O", value: "wife_of" },
  { label: "WARD / BED", value: "ward_bed_no" },
  { label: "MOBILE", value: "mobile_no" },
  { label: "CATEGORY", value: "patientCategory" },
  { label: "AGE / GENDER", value: "age" },
  { label: "ADM.DATE", value: "bill_date" },
  { label: "DIS.DATE", value: "discharge_date" },
  { label: "ADDRESS", value: "address" },
  { label: "CONSULTANTS", value: "doctor_name" },
];
const admissionExamination = [
  { label: "TEMP", value: "98.2", unit: "F" },       // Fahrenheit
  { label: "PULSE", value: "72", unit: "bpm" },      // Beats per minute
  { label: "BP", value: "120/80", unit: "mmHg" },    // Millimeters of mercury
  { label: "PR", value: "80", unit: "bpm" },         // Beats per minute
  { label: "SPO2", value: "98", unit: "%" },         // Percentage
  { label: "GCS", value: "15", unit: "" },           // Glasgow Coma Scale score, no unit
  { label: "RS", value: "Normal", unit: "" },        // No unit
  { label: "CNS", value: "Normal", unit: "" },       // No unit
  { label: "CHEST", value: "Clear", unit: "" },      // No unit
  { label: "PA", value: "Normal", unit: "" },        // No unit
  { label: "CVS", value: "Normal", unit: "" },       // No unit
  { label: "RR", value: "18", unit: "breaths/min" }, // Breaths per minute
  { label: "REMARK", value: "No remarks", unit: "" } // No unit
];

const dischargeExamination = [
  { viewLabel: "TEMP", label: "discharge_TEMP", value: "98.2", unit: "F" },     // Fahrenheit
  { viewLabel: "PULSE", label: "discharge_PULSE", value: "72", unit: "bpm" },   // Beats per minute
  { viewLabel: "BP", label: "discharge_BP", value: "120/80", unit: "mmHg" },    // Millimeters of mercury
  { viewLabel: "PR", label: "discharge_PR", value: "80", unit: "bpm" },         // Beats per minute
  { viewLabel: "SPO2", label: "discharge_SPO2", value: "98", unit: "%" },       // Percentage
  { viewLabel: "GCS", label: "discharge_GCS", value: "15", unit: "" }           // No unit, Glasgow Coma Scale score
];



const DischargesSummary = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const [patientDetails, setPatientDetails] = useState(defaultData);
  const [hospitalDoc, setHospitalDoc] = useState([]);
  const { billId } = useParams();

  const displayDocHospital = async () => {
    console.log(user.id);
    try {
      const viewDoc = await axios.get(
        `https://test-cghs.doaguru.com/api/v1/auth/displayHospitalDoc/${user.id}`
      );

      console.log(viewDoc.data);
      setHospitalDoc(viewDoc.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getData = async () => {
    try {
      const [dischargeResponse, examinationResponse] = await Promise.all([
        axios.get(
          `https://test-cghs.doaguru.com/api/v1/auth/getDischargeSummary/${billId}`
        ),
        axios.get(
          `https://test-cghs.doaguru.com/api/v1/auth/getExaminationDataOfPatient/${billId}`
        ),
      ]);


      console.log(dischargeResponse.data);
      let hospitalNumber = "NA"
      if(dischargeResponse.data) {
        const hospitalDataResponse = await axios.get(`https://test-cghs.doaguru.com/api/v1/auth/getAllIPDPatientBill/${dischargeResponse.data.dischargesummarydetails[0].hospital_id}`)
        hospitalNumber = hospitalDataResponse.data[0].contact;
      }
      const patientInfo = dischargeResponse.data.dischargesummarydetails[0];

      for (let obj in patientInfo) {
        if (patientInfo[obj] && patientInfo[obj][0] === "{") {
          let arr = [];
          let jsonParse = JSON.parse(patientInfo[obj]);
          for (let innerObj in jsonParse) {
            arr.push(jsonParse[innerObj]);
          }
          patientInfo[obj] = arr;
        }
      }

      const admissionData =
        examinationResponse.data.admissionData.find(
          (item) => item.type === "admission"
        ) || {};
      const dischargeExaminationData =
        examinationResponse.data.dischargeData.find(
          (item) => item.type === "discharge"
        ) || {};

      const examinationDataProcessed = {
        TEMP: "",
        PULSE: "",
        BP: "",
        SPO2: "",
        GCS: "",
        CVS: "",
        RS: "",
        CHEST: "",
        RR: "",
        CNS: "",
        PA: "",
        REMARK: "",
        discharge_TEMP: "",
        discharge_PULSE: "",
        discharge_BP: "",
        discharge_SPO2: "",
        discharge_GCS: "",
        discharge_CVS: "",
        discharge_RS: "",
        discharge_CHEST: "",
        discharge_RR: "",
        discharge_CNS: "",
        discharge_PA: "",
        discharge_REMARK: "",
      };

      // Populate admission data
      for (const key in admissionData) {
        if (examinationDataProcessed.hasOwnProperty(key.toUpperCase())) {
          examinationDataProcessed[key.toUpperCase()] = admissionData[key];
        }
      }

      // Populate discharge data
      for (const key in dischargeExaminationData) {
        if (
          examinationDataProcessed.hasOwnProperty(
            `discharge_${key.toUpperCase()}`
          )
        ) {
          examinationDataProcessed[`discharge_${key.toUpperCase()}`] =
            dischargeExaminationData[key];
        }
      }

      // Combine both data sets
      const combinedData = {
        ...patientInfo,
        ...examinationDataProcessed,
      };

      console.log(combinedData);
      let mappedData = {
        ...combinedData,
        age: `${patientInfo.age}/${patientInfo.gender === "Male" ? "M" : "F"}`,
        bill_date: new Date(patientInfo.bill_date).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true, // Enables AM/PM format
        }),
        treatment_date: new Date(patientInfo.treatment_date).toLocaleTimeString(
          [],
          {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true, // Enables AM/PM format
          }
        ),
        discharge_date: new Date(patientInfo.discharge_date).toLocaleTimeString(
          [],
          {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true, // Enables AM/PM format
          }
        ),
      };
      console.log(mappedData);
      setPatientDetails({...mappedData, contact: hospitalNumber});
    } catch (error) {
      console.error("Error : ", error);
    }
  };

  function extractElements(input) {
    // Remove the square brackets and split by comma
    const strWithoutBrackets = input.replace(/^\[|\]$/g, "");

    // Step 2: Split the string by commas
    const parts = strWithoutBrackets.split(/,\s*(?![^()]*\))/);

    // Step 3: Remove single quotes from each part
    const cleanedParts = parts.map((part) => part.replace(/^'|'$/g, ""));

    console.log(cleanedParts);
    return cleanedParts;
  }

  const moreSentances = (text) => {
    let para = [];
    if (text && text.length > 40) {
      if (text.includes(",")) {
        para = text
          .split(",")
          .map((item, index) => <div key={index}>{item.trim()}</div>);
      }
      return para;
    }
    return <div>{text}</div>;
  };
  useEffect(() => {
    getData();
    displayDocHospital();
  }, []);

  return (
    <>
      <Component>
        <div className="px-5">
          <div>
            <img
              src={hospitalDoc?.header_img} 
              alt=""
              width="100%"
            />
          </div>
          <div>
            <h3 className="text-decoration-underline text-center">
              DISCHARGE SUMMARY
            </h3>
          </div>
          {/* Info Box */}
          <div>
            <table className="w-100 border border-1 border-dark-subtle border-end-0">
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="">
                <tr>
                  <td colSpan={2}>
                    <p className="mb-4"></p>
                  </td>
                </tr>
                {patientDetailsTable.map((prop, index) =>
                  index % 2 === 0 ? (
                    <tr key={index}>
                      <td>
                        <div className="ps-4 d-flex">
                          <p className="font-14 m-0" style={{ width: "100px" }}>
                            {prop.label}:
                          </p>
                          <p
                            style={{ width: "250px" }}
                            className="fw-bold font-14 ps-3 text-break"
                          >
                            {/* dffsadfsaddsfdsfds */}
                            {patientDetails[prop.value] || "NA"}
                            {/* kkflkdsjkkfkflkdsjk */}
                          </p>
                        </div>
                      </td>
                      {patientDetailsTable[index + 1] && (
                        <td>
                          <div className="pe-4 ps-2 d-flex">
                            <p
                              className="font-14 m-0"
                              style={{ width: "100px" }}
                            >
                              {patientDetailsTable[index + 1].label !== "W/O" ? patientDetailsTable[index + 1].label : patientDetails[patientDetailsTable[index + 1].value] ? patientDetailsTable[index + 1].label : "S/O"}:
                            </p>
                            <p
                              style={{ width: "250px" }}
                              className="fw-bold ps-3 text-break font-14"
                            >
                               {patientDetailsTable[index + 1].label !== "W/O" ?
                                patientDetails[patientDetailsTable[index + 1].value] : 
                                patientDetails[patientDetailsTable[index + 1].value] ? 
                                patientDetails[patientDetailsTable[index + 1].value] || "NA" : 
                                patientDetails.son_of || "NA"}
                              {/* {patientDetails[patientDetailsTable[index + 1].value] || "NA"} */}
                              {/* ;dffgjkgl; */}
                            </p>
                          </div>
                        </td>
                      )}
                    </tr>
                  ) : null
                )}
              </tbody>
            </table>
          </div>
          {/* main content */}
          <div className="d-flex flex-column gap-3 mt-3">
            <div>
              <span className="text-decoration-underline font-12 ">
                DISCHARGE TYPE :
              </span>
              <span className="font-14 fw-bolder ms-3">
                {patientDetails?.discharge_type || "NA"}
              </span>
            </div>
            <div>
              <p className="text-decoration-underline font-12  m-0">
                DIAGNOSIS :
              </p>
              {patientDetails?.diagnosis?.map((item) => (
                <p className="font-14 fw-bolder ms-3">{item.name}</p>
              )) || <p className="font-14 fw-bolder ms-3">NA</p>}
            </div>
            {/* <div>
              <p className="text-decoration-underline font-12  m-0">
                MEDICAL HISTORY & PRESENTING COMPLIANTS (REASON FOR ADMISSION) :
              </p>
              {patientDetails?.condition_of_admission?.map((item) => (
                <p className="font-14 fw-bolder ms-3">{item}</p>
              )) || (
                <p className="fw-bolder ms-3 font-14">
                  C/O - PATIENT ADMITTED WITH COMPLAINTS OF PAIN AND BURNING IN
                  ANAL REASON.
                </p>
              )}
            </div> */}
            <div>
              <p className="text-decoration-underline font-12  m-0">
                MEDICAL HISTORY & PRESENTING COMPLIANTS (REASON FOR ADMISSION) :
              </p>
              {(
                <p className="font-14 fw-bolder ms-3">
                  {patientDetails?.condition_of_admission}
                </p>
              ) || (
                <p className="fw-bolder ms-3 font-14">
                  C/O - PATIENT ADMITTED WITH COMPLAINTS OF PAIN AND BURNING IN
                  ANAL REASON.
                </p>
              )}
            </div>
            <div>
              <p className="text-decoration-underline font-12  m-0">
                PAST MEDICAL
              </p>
              {(
                <p className="font-14 fw-bolder ms-3">
                  {patientDetails?.past_medical}
                </p>
              ) || <p className="font-14 fw-bolder ms-3">NA</p>}
            </div>
            <div>
              <p className="text-decoration-underline font-12  m-0">
                POST OP. SURGERY
              </p>
              {(
                <p className="font-14 fw-bolder ms-3">
                  {patientDetails?.post_operation}
                </p>
              ) || <p className="font-14 fw-bolder ms-3">NO.</p>}
            </div>
            <div>
              <p className="text-decoration-underline font-12 ">
                PHYSICAL & SYSTEMIC EXAMINATION (on admission) :
              </p>
              <div>
                <div>
                  <div className="d-flex pe-5 pe-5">
                    {/* <ul className="w-100 font-14 pe-5 me-5">
                      {healthData.map((item, index) => (
                        <li
                          className="d-flex justify-content-between mb-3"
                          key={index}
                        >
                          <span className="fw-bold">{item.label}:</span>{" "}
                          <span>{item.value}</span>
                        </li>
                      ))}
                    </ul> */}
                    <ul className="w-100 font-14 pe-5 me-5">
  {/* Group the items in pairs using reduce */}
  {admissionExamination.reduce((acc, item, index) => {
    if (index % 2 === 0) {
      // Start a new row for every two items
      acc.push([item]);
    } else {
      // Push the second item in the current row
      acc[acc.length - 1].push(item);
    }
    return acc;
  }, []).map((pair, pairIndex) => (
    <div className="d-flex justify-content-between mb-3" key={pairIndex}>
      {pair.map((item, index) => (
        <li className="d-flex justify-content-between mb-3 me-5" key={index}>
          <div className="d-flex justify-content-between" style={{width: "100px"}}>
            <span className="fw-bold">{`${item.label} ${item.unit}`}:</span>
            <span>{patientDetails[item.label] || "1"}</span>
          </div>
        </li>
      ))}
    </div>
  ))}
</ul>

                  </div>
                </div>
              </div>
            </div>
            <div>
              <p className="font-14 fw-bold m-0 pb-1 text-decoration-underline">
                HOSPITAL COURSE:
              </p>
              <p className="font-14 ">
                {patientDetails?.hospital_course || "NA"}
              </p>
            </div>
            <div>
              <p className="text-decoration-underline font-12 ">
                BLOOD TRANSFUTION :
              </p>
              {/* {patientDetails?.disgnosis?.map((item) => (
                <p className="font-14 fw-bolder">{item}</p>
              )) || <p className="font-14 fw-bolder">NO</p>} */}
              {(
                <p className="font-14 fw-bolder">
                  {patientDetails?.blood_transfusion}
                </p>
              ) || <p className="font-14 fw-bolder">NO</p>}
            </div>
            <div>
              <p className="text-decoration-underline font-12 ">
                SURGICAL PROCEDURE :
              </p>
              {Array.isArray(patientDetails.surgical_Procedure) ? (
                <>
                  <p className="font-14 fw-bolder m-0">Yes</p>
                  {/* {patientDetails.surgical_Procedure.map((item) => (
                    <p className="font-14 fw-bolder m-0">{item}</p>
                  ))} */}
                  <div>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>SURGERY</th>
                          <th>DATE</th>
                          <th>SURGEON</th>
                          <th>ANAESTHESIA</th>
                        </tr>
                      </thead>
                      <tbody>
                        {patientDetails.surgical_Procedure.map(
                          (item, index) => (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{item.surgeryName}</td>
                              <td>{item.surgeryDate}</td>
                              <td>{item.surgeon}</td>
                              <td>{item.anaesthesia}</td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <>
                  <p className="font-14 fw-bolder m-0">No</p>
                  <div>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>SURGERY</th>
                          <th>DATE</th>
                          <th>SURGEON</th>
                          <th>ANAESTHESIA</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>NA</td>
                          <td>NA</td>
                          <td>NA</td>
                          <td>NA</td>
                          <td>NA</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </div>
            <div>
              <p className="text-decoration-underline fw-bolder font-12  m-0">
                INVESTIGATIONS :
              </p>
              {patientDetails?.investigation ? (
                <>
                  <p className="font-14 m-0"> Yes</p>
                  <p className="font-14 m-0">{patientDetails.investigation}</p>
                </>
              ) : (
                <>
                  <p className="font-14 m-0"> NA</p>
                </>
              )}
            </div>
            <div>
              <p className="text-decoration-underline fw-bolder font-12  m-0">
                OTHER PROCEDURE NOTE:
              </p>
              {patientDetails?.procedure_note ? (
                <>
                  <p className="font-14 m-0"> Yes</p>
                  <p className="font-14  m-0">
                    {patientDetails.procedure_note}
                  </p>
                </>
              ) : (
                <>
                  <p className="font-14 m-0"> NA</p>
                </>
              )}
            </div>
            <div>
              <p className="text-decoration-underline fw-bolder font-12">
                CONDITION ON DISCHARGE:
              </p>

              {dischargeExamination &&
              dischargeExamination.some(
                (item) => patientDetails[item.label]
              ) ? (
                <>
                  <p className="font-14 m-0">Yes</p>
                  <div>
                    <table className="table">
                      <thead>
                        <tr>
                          {dischargeExamination.map((item, index) => (
                            <th key={index}>{item.viewLabel}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {dischargeExamination.map((item, index) => (
                            <td key={index}>
                              {`${patientDetails[item.label]} ${item.unit}` || "NA"}
                            </td>
                          ))}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <>
                  <p className="font-14 m-0">NA</p>
                  {/* <div>
        <table className="table">
          <thead>
            <tr>
              {dischargeExamination.map((item, index) => (
                <th key={index}>{item.viewLabel}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {dischargeExamination.map((item, index) => (
                <td key={index}>NA</td>
              ))}
            </tr>
          </tbody>
        </table>
      </div> */}
                </>
              )}
            </div>

            <div>
              <p className="text-decoration-underline fw-bolder font-12 m-0">
                ADVICE ON DISCHARGE:
              </p>
              <div className="mb-1">
                {/* {patientDetails.discharge_advised.map((item, index) => (
                    <p key={index} className="font-14 fw-bolder m-0">
                      {item}
                    </p>
                  ))} */}
                {
                  <p className="font-14 m-0">
                    {patientDetails?.discharge_advice || "NA"}
                  </p>
                }
              </div>
              {/* <div className="mb-1">
                  <p className="font-14 fw-bolder m-0">
                    FULL DIET / REVIEW AFTER 7 DAYS.
                  </p>
                  <p className="font-14 fw-bolder m-0">
                    BED REST AND TAKE MEDICINE REGULARLY
                  </p>
                </div> */}
            </div>
            <div>
              <p className="text-decoration-underline font-12 m-0 fw-bold">
                AFTER DISCHARGE MEDICATIONS:
              </p>
              {/* {Array.isArray(patientDetails.treatment_Advised) &&
              patientDetails.treatment_Advised.length > 0 ? (
                <table className="table">
                  <thead>
                    <tr>
                      <th>S. No.</th>
                      <th>Medicine</th>
                      <th>Dosage</th>
                      <th>Frequency</th>
                      <th>Route</th>
                      <th>Instruction</th>
                      <th>Remark</th>
                    </tr>
                  </thead>
                  <tbody>
                    {patientDetails.treatment_Advised.map(
                      (medication, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{medication.medicine}</td>
                          <td>{medication.dosage}</td>
                          <td>{medication.frequency}</td>
                          <td>{medication.route}</td>
                          <td>{medication.instruction}</td>
                          <td>{medication.remark}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              )  */}
              {patientDetails?.discharge_medicine?.length > 0 ? (
                <table className="table">
                  <thead>
                    <tr>
                      <th>S. No.</th>
                      <th>Medicine</th>
                      <th>Dosage</th>
                      <th>Frequency</th>
                      <th>Route</th>
                      <th>Instruction</th>
                      <th>Remark</th>
                    </tr>
                  </thead>
                  <tbody>
                    {patientDetails.discharge_medicine.map(
                      (medication, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{medication.dischargeMedicine}</td>
                          <td>{medication.dosage}</td>
                          <td>{medication.freq}</td>
                          <td>{medication.route}</td>
                          <td>{medication.instruction}</td>
                          <td>{medication.remark}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              ) : (
                <p className="font-14 m-0">No medications prescribed.</p>
              )}
            </div>
            <div className="d-flex mt-3">
              <div className="text-decoration-underline fw-bolder font-12">
                FOLLOW UP & URGENT CARE:-
              </div>
              {patientDetails?.followUp_urgent ? (
                <div className="d-flex gap-5 ms-5 ps-5">
                  <span>{patientDetails.followUp_urgent}</span>
                </div>
              ) : (
                <div className="d-flex gap-5 ms-5 ps-5">
                  <span>NA</span>
                </div>
              )}
            </div>
            <div className="d-flex">
              <div className="text-decoration-underline fw-bolder font-12">
                FOLLOW UP ADVICE & VISIT -
              </div>
              {patientDetails?.followUp_advice ? (
                <div className="d-flex gap-5 ms-5 ps-5">
                  <span>{patientDetails.followUp_advice}</span>
                </div>
              ) : (
                <div className="d-flex gap-5 ms-5 ps-5">
                  <span>NA</span>
                </div>
              )}
            </div>
            <div className="d-flex mb-3">
              <div className="text-decoration-underline fw-bolder font-12">
                ANY OTHER BY CONSULTANT -
              </div>
              {/* {patientDetails?.consultant_note ? (
              <div className="d-flex gap-5 ms-5 ps-5">
                <span>{patientDetails.consultant_note}</span>
              </div>
            )  */}
              {patientDetails?.other_byConsultant ? (
                <div className="d-flex gap-5 ms-5 ps-5">
                  <span>{patientDetails.other_byConsultant}</span>
                </div>
              ) : (
                <div className="d-flex gap-5 ms-5 ps-5">
                 <span>NA</span>
                </div>
              )}
            </div>
            {/* {patientDetails?.urgentCareGuidelines ? (
              <div>
                <div className="text-decoration-underline fw-bolder font-14">
                  WHEN TO OBTAIN URGENT CARE
                </div>
                <p className="font-12 fw-bold">
                  {patientDetails.urgentCareGuidelines}
                </p>
              </div>
            ) : (
              <div>
                <div className="text-decoration-underline fw-bolder font-14">
                  WHEN TO OBTAIN URGENT CARE
                </div>
                <p className="font-12 fw-bold">
                  {patientDetails?.when_urgent_care || "SEVERE PAIN / ABDOMEN DISCOMFORT / VOMITING"}
                </p>
              </div>
            )} */}
            <div>
              <div className="text-decoration-underline fw-bolder font-14">
                HOW TO OBTAIN URGENT CARE
              </div>
              <p className="m-0 font-12">
                Please Contact the consultant, In case of any Emergency,
                Approach Casualty Medical Officer,
              </p>
              <p className="m-0 font-12">
                Life Medicity Hospital Jabalpur, Emergency Number{" "}
                <span className="fw-bolder">{patientDetails.emmergency_number || "NA"}</span>
              </p>
              <p className="m-0 font-12">
                Please contact for Telemedicine consultation on call and
                whatsapp from home stay - Contact Number - {patientDetails.contact || "NA"}
              </p>
              <p className="m-0 font-12">
                I am satifiied with the treatment recieved to me / my patient
                during my / my patient visit in the hospital{" "}
              </p>
              <p className="m-0 font-12">
                मेरे परिजन को अस्पताल में भर्ती होने के दरम्यान मिली सेवा दिए गए
                इलाज से में संतुष्ट हूॅ।{" "}
              </p>
              <p className="m-0 font-12">
                I have been explained by Doctor on ....................... and i
                fully understand about my medications, insulin uptake, would
                call revisit etc.
              </p>
              <p className="m-0 font-12">
                मुझे अपनी/अपने परिजन .............. की बीमारी के बारे में दवाई
                जांच आदि के बारे में डॉक्टर द्वारा अवगत कराया गया है।
              </p>
              <p className="m-0 font-12">
                I have recieved the detailed discharge summary report / of my
                patient / <span>मुझे छुट्टी के पूरे कागजात दिए गए है।</span>
              </p>
            </div>
            <div className="d-flex gap-5">
              <div className="font-12 fw-bolder">
                PRINTED BY : {patientDetails.bill_prepared_by || "NA"}
              </div>
              <div className="font-12 fw-bolder">
                CHECKED BY : {patientDetails.doctor_name || "NA"}
              </div>
            </div>
            <div className="font-12 fw-bolder">
              PREPARED BY : {patientDetails.bill_prepared_by || "NA"} 
            </div>
          </div>

        </div>
       
        <div className="d-flex justify-content-center my-3 gap-2">
          <button
            type="button"
            className=" d-print-none btn btn-primary btn-lg"
            onClick={() => window.print()}
          >
            Print
          </button>
          <button
            type="button"
            className=" d-print-none btn btn-primary btn-lg"
            onClick={() => navigate("/admin-dashboard")}
          >
            Go to Dashboard
          </button>
        </div>
      </Component>
    </>
  );
};
export default DischargesSummary;
const Component = styled.div`
  td {
    font-size: 14px;
    max-width: 450px;
  }
  li {
    list-style: none;
  }
  .font-12 {
    font-size: 14px;
  }
  .font-14 {
    font-size: 16px;
  }

`;
